import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import { listaSafras, listaRevs } from '../stdPlanoSafra';
import { stdPlanoVars } from '../stdPlanoSafra'
import GlobalStateContext from '../../components/GlobalStateContext';
import ModalConfirm from '../../components/ui/ModalConfirm';
import psHooks from '../opoio/psHooks';

export default function SubMenuFiles() {
  const {psData, setPsData, isOpen, setIsOpen, allPsData, setAllPsData, psDataReal, setPsDataReal} = useContext(PSContext)
  const {user} = useContext(GlobalStateContext)
  const [selectedSafra, setSelectedSafra] = useState(listaSafras[0]);
  const [selectedOption, setSelectedOption] = useState('opcao1');
  const [selectedRev, setSelectedRev] = useState(listaRevs[0]);
  const [revName, setRevName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalOpen, setShowModalOpen] = useState(false);
  const [safraDel, setSafraDel] = useState(null)
  const [revDel, setRevDel] = useState(null)
  const { getPsReal } = psHooks();


  useEffect(() => {
    async function fetchData() {
      if (isOpen) {
        let groupedData = await getAllPsData();
        console.log(groupedData);
        setAllPsData(groupedData);
      } else {
        setAllPsData(null); // Limpa os dados quando o componente não está aberto
      }
    }

    fetchData();
  }, [isOpen]);

  async function getAllPsData() {
    console.log('getAllPsData');
    try {
      const response = await fetch(`${global.endPoint}/getallps`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Erro de requisição.');
      }

      console.log('AllPs');
      let impData = await response.json();

      const groupedData = impData.reduce((acc, item) => {
        const { safra, revisao, codigo } = item;
        if (!acc[safra]) {
          acc[safra] = {};
        }
        if (!acc[safra][revisao]) {
          acc[safra][revisao] = [];
        }
        acc[safra][revisao].push(item);

        return acc;
      }, {});

      return groupedData;
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
    return null;
  }

  const getLatestUpdated = (items) => {
  //   let temps = []
  //   items.forEach(item => {
  //       temps.push(item)
  // });
    return items.reduce((latest, item) => {
      return new Date(item.atualizadoEm) > new Date(latest.atualizadoEm) ? item : latest;
    }, items[0]);
  };

  async function deletePs(safra, revisao){
    await fetch(''+global.endPoint+'/deletarps', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({safra: safra, revisao: revisao})
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro de requisição.');
        }else{
          console.log('deleted')
        }
      })
      .catch(error => {
        // Lida com o erro da requisição
      })

      await getAllPsData()
  }

  async function abrirPs(safra, revisao){
    try {
        const response = await fetch(`${global.endPoint}/getps?safra=${safra}&revisao=${revisao}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
      
        if (!response.ok) {
          throw new Error('Erro de requisição.');
        }
        let impData = await response.json();

        let stdPS = stdPlanoVars
        let tempData = []

        console.log('impdata')
        console.log(impData)

        for (const item of stdPS){
            let newItem = impData.filter(row => row.codigo === item.cod)
            if (newItem.length > 0){
                tempData.push(newItem[0])
            }
        }
        
        setPsData(tempData)
      
        
        
      } catch (error) {
        console.error('Erro na requisição:', error);
      }
      let real = await getPsReal(safra)
      setPsDataReal(real)

      setIsOpen(false)

  }

  const confirmDelete = () => {
    deletePs(safraDel, revDel)
    setShowModal(false);
  };

  const cancelOpen = () => {
    setShowModalOpen(false);
  };

  const confirmOpen = () => {
    setShowModalOpen(false);
    abrirPs(safraDel, revDel)
    
    
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const showConfirmOpen = (safra, revisao) => {
    setSafraDel(safra)
    setRevDel(revisao)
    setShowModalOpen(true);
  };

  const showConfirm = (safra, revisao) => {
    setSafraDel(safra)
    setRevDel(revisao)
    setShowModal(true);
  };


  return (
    <>
     <div style={{marginLeft: '2vh', marginRight: '2vh', marginTop: '2vh'}}>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5vh', borderBottom: '1px solid #f2f2f7', fontSize: '1.6vh', fontWeight: 'bold', textAlign: 'left', paddingLeft: '2vh'}}>
                        <div style={{width: '10%'}}>Safra</div>
                        <div style={{width: '15%'}}>Revisão</div>
                        <div style={{width: '25%'}}>Última Modificação</div>
                        <div style={{width: '30%'}}>Usuário</div>
                        <div style={{width: '20%'}}>Ações</div>
                        
                    </div>
                    </div>
    <div >
    
    {allPsData && <div>
      {Object.keys(allPsData)
      .sort((a, b) => b.localeCompare(a))
      .map(safra => (
        <div key={safra}>
          <div style={{marginTop: '1vh', marginBottom: '0.3vh', fontSize: '1.6vh', fontWeight: 'bold', textAlign: 'left', marginLeft: '2vh'}}>{safra}</div>
          <div style={{marginLeft: '2vh', marginRight: '2vh', marginTop: '0vh', backgroundColor: 'white', borderRadius: '1vh'}}>
            {Object.keys(allPsData[safra])
            .sort()
            .map((revisao, index) => {
                const latestItem = getLatestUpdated(allPsData[safra][revisao]);
                const formattedDate = new Date(latestItem.atualizadoEm).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }); // Formato brasileiro de data e hora
                const email = allPsData[safra][revisao].filter(i => i.codigo === 'psAlerts')[0].descricao
                return (
                    <div  style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.7vh', borderBottom: '1px solid #f2f2f7', 
                    textAlign: 'left', paddingLeft: '2vh', fontSize: '1.6vh', alignItems: 'center', }} //backgroundColor: `${index%2 !== 0 ? '#f2f2f7' : 'white'}`
                    key={revisao}>
                        <div style={{width: '10%'}}>{safra}</div>
                        <div style={{width: '15%'}}>{revisao}</div>
                        <div style={{width: '25%'}}>{formattedDate}</div>
                        <div style={{width: '30%'}}>{email}</div>
                        <div style={{display: 'flex', flexDirection: 'row', width: '20%'}}>
                        <div ><button className="modal-open-button2" onClick={()=> showConfirmOpen(safra, revisao)}>Abrir</button></div>
                        <div style={{marginLeft: '2vh'}}><button className="modal-delete-button2" onClick={()=> showConfirm(safra, revisao)}>Deletar</button></div>
                        </div>
                    </div>
                  )
            })}
          </div>
        </div>
      ))}
    </div>}
    </div>

<ModalConfirm show={showModal} title="Alerta">
<div className='modal-title'>Tem certeza que deseja deletar o plano {safraDel} - {revDel}?</div>
<button className="modal-cancel-button" onClick={cancelDelete}>Cancela</button>
<button className="modal-destroy-button" onClick={confirmDelete}>Deletar</button>

</ModalConfirm>
<ModalConfirm show={showModalOpen} title="Alerta">
<div className='modal-title'>Deseja abrir o plano {safraDel} - {revDel}? Verifique se o plano atual foi salvo.</div>
<button className="modal-cancel-button" onClick={cancelOpen}>Cancela</button>
<button className="modal-open-button" onClick={confirmOpen}>Abrir</button>

</ModalConfirm>
</>
  )
  
}
import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import { FaFilePen } from "react-icons/fa6";
import { Edit } from 'lucide-react';
import psHooks from '../opoio/psHooks'
import { calcPS, abrirPs } from '../opoio/calcPS';

export default function BtEditPS() {
  const {isSave, setIsSave, setIsEdit, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart, psData, allPsData, psDataReal, setPsData} = useContext(PSContext)
  const [isDisable, setIsDisable] = useState(false)
  const { getPsReal } = psHooks();

  

  useEffect(() => {
    if(isNew || isSave || isOpen || isExpPdf || isSaveAs || isChart || !psData){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
  }, [isSave, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart, psDataReal])

    const btClicked = async () => {
      setIsEdit(!isEdit)
      if (isEdit ){
        console.log('psData before Calc PS')
        console.log(psData)
        let tempData = await calcPS(psData, psDataReal)
        console.log(tempData.filter(item => item.codigo === 'psAtrPcts'))
        setPsData(tempData);
        console.log('yesssss')
        await atualizarPS()
        setIsSave(true)
        // global.psSaved = true
        // setIsSave(false)
        // global.psSaved = false
        if (psData[0].revisao === '13_Realizado'){
          console.log('Update All Plans of :'+psData[0].safra)
          let safraToUpt = allPsData[psData[0].safra]
          await updatePlans(safraToUpt)
        }
        if (global.psSaved){
          setIsSave(false)
        }
        // setIsSave(false)
        
      }
    }

    async function atualizarPS(){
      // let tempData = await calcPS(psData)
      // setPsData(tempData);

      await fetch(''+global.endPoint+'/atualizarps', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(psData)
      })
      .then(async response => {
        if (!response.ok) {
          throw new Error('Erro de requisição.');
        }else{
          console.log('updated')
        }
      })
      .catch(error => {
        // Lida com o erro da requisição
      })
    }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
        <button className='psButtom' onClick={btClicked}
        disabled={isDisable}>
        <div className='psMenuButtom' >
    <FaFilePen  className='psMenuIcon'/>
    <div className='psMenuText'>EDITAR</div></div>
        </button>
</div>
  )
}

async function updatePlans(safraToUpt){
  let psReal = safraToUpt['13_Realizado']
  // console.log('psReal')
  let revisoesToUpt = safraToUpt
  for (let key in revisoesToUpt) {
    if (revisoesToUpt.hasOwnProperty(key)) {
      if (key !== '13_Realizado' && key !== '0_Inicial' && key !== '1_Janeiro'){
        console.log(key);
      let temp = await calcPS(revisoesToUpt[key], psReal)
      console.log(temp);
      await fetch(''+global.endPoint+'/atualizarps', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(temp)
      })
      .then(async response => {
        if (!response.ok) {
          throw new Error('Erro de requisição.');
        }else{
          console.log('updated')
        }
      })
      .catch(error => {
        // Lida com o erro da requisição
      })
      }
      
    }
  }
}
import React, {useContext } from 'react';
import { FaFileImport } from "react-icons/fa";
import GlobalStateContext from '../../components/GlobalStateContext'
import { NavLink } from 'react-router-dom';

export default function ImportBulletin() {
    const {setScreen} = useContext(GlobalStateContext)

  const handleMenuChanged = (item) => {
    setScreen(item)
    };

  return (
    <NavLink to={'/analiseProducao/impBoletim'} onClick={() => handleMenuChanged('impBoletim')}
    style={{textDecoration: 'none'}}>
    <div className='menuButtom' >
    <FaFileImport  className='menuIcon' />
    <div className='menuText'>Impotar Boletim</div>
</div>
</NavLink>
  )
}

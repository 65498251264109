import React, { useEffect, useContext, useState } from 'react'
import MenuLateral from './MenuLateral/MenuLateral'
import BarraSuperior from './BarraSuperior/BarraSuperior'
import Conteudo from './Conteudo/Conteudo'
import '../App.css'
import GlobalStateContext from '../components/GlobalStateContext'
import { listaPS, listBME } from '../Resources/Constantes'

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Fluxograma from './Conteudo/Fluxograma'
import Limites from './Conteudo/Limites/Limites'
import Alertas from './Conteudo/Alertas/Alertas';
import SegmentedControl from './Conteudo/SegmentedControl'
import Listas from './Conteudo/Listas'
import ExtracaoFlx from './Conteudo/Fluxs/extracaoFlx'

import { getAlertList } from '../Funcoes/GetAletrsList';
import ExportPDF from './Pages/Print/PrintAllFlxs'
import ExcelReader from '../ANALISES/Analise Producao/ExcelReader'
import DayAnalyzer from '../ANALISES/Analise Producao/DayAnalizer'
import ExtracaoSeg from './ui/ExtracaoSeg'
import PlanoSafra from '../PLANO SAFRA/planoSafra'



function Main() {
  const {area, setData, data, setlistAlerts, setUser, user, screen, menu} = useContext(GlobalStateContext)

  global.endPoint = 'https://pms-api-santaelisa.onrender.com'
  // global.endPoint = 'HTTP://localhost:7096'

  useEffect(() => {
    console.log('Initial Execution')
    // setUser(null)
    setlistAlerts(getAlertList(data))
    const fetchData = async () => {
      try {
        const response = await fetch(''+global.endPoint+'/'+user?.uid+'/getAll');
        // const response = await fetch(''+global.endPoint+'/'+1+'/getAll');
        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }
        const jsonData = await response.json();
        // console.log('User: '+ user.uid)
        console.log(jsonData)
        
        setData(jsonData);
        setlistAlerts(getAlertList(data))
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      }
    }
    // user && fetchData();
    fetchData();
  }, [setData, setlistAlerts]);

  const noSeg = ["resumoProd", "alertas", "limites", "toPrint", "analiseProducao"]

  return (
    <div className='main'>
     <BrowserRouter>
     {/* <Menu/> */}
        <MenuLateral/>
        <div className='rightSide'>
            <BarraSuperior/>
            
            {menu === 'bme' && !noSeg.includes(area) && <ExtracaoSeg/>}
              <Routes>
                <Route path='/resumoProd' element={<Listas/>}/>
                <Route path='/' element={<Listas/>}/>
            
                <Route path='/alertas' element={<Alertas/>}/>
                <Route path='/limites' element={<Limites/>}/>
                <Route path='/planoSafra' element={<PlanoSafra/>}/>
                <Route path={'/extracao/flxAcionamentos'} element={<Fluxograma/>}/>
                {/* <Route path={'/extracao/fluxograma'} element={<ExtracaoFlx/>}/> */}
                <Route path={'/'+area+'/fluxograma'} element={<Fluxograma/>}/>
                <Route path={'/'+area+'/premissas'} element={<Listas/>}/>
                <Route path={'/'+area+'/fluxos'} element={<Listas/>}/>
                <Route path={'/'+area+'/equipamentos'} element={<Listas/>}/>
                <Route path={'/extracao/acionM1'} element={<Listas/>}/>
                <Route path={'/extracao/acionM2'} element={<Listas/>}/>
                <Route path={'/tratAcucar/aquecedores'} element={<Listas/>}/>
                <Route path={'/tratAcucar/regeneradores'} element={<Listas/>}/>
                <Route path={'/vapor/turboger'} element={<Listas/>}/>
                <Route path={'/vapor/caldeiras'} element={<Listas/>}/>
                <Route path={'/print'} element={<ExportPDF/>}/>
                <Route path={'/analiseProducao/impBoletim'} element={<ExcelReader/>}/>
                <Route path={'/analiseProducao/dayAnalyzer'} element={<DayAnalyzer/>}/>
                {/* <Route path='/tratAcucar/fluxograma' element={<Fluxograma/>}/>
                <Route path='/tratEtanol/fluxograma' element={<Fluxograma/>}/> */}
                
              </Routes>

            {/* {(listBME.includes(area) || area === 'limites') && <Conteudo/>}
            {listaPS.includes(area) && <ConteudoPS/>} */}
            
        </div>
        </BrowserRouter>
    </div>
  )
}

export default Main
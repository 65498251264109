import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import { MdFileOpen } from "react-icons/md";
import { FaFileLines } from "react-icons/fa6";

export default function BtOpenPS() {
  const {isSave, setIsOpen, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart} = useContext(PSContext)
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if(isNew || isSave || isSaveAs || isExpPdf || isEdit || isChart){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
  }, [isSave, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart])

    const btClicked = () => {
      setIsOpen(!isOpen)
    }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
        <button className='psButtom' onClick={btClicked}
        disabled={isDisable}>
        <div className='psMenuButtom' >
    <FaFileLines  className='psMenuIcon' style={{scale: '0.7'}}/>
    <div className='psMenuText'>ARQUIVOS</div></div>
        </button>
</div>
  )
}
import Flow from '../Flow'
import vapFlx from '../../../assets/fluxogramas/vapor6Web.png'

function VaporFlx() {
  const vh = window.innerHeight
const ideal = 727
let nVH = ideal/vh

  return (
    <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
    <div style={{ position: 'absolute', width: `${115*nVH}vh`, height: `${100*nVH}vh`, top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}}>
      <img style={{position: 'absolute', height: `${90*nVH}vh`, width: `${135*nVH}vh`, left: '0vh', top: '0vh'}} src={vapFlx} alt="" />
    {vapor.flows.map((item, index) => {
        return (
          <Flow flow={item.flow} x={item.x * nVH} y={item.y * nVH} align={item.align} valign={item.valign} cods={item.props} key={index} f={nVH}/>
        )
        })}
    </div>
  </div>
  )
}

export default VaporFlx

const vapor = {
    imagem: vapFlx,
    flows: [
      { 
        flow: 'Bagaço',
        props: ['flowBagacoToBoilers'],
        x: 3 , y: 11.6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Sobra',
        props: ['sobraBagaco'],
        x: 3, y: 46.4, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'V67',
        props: ['pressureVA', 'tempVaporVA'],
        x: 3, y: 6.4, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'V42',
        props: ['pressureVM', 'tempVaporVM'],
        x: 3, y: 23.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'V22',
        props: ['pressureV22', 'tempVaporV22'],
        x: 3, y: 40.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'VE',
        props: ['pressureVE', 'tempVaporVE'],
        x: 3, y: 57.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'VV1',
        props: ['pressureVV1'],
        x: 3, y: 65.3, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'VV2',
        props: ['pressureVV2'],
        x: 3, y: 73.7, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'VV3',
        props: ['pressureVV3'],
        x: 3, y: 83, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['gerVaporVACaldeiras'],
        x: 26.5, y: 4, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consCondDesCaldeirasVA', 'tempAguaAlimCaldeirasVA'],
        x: 26.5, y: 9.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consBagCaldeirasVA'],
        x: 5.5, y: 13.7, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowBlowDownCaldVA'],
        x: 26.5, y: 15, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['gerVaporVMCaldeiras'],
        x: 26.5, y: 21, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consCondDesCaldeirasVM', 'tempAguaAlimCaldeirasVM'],
        x: 26.5, y: 26.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consBagCaldeirasVM'],
        x: 5.5, y: 31.3, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowBlowDownCaldVM'],
        x: 26.5, y: 32.4, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['gerVaporV22Caldeiras'],
        x: 26.5, y: 38, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consCondDesCaldeirasV22', 'tempAguaAlimCaldeirasV22'],
        x: 26.5, y: 43.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consBagCaldeirasV22'],
        x: 5.5, y: 47.8, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowBlowDownCaldV22'],
        x: 26.5, y: 49, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVATgCpVA', 'pressInTgCpVA', 'tempInTgCpVA'],
        x: 66, y: 3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['gerVaporVETgCpVA', 'tempEscTgCpVA'],
        x: 62.5, y: 13, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['ptTgCpVA'],
        x: 72.5, y: 9.3, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['eficIsoTgCpVA'],
        x: 56.3, y: 10, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVMTgCpVM', 'pressInTgCpVM', 'tempInTgCpVM'],
        x: 83.1, y: 20, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['gerVaporVETgCpVM', 'tempEscTgCpVM'],
        x: 79.4, y: 30.6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['ptTgCpVM'],
        x: 88.5, y: 26.8, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['eficIsoTgCpVM'],
        x: 72.8, y: 27.5, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVMTgCdVM', 'pressInTgCpVM', 'tempInTgCpVM'],
        x: 49.5, y: 20, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['gerCondVETgCdVM', 'pressEscTgCdVM', 'tempEscTgCdVM'],
        x: 46, y: 30.6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['ptTgCdVM'],
        x: 54.7, y: 26.8, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['eficIsoTgCdVM'],
        x: 40, y: 27.5, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVaporV22M1'],
        x: 69, y: 38, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['gerVaporVEM1', 'tempVaporVEM1'],
        x: 66, y: 48, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowVaporV22M2'],
        x: 86, y: 38, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['gerVaporVEM2', 'tempVaporVEM2'],
        x: 83, y: 48, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowVaporV22TBCaldeiras'],
        x: 103, y: 38, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVaporVETBCaldeiras', 'tempVaporVETBCaldeiras'],
        x: 100, y: 48, align: 'left', valign: 'flex-start'
      },
      //REB
      { 
        flow: '',
        props: ['consVaporVARebVAVE'],
        x: 96.5, y: 4, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowAguaDesRebVAVE'],
        x: 89, y: 12.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['gerVaporVERebVaVe'],
        x: 96.5, y: 16, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVARebVAVM'],
        x: 110.7, y: 4, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowAguaDesRebVAVM'],
        x: 103, y: 12.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['gerVaporVMRebVAVM'],
        x: 110.7, y: 16, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVMRebVMV22'],
        x: 104, y: 21, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowAguaDesRebVMV22'],
        x: 96, y: 30.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['gerVaporV22RebVMV22'],
        x: 104, y: 33.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporV22RebV22VE'],
        x: 114.5, y: 38, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowAguaDesRebV22VE'],
        x: 106.5, y: 48, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['gerVaporVERebV22VE'],
        x: 114.5, y: 50.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVEEvapAc'],
        x: 73.5, y: 54.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVEDesaerador2'],
        x: 47.7, y: 51, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVEDesaerador'],
        x: 47.7, y: 54.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVaporVEDestilaria'],
        x: 84, y: 54.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVEPenMol'],
        x: 94.3, y: 54.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVESecadorAcucar'],
        x: 104.6, y: 54.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVaporVELimpCoz'],
        x: 115.2, y: 54.5, align: 'left', valign: 'flex-end'
      },
  
      { 
        flow: '',
        props: ['flowVaporVV1Aquecimento'],
        x: 26, y: 63.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVaporVV1TotalDest'],
        x: 46.5, y: 63.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVV1'],
        x: 73.5, y: 60, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVaporVV12Efeito'],
        x: 67.5, y: 63.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVV1CozedorMassaA'],
        x: 84, y: 63.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVV1CozedorMassaB'],
        x: 105, y: 63.5, align: 'left', valign: 'flex-end'
      },
  
      { 
        flow: '',
        props: ['flowVaporVV2Aquecimento'],
        x: 35.3, y: 72.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVaporVV23Efeito'],
        x: 56.6, y: 72.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVV2CozedorMassaA'],
        x: 76, y: 72.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVV2CozedorMassaB'],
        x: 94.7, y: 72.5, align: 'left', valign: 'flex-end'
      },
  
      { 
        flow: '',
        props: ['flowVaporVV3Aquecimento'],
        x: 46.5, y: 81.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVaporVV34Efeito'],
        x: 67.5, y: 81.5, align: 'left', valign: 'flex-end'
      },
  
      { 
        flow: '',
        props: ['consVaporVEAlivio'],
        x: 124.4, y: 49.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVV1Alivio'],
        x: 124.4, y: 59, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporVV2Alivio'],
        x: 124.4, y: 68.5, align: 'left', valign: 'flex-end'
      },
      //PERDAS
      { 
        flow: 'Perdas',
        props: ['consVaporVAPerdas'],
        x: 116, y: 4, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Perdas',
        props: ['consVaporVMPerdas'],
        x: 116, y: 21, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Perdas',
        props: ['consVaporV22Perdas'],
        x: 116, y: 38, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Perdas',
        props: ['consVaporVEPerdas'],
        x: 116, y: 55, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Perdas',
        props: ['consVaporVV1Perdas'],
        x: 116, y: 64.5, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Perdas',
        props: ['consVaporVV2Perdas'],
        x: 116, y: 73, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Perdas',
        props: ['consVaporVV3Perdas'],
        x: 116, y: 82.2, align: 'right', valign: 'flex-start'
      },
  
      { 
        flow: '',
        props: ['flowCondDessupFromDes'],
        x: 30, y: 12.5, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['gerVaporVEDessupVE'],
        x: 15, y: 56.3, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['consVaporV22Desidratacao'],
        x: 116.7, y: 33.4, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['consVaporV10Desidratacao'],
        x: 116.7, y: 29, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['consCondVEDessupDesid'],
        x: 108.8, y: 30.7, align: 'right', valign: 'flex-start'
      },
  
      { 
        flow: '',
        props: ['gerCondVEProcesso', 'tempRetCondVEDesaerador'],
        x: 53, y: 54.2, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowCondVEDest'],
        x: 51.5, y: 59.6, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowCondProDesVAVM'],
        x: 52, y: 46.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowCondProDesV22'],
        x: 52, y: 56.2, align: 'left', valign: 'flex-end'
      },
  
      { 
        flow: '',
        props: ['flowAguaDesaerador2', 'tempDesaerador2'],
        x: 37.3, y: 46.8, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Desmi',
        props: ['consAguaDesmiDesaerador', 'tempsAguaDesmiDesaerador'],
        x: 49.5, y: 43.5, align: 'left', valign: 'flex-end'
      },
      
      { 
        flow: 'CDVG1',
        props: ['consCondVGDesaerador', 'tempCondVG1Reg'],
        x: 23.5, y: 59.5, align: 'left', valign: 'flex-end'
      },
    ]
  }
import { useContext, useState } from "react"
import GlobalStateContext from "../../GlobalStateContext"

function LimitsLine({item}) {
    const {data, setData, user} = useContext(GlobalStateContext)
    const [desc, setDesc] = useState(item?.descricao)
    const [def, setDef] = useState(''+item?.valorDefault)
    const [max, setMax] = useState(item?.limiteMax)
    const [min, setMin] = useState(item?.limiteMin)

    const handleDesc = (e) =>{
        // console.log('Cod: '+ e.target.id + ' new Desc: '+e.target.value)
        setDesc(e.target.value)
    }

    const handleDef = (e) =>{
        // console.log('Cod: '+ e.target.id + ' new Def: '+e.target.value)
        setDef(e.target.value)
    }

    const handleMin = (e) =>{
        setMin(e.target.value)
    }

    const handleMax = (e) =>{
        setMax(e.target.value)
    }

    const changedDesc = async(e) =>{
        console.log('Update desc')
        await updateDesc(e.target.id, e.target.value)
    }

    const changedDef = (e) =>{
        updateDef(e.target.id, e.target.value)
    }

    const changedMin = (e) =>{
        updateMin(e.target.id, e.target.value)
    }

    const changedMax = (e) =>{
        updateMax(e.target.id, e.target.value)
     }

    const updateMin = async (cod, min) => {
        await fetch(''+global.endPoint+'/'+user?.uid+'/setMin', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codigo: cod, newValue: parseFloat(min)})
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro de requisição.');
        }else{
          data[cod].limiteMin = parseFloat(min)
        }
      })
      .catch(error => {
        // Lida com o erro da requisição
      })
    }

    const updateMax = async (cod, max) => {
        await fetch(''+global.endPoint+'/'+user?.uid+'/setMax', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codigo: cod, newValue: parseFloat(max)})
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro de requisição.');
        }else{
          data[cod].limiteMin = parseFloat(max)
        }
      })
      .catch(error => {
        // Lida com o erro da requisição
      })
    }

    const updateDef = async (cod, def) => {
          await fetch(''+global.endPoint+'/'+user?.uid+'/setDefValue', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({codigo: cod, newValue: parseFloat(def)})
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro de requisição.');
          }else{
            data[cod].valorDefault = parseFloat(def)
          }
        })
        .catch(error => {
          // Lida com o erro da requisição
        })
      }

      const updateDesc = async (cod, desc) => {
        // console.log('Cod: '+ cod + ' new desc: '+desc)
      await fetch(''+global.endPoint+'/'+user?.uid+'/setDesc', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({codigo: cod, newValue: desc})
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro de requisição.');
      }else{
        console.log(desc)
        data[cod].descrico = desc
        setData(data)
      }
    })
    .catch(error => {
      // Lida com o erro da requisição
    })
  }

    return (
        <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRow' style={{width: '30%'}}>
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
                    id={item?.codigo} type="text" value={desc}
                    onChange={handleDesc} onBlur={changedDesc}/> 
                </div>
                <div className='fullLineRow' style={{width: '25%'}}>{item?.codigo}</div>
                <div className='fullLineRow' style={{width: '15%'}}>
                    {item?.input ? 
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
                    id={item?.codigo} type="text" value={item?.valor.toFixed(2)}
                    onChange={handleDef} onBlur={changedDef} /> :
                    item?.valor.toFixed(2)
                    }
                </div>
                <div className='fullLineRow' style={{width: '15%'}}>
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
                    id={item?.codigo} type="text" value={def}
                    onChange={handleDef} onBlur={changedDef}/>
                </div>
                <div className='fullLineRow' style={{width: '15%'}}>
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
                    id={item?.codigo} type="text" value={min !== null ? min : ''}
                    onChange={handleMin} onBlur={changedMin}/>
                </div>
                <div className='fullLineRow' style={{width: '15%'}}>
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
                    id={item?.codigo} type="text" value={max !== null ? max : ''}
                    onChange={handleMax} onBlur={changedMax}/> 
                </div>
                <div className='fullLineRow' style={{width: '15%', textAlign: 'right'}}>
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh', textAlign: 'right'}} id={item?.codigo} type="text" value={item?.unidade}/> 
                </div>
            </div>
        </div> 
        )  

}

export default LimitsLine
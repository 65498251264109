import Flow from '../Flow'
import FiltLodoFlx from '../../../assets/fluxogramas/filtFlx.png'

function FiltracaoLodoFlx() {
  const vh = window.innerHeight
const ideal = 727
let nVH = ideal/vh

  return (
    <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
    <div style={{ position: 'absolute', width: `${115*nVH}vh`, height: `${100*nVH}vh`, top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}}>
      <img style={{position: 'absolute', height: `${90*nVH}vh`, width: `${135*nVH}vh`, left: '0vh', top: '0vh'}} src={FiltLodoFlx} alt="" />
    {filtLodo.flows.map((item, index) => {
        return (
          <Flow flow={item.flow} x={item.x * nVH} y={item.y * nVH} align={item.align} valign={item.valign} cods={item.props} key={index} f={nVH}/>
        )
        })}
    </div>
  </div>
  )
}

export default FiltracaoLodoFlx

const filtLodo = {
    imagem: FiltLodoFlx,
    flows: [
      { 
        flow: 'Bagacilho',
        props: ['flowBagacilho'],
        x: 115.5 , y: 37.8, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Água Emb.',
        props: ['flowAguaEmbebicaoFiltroRot'],
        x: 115.5, y: 52.4, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Água Emb.',
        props: ['flowAguaEmbebicaoFiltroPre'],
        x: 3, y: 42.8, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Água Lav.',
        props: ['flowAguaLavTelasFiltroPre'],
        x: 3, y: 51, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Águas Resid.',
        props: ['flowAguaLavTelasFiltroPre'],
        x: 3, y: 58.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Lodo Etanol',
        props: ['flowLodoEtanol', 'brixLodoEtanol', 'artMassaLodoEtanol'],
        x: 115.5, y: 6.6, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Lodo Açúcar',
        props: ['flowLodoAcucar', 'brixLodoAcucar', 'artMassaLodoAcucar'],
        x: 3, y: 6.6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Caldo Filtrado',
        props: ['flowCaldoFiltrado', 'brixCaldoFiltrado', 'tempCaldoFiltrado', 'artMassaCaldoFiltrado'],
        x: 3, y: 72.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Torta',
        props: ['flowTorta','artTorta', 'artMassaTorta'],
        x: 3, y: 81.6, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Lodo',
        props: ['flowLodo','brixLodo', 'artMassaLodo'],
        x: 68, y: 6.6, align: 'left', valign: 'flex-start'
      }
    ]
  }

// GlobalStateContext.js
import React, { createContext, useState } from 'react';

// Criando o contexto
export const GlobalStateContext = createContext();

// Provedor de contexto
export const GlobalStateProvider = ({ children }) => {
  const [isAlertOn, setAlert] = useState(true); // Estado global
  const [data, setData] = useState(null);
  const [area, setArea] = useState('resumoProd');
  const [menu, setMenu] = useState('bme');
  const [screen, setScreen] = useState('fluxos');
  const [listAlerts, setlistAlerts] = useState(null);
  const [user, setUser] = useState(null);
  const [isPrinting, setPrinting] = useState(false);
  return (
    <GlobalStateContext.Provider value={{ 
      isAlertOn, setAlert, 
      data, setData,
      area, setArea,
      menu, setMenu,
      screen, setScreen, 
      listAlerts, setlistAlerts, 
      user, setUser , 
      isPrinting, setPrinting}}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateContext;
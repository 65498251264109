
import React, { createContext, useState } from 'react';

// Criando o contexto
export const PSContext = createContext();

// Provedor de contexto
export const PSContextProvider = ({ children }) => {
  const [isNew, setIsNew] = useState(false); 
  const [isSave, setIsSave] = useState(false); 
  const [isSaveAs, setIsSaveAs] = useState(false); 
  const [isOpen, setIsOpen] = useState(false); 
  const [isExpPdf, setIsExpPdf] = useState(false); 
  const [isEdit, setIsEdit] = useState(false); 
  const [isChart, setIsChart] = useState(false); 
  const [psData, setPsData] = useState(null);
  const [allPsData, setAllPsData] = useState(null);
  const [psDataReal, setPsDataReal] = useState(null);
  const [editingMonth, setEditingMonth] = useState('Janeiro');
  return (
    <PSContext.Provider value={{ 
        isNew, setIsNew, isSave, setIsSave, isSaveAs, setIsSaveAs,
        isOpen, setIsOpen, isExpPdf, setIsExpPdf, isEdit, setIsEdit,
        isChart, setIsChart, psData, setPsData, allPsData, setAllPsData, psDataReal, setPsDataReal,
        editingMonth, setEditingMonth
        }}>
      {children}
    </PSContext.Provider>
  )
}

export default PSContext;

import { initializeApp } from "firebase/app";

import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCgZI2lqKc84i9_HHlzbKPyWZuWSLK0TBo",
  authDomain: "pmsteste-45bb8.firebaseapp.com",
  projectId: "pmsteste-45bb8",
  storageBucket: "pmsteste-45bb8.appspot.com",
  messagingSenderId: "937127641318",
  appId: "1:937127641318:web:146b9d36afa6e5a3347b27"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


const auth = getAuth(app);

export { auth };
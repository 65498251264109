import React, { useState, useContext } from 'react'
import '../../css/InputLine.css'
import PSContext from './PSContext';

function PsInput({cod, mes, valor}) {
  
  const [prevV, setPrevV] = useState('')
  const {psData, setPsData, setPsDataReal} = useContext(PSContext)
  const [v, setV] = useState(valor)


  const handleIpt = (e) => {
    setV(e.target.value)
  }
  const updateValue = async (e) => {
    if (v === '' || !contemNumero(v)){
      setV(prevV)
    }else{
      if (v !== prevV){
        setV(e.target.value)
        console.log(''+e.target.value + ': '+e.target.id)
        setValue(cod, mes, parseFloat(v))
      }
    }
  }
  const handlePrevV = (e) => {
    setPrevV(e.target.value)
  }

  function setValue(codigo, mes, novoValor) {
    let data = psData
    let index = data.findIndex(obj => obj.codigo === codigo);
    if (index !== -1) {
        let item = data[index];
        if (mes in item) {
            item[mes] = novoValor;
            data[index] = item;  
        } 
    }
    global.psSaved = false
    setPsData(data)
    console.log(data)
    console.log(data[0].revisao)
    if (data[0].revisao === '13_Realizado'){
        console.log('is 13_Realizado')
        setPsDataReal(data)
    }
}

  return (
    <div>
            
     <input style={{width: '95%', textAlign: 'left', fontSize: '1.6vh', padding: '0.4vh', marginLeft: '-0.5vh', border: '1px solid #c7c7c7', borderRadius: '2px', color:  '#007bff'}} 
     value={v} id={cod} onChange={handleIpt} onBlur={updateValue} onFocus={handlePrevV} type="text" />
              
    </div>
  )
}

export default PsInput

function contemNumero(string) {
  return !isNaN(parseFloat(string)) && isFinite(string);
}

import styles from './BarraSuperior.module.css'
import { getTitleFromArea } from '../../Funcoes/TextFuncs'
import { IoRefreshCircle, IoAlertCircleSharp } from "react-icons/io5";
import React, { useState, useContext, useEffect } from 'react'
import Modal from './Modal';
import GlobalStateContext from '../GlobalStateContext'
import FileUploader from '../../Resources/FileUploader';
import PrintAllFlxs from '../Pages/Print/PrintAllFlxs'
import FileSaveAs from '../../Resources/FileSaveAs';
import PrintFlxs from '../Pages/Print/PrintFlxs'
import buttomStyle from '../../css/headButton.css'
import MenuAnalises from '../../ANALISES/Analise Producao/menuAnalises'
import ExcelReader from '../../ANALISES/Analise Producao/ExcelReader';
import ImportBulletin from '../../ANALISES/Botoes/ImportBulletin';
import BtDayAnalyzer from '../../ANALISES/Botoes/btDayAnalyzer';
import Menu from './MenuBarraSuperior'


function BarraSuperior() {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalText, setModalText] = useState('Calculando...');
  const { isAlertOn, setAlert, setData, data, area, user, menu } = useContext(GlobalStateContext);


  const hanldeAlert = (value) => {
    setAlert(value)
  }
 
  const fetchData = async () => {
    setIsLoading(true)
    setModalText('Calculando...')
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 1;
      if (progressValue <= 100) {
        setProgress(progressValue)
      } else {
        clearInterval(interval)
        setModalText('Atualizando...')
      }
    }, 40);

    try {
      const response = await fetch(''+global.endPoint+'/'+user?.uid+'/calcAll');
      if (!response.ok) {
        throw new Error('Erro ao buscar dados da API');
      }
      const jsonData = await response.json()
      
      setIsLoading(false)
      setProgress(0)
      hanldeAlert(false)

      console.log('flowBagaco2: '+data['flowBagaco'].valor)
      console.log(data)
      // for (const key in jsonData){
      //   tempData[key].valor = jsonData[key].value
      // }
      
      setData(jsonData)
      console.log('flowBagaco1: '+jsonData['flowBagaco'].valor)
      console.log('flowBagaco2: '+data['flowBagaco'].valor)
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      setIsLoading(false)
      setProgress(0)
    }
  }

  const calcBME = async () => {
     await fetchData()
     console.log('flowBagaco: '+data['flowBagaco'].valor)
  }

  return (
    <div className={styles.barra_Superior}>
      <div className={styles.menu_BarraSup}>
        {/* <div className={styles.buttom}>
            <IoRefreshCircle className={styles.menu_Item} onClick={fetchData}/>
            <div className={styles.iconText}>Calcular</div>
            {isAlertOn ? <div className={styles.badge}><IoAlertCircleSharp/></div>:<></>}
        </div> */}

        {/* <Menu/> */}

        {menu === 'bme' && 
        <div className='menuButtom' onClick={fetchData}>
        <IoRefreshCircle  className='menuIcon' style={{marginBottom: '0.2vh', marginTop: '0.5vh'}}/>
        <div className='menuText'>Calcular</div>
        {isAlertOn ? <div className={styles.badge}><IoAlertCircleSharp/></div>:<></>}
    </div>}
       
          {area == 'resumoProd' && <PrintAllFlxs/>}
          {/* {area == 'resumoProd' && <FileUploader/>} */}
          {/* {area == 'resumoProd' && <FileSaveAs/>} */}

          {menu == 'analises' && <ImportBulletin/>}
          {menu == 'analises' && <BtDayAnalyzer/>}

          {/* <p>{menu}</p> */}

    
       
            
       
       
      </div>
      <h2 className={styles.title_BarraSup}>{getTitleFromArea(area)}</h2>
      <div className={styles.logos_BarraSup}>
        <img className={styles.logo_BarraSup} src="/logo512.png" alt="logo pms"/>
      </div>
      <Modal show={isLoading} progress={progress} texto={modalText}/>
    </div> 
  )
}

export default BarraSuperior


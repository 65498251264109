import { useState, useContext, useEffect } from 'react'
import { getTitleFromArea } from '../../Funcoes/TextFuncs'
import styles from './MenuLateral.module.css'
import { IoChevronForwardOutline, IoChevronDown, IoAlertCircleSharp } from "react-icons/io5";
import GlobalStateContext from '../GlobalStateContext'
import { getAlertList } from '../../Funcoes/GetAletrsList';
import { listaAN, listaPS, listBME } from '../../Resources/Constantes';
import { NavLink} from 'react-router-dom'
import LogoCos from '../../assets/fluxogramas/logoCostumer.png'
import {COLORS} from '../../components/ui/colors'

function MenuLateral() {
  // const listBME = ['resumoProd', 'extracao', 'tratAcucar', 'tratEtanol', 'filtLodo', 'evaporacao', 'fermentacao', 'destilaria', 'fabrica', 'vapor', 'balHidrico', 'alertas']
  // const listPS = ['planoSafra', 'comparativoPs', 'plano10Anos']
  const [showMenuBme, setShowMenuBme] = useState(true)
  const [showMenuPS, setShowMenuPS] = useState(false)
  const [showMenuAN, setShowMenuAN] = useState(false)
  const {area, setArea, screen, setScreen, listAlerts, user, setlistAlerts, data, menu, setMenu} = useContext(GlobalStateContext)


  useEffect(() => {
    document.documentElement.style.setProperty('--bg-color', COLORS.bGroungColor);
  }, []);

  const showBME = () => {
    setShowMenuBme(!showMenuBme)
  }

  const showPS = () => {
    setShowMenuPS(!showMenuPS)
  }

  const showAN = () => {
    setShowMenuAN(!showMenuAN)
  }

  const handleMenuChanged = (item) => {
    setArea('blank')

    setArea(item)
    
    
    if (item === 'resumoProd'){
      setScreen('fluxos')
    }
    

    if (listBME.includes(item) || item === 'resumoProd'){
      setMenu('bme')
      setScreen('fluxograma')
    }else if (listaPS.includes(item)){
      setMenu('ps')
    }else{
      setMenu('analises')
      setScreen('impBoletim')
    }

    
  }

  useEffect(() => {
    setlistAlerts(getAlertList(data))
  }, [data]);

  const alertIcon = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <p className={styles.p_Item} style={{color: 'red', fontWeight: 'bold'}}>
           <IoAlertCircleSharp style={{color: 'red', fontWeight: 'bold', 
          marginTop: '0.5vh', fontSize: '2.5vh', marginRight: '1vh', marginLeft: '-0.5vh'}}/>
           </p>
      </div>
    )
  }

  return (
    <div className='menuLateral'>
      <div className={styles.menu_Logo}>
        {/* <img className={styles.logo_Costumer} src={LogoCos} alt="" /> */}
      </div>
      <div>
        <div onClick={showBME} className={styles.menu_Head}>
            <p>BALANÇO DE MASSA E ENERGIA</p>
            {showMenuBme ? 
            <IoChevronDown className={styles.icon_Head}/> :
            <IoChevronForwardOutline className={styles.icon_Head}/>
            }
        </div>
        
        <div className={!showMenuBme ? styles.show_menuBme : ''} key={'menuBME'}>
        <nav>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
           <NavLink className={({isActive}) => (isActive ? styles.sel_Item : styles.unsel_Item)} 
                to='/resumoProd' onClick={() => handleMenuChanged('resumoProd')}>Resumo de Produção</NavLink>
          </div>
       
          {listBME.map((item, index) => {
            return (
              
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
                <NavLink className={area === item ? styles.sel_Item : styles.unsel_Item} 
                to={'/'+item+'/fluxograma'} onClick={() => handleMenuChanged(item)} 
                key={item}>{getTitleFromArea(item)}</NavLink>
                
               
                </div>
            )
          })} 
          {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
           <NavLink className={({isActive}) => (isActive ? styles.sel_Item : styles.unsel_Item)} 
                to='/alertas' onClick={() => handleMenuChanged('alertas')}>Alertas</NavLink>
                 {(listAlerts?.length > 0) && <alertIcon/>}
          </div> */}

          {/* {(user?.profile === 'developer' || user?.profile === 'admin') &&  */}
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
              <NavLink className={area === 'limites' ? styles.sel_Item : styles.unsel_Item} to={'/limites'} onClick={() => handleMenuChanged('limites')}>Limites</NavLink>
            </div>
          {/* } */}

        {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
              <NavLink className={area === 'toPrint' ? styles.sel_Item : styles.unsel_Item} to={'/print'} onClick={() => handleMenuChanged('toPrint')}>Imprimir</NavLink>
            </div> */}
          </nav>
        </div>
         
      </div>
      <div>
        <div onClick={showPS} className={styles.menu_Head}>
            <p>PLANEJAMENTO SAFRA</p>
            {showMenuPS ? 
            <IoChevronDown className={styles.icon_Head}/> :
            <IoChevronForwardOutline className={styles.icon_Head}/>
            }
        </div>
        
        <div className={!showMenuPS ? styles.show_menuBme : ''} key={'menuPS'}>
        <nav>
          {listaPS.map((item, index) => {
            return (
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <NavLink className={area === item ? styles.sel_Item : styles.unsel_Item} to={'/'+item} onClick={() => handleMenuChanged(item)} key={index}>{getTitleFromArea(item)}</NavLink>
                  {listAlerts?.length > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  {item === 'alertas' ? <p className={styles.p_Item} 
                  style={{color: 'red', fontWeight: 'bold'}}>
                    {listAlerts?.length}</p> : <></>}
                    {item === 'alertas' ? <IoAlertCircleSharp  style={{color: 'red', fontWeight: 'bold', 
                    marginTop: '0.5vh', fontSize: '2.5vh', marginRight: '1vh', marginLeft: '-0.5vh'}}/> : <></>}
                </div>}
                  </div>
            )
          })}
          </nav>
        </div>
         
      </div>

       <div>
        <div onClick={showAN} className={styles.menu_Head}>
            <p>ANÁLISES</p>
            {showMenuAN ? 
            <IoChevronDown className={styles.icon_Head}/> :
            <IoChevronForwardOutline className={styles.icon_Head}/>
            }
        </div>
        
        <div className={!showMenuAN ? styles.show_menuBme : ''} key={'menuAn'}>
        <nav>
          {listaAN.map((item, index) => {
            return (
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <NavLink className={area === item ? styles.sel_Item : styles.unsel_Item} to={'/analiseProducao/dayAnalyzer'} onClick={() => handleMenuChanged(item)} key={index}>{getTitleFromArea(item)}</NavLink>
                  </div>
            )
          })}
          </nav>
        </div>
         
      </div>
    </div>
  )
}

export default MenuLateral


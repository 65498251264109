import React, { useContext } from 'react'
import '../../css/InputLine.css'
import InputLine from './InputLine'
import OutputLine from './OutputLine'
import ComboLine from './ComboLine'
import SwitchLine from './SwitchLine'
import GlobalStateContext from '../GlobalStateContext'

function ListLine({ipt, cod, handleCountChange, key, item}) {
    const {isPrinting } = useContext(GlobalStateContext)

    const vh = window.innerHeight
  const ideal = 727
  let nVH = ideal/vh
    
    if (ipt === true && !isPrinting){
        return (
            <InputLine cod={cod} key={key}/>
        )
    }else if(ipt === true && isPrinting){
        return(
            <OutputLine cod={cod} key={key}/>
        )
    }else if(ipt === false){
        return(
            <OutputLine cod={cod} key={key}/>
        )
    }else if(ipt === 'swicth'){
        return(
            <SwitchLine cod={cod} key={key} handleCountChange={handleCountChange}/>
        )
    }else{
        if (ipt === 'combo'){
            return(<ComboLine cod={cod} key={key} handleCountChange={handleCountChange} item={item}/> )
        }
        else{
            return(
                 <></>
            )
        }
    } 
}

export default ListLine
import Flow from '../Flow'
import FermFlx from '../../../assets/fluxogramas/fermentacao4Web.png'

function FermentacaoFlx() {
  const vh = window.innerHeight
const ideal = 727
let nVH = ideal/vh

  return (
    <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
    <div style={{ position: 'absolute', width: `${115*nVH}vh`, height: `${100*nVH}vh`, top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}}>
      <img style={{position: 'absolute', height: `${90*nVH}vh`, width: `${135*nVH}vh`, left: '0vh', top: '0vh'}} src={FermFlx} alt="" />
    {fermentacao.flows.map((item, index) => {
        return (
          <Flow flow={item.flow} x={item.x * nVH} y={item.y * nVH} align={item.align} valign={item.valign} cods={item.props} key={index} f={nVH}/>
        )
        })}
    </div>
  </div>
  )
}

export default FermentacaoFlx

const fermentacao = {
    imagem: FermFlx,
    flows: [
      { 
        flow: 'Xarope',
        props: ['flowXaropeToFerm', 'brixXarope', 'tempXarope'],
        x: 116 , y: 3.5, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Caldo',
        props: ['flowCaldoToFerm', 'brixCaldoToFerm', 'tempCaldoToFerm'],
        x: 3, y: 3.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Mel Final',
        props: ['flowMelFinal2', 'brixMelFinal2', 'tempMelFinal'],
        x: 3, y: 13.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Água Diluição',
        props: ['flowAguaDilMosto', 'tempAguaDilMosto'],
        x: 116, y: 13.5, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Mosto',
        props: ['flowMosto', 'brixMosto', 'artMassaMosto', 'tempMosto'],
        x: 68, y: 25, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Mosto Resf.',
        props: ['flowMosto', 'tempMostoResf'],
        x: 68, y: 42, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Vinho Bruto',
        props: ['flowVinhoBruto', 'concEtanolVinhoBruto', 'concCelVinhoBruto'],
        x: 68, y: 62, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Vinho',
        props: ['flowVinhoCent','concEtanolVinhoBruto','concCelVinhoCent'],
        x: 116, y: 75, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Creme',
        props: ['flowCreme','concCelCreme'],
        x: 52.5, y: 69.1, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Ferm. Trat.',
        props: ['flowfermTratado','concEtanolFermTratado','concCelFermTratato'],
        x: 46.5, y: 60, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CO2',
        props: ['flowCO2'],
        x: 40, y: 36, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Água',
        props: ['flowAguaLavCO2'],
        x: 3, y: 40, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowAguaLavCO2'],
        x: 14, y: 52, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Água',
        props: ['flowAguaComplemDilFerm'],
        x: 3, y: 70.6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['consAguaTratDiluicaoFerm'],
        x:25.3, y: 72.6, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Sangria',
        props: ['flowVolSangriaLev'],
        x: 3, y: 81, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowAguaResfMosto', 'tempAguaEntResfMosto'],
        x: 116, y: 31, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowAguaResfMosto', 'tempAguaSaiResfMosto'],
        x: 116, y: 40, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowAguaTorreFerm', 'tempAguaFriaTorreFerm'],
        x: 116, y: 47.8, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowAguaTorreFerm', 'tempAguaQuenteTorreFerm'],
        x: 116, y: 57, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowMelpEstoque', 'artMassaMelpEstoque'],
        x: 30.4, y: 14, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowMelpFerm', 'artMassaMelpFerm'],
        x: 48.5, y: 9.5, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowMelEstoque', 'brixMelEstoque', 'artMassaMelEstoque'],
        x: 40, y: 15.8, align: 'center', valign: 'flex-start'
      },
    ]
  }

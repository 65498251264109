import React, {useContext, useEffect, useState, useRef } from 'react';
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FaFilePdf } from "react-icons/fa6";
import Modal from '../../components/BarraSuperior/Modal';
import PlanoSafra from '../planoSafra';
import Headlandscape from '../../components/Pages/HeadLandscape';
import BtChartsPS from './btChartsPS';
import DayAnalyzer from '../../ANALISES/Analise Producao/DayAnalizer';
import PsTest from '../PsTest';

export default function BtExportPdfPS() {
  const divRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalText, setModalText] = useState('...');
  const { setPrinting, user } = useContext(GlobalStateContext)
  const {isSave, isNew, isSaveAs, isOpen, isEdit, isChart, psData} = useContext(PSContext)
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if(isNew || isSave || isOpen || isSaveAs || isEdit || isChart || !psData){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
  }, [isSave, isNew, isSaveAs, isOpen, isEdit, isChart])

  const addToRefs = (el) => {

    if (el && !divRefs.current.includes(el)) {
      divRefs.current.push(el);
    }
  };

   
    async function exportPDF(){
      // setPrinting(true)
      setIsLoading(true)
      setProgress(0)
      setModalText('Gerando PDF...')
      let progressValue = 0;
      const interval = setInterval(() => {
        progressValue += 5;
        if (progressValue <= 100) {
          setProgress(progressValue)
        } else {
          clearInterval(interval)
          setIsLoading(false)
          // setPrinting(false)
        }
      }, 6);
     
      const pdf = new jsPDF('l', 'mm', 'a4', true);
      
      let promiseChain = Promise.resolve();
  
      divRefs.current.forEach((div, index) => {
        console.log('index: '+index)
        
        promiseChain = promiseChain.then(async () => {
          const canvas = await html2canvas(div);
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = 277; // largura em mm para A4
          const imgHeight = 190; //(canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
          if (index < divRefs.current.length - 1){
            pdf.addPage("a4", "l");
          }
          

          
        });
        // setProgress(index*100/divRefs.current.length)

      });
  
  
  
      promiseChain.then(() => {
          // pdf.setFont('courier');
          //     pdf.setFontSize(10);
          //     pdf.setCharSpace(6);
        pdf.save('PlanoSafra_'+psData[0].safra+'_'+psData[0].revisao+'.pdf')
      }).catch(err => {
        console.error('Erro ao gerar PDF:', err);
      });
    
    };

  return (
    <div>
      {psData && 
      <div style={{ position: 'absolute', left: '-9999px' , top: '9999px'}}>
    
      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='PLANO SAFRA' subtitle={''+psData[0].safra+' - '+psData[0].revisao} fileName='Teste' nomeField='Unidade' page='1' pages='3'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <PsTest sections={['MATÉRIA PRIMA', 'MOAGEM', 'MIX', 'EFICIÊNCIAS']}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='PLANO SAFRA' subtitle={''+psData[0].safra+' - '+psData[0].revisao} fileName='Teste' nomeField='Unidade' page='2' pages='3'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <PsTest sections={['AÇÚCAR', 'ETANOL', 'ENERGIA']}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='PLANO SAFRA' subtitle={''+psData[0].safra+' - '+psData[0].revisao} fileName='Teste' nomeField='Unidade' page='3' pages='3'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <PsTest sections={['VAPOR', 'BIOMASSA', 'DIAS NÃO EFETIVOS']}/>
             </div>
      </div>

    </div>
      }
      

    <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.2vh'}}>
        <button className='psButtom' onClick={exportPDF}
        disabled={isDisable}>
        <div className='psMenuButtom' >
    <FaFilePdf  className='psMenuIcon' style={{scale: '0.95'}}/>
    <div className='psMenuText'>EXPORTAR</div></div>
        </button>
        <Modal show={isLoading} progress={progress} texto={modalText}/>
</div>
</div>
  )
}
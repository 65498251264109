import React, {useContext, useEffect, useState } from 'react';
import { FaFileCirclePlus } from "react-icons/fa6";
import PSContext from '../opoio/PSContext';

export default function BtNewPS() {
  const {isSave, setIsNew, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart} = useContext(PSContext)
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if(isSaveAs || isSave || isOpen || isExpPdf || isEdit || isChart){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
  }, [isSave, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart])

    const btClicked = () => {
      setIsNew(!isNew)
    }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
        <button className='psButtom' onClick={btClicked}
        disabled={isDisable}>
        <div className='psMenuButtom' >
    <FaFileCirclePlus  className='psMenuIcon'/>
    <div className='psMenuText'>NOVO</div></div>
        </button>
</div>
  )
}
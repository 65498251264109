import Flow from '../Flow'
import TratEtFlx from '../../../assets/fluxogramas/tratEtanolWeb.png'

function TratEtanolFlx() {
  const vh = window.innerHeight
const ideal = 727
let nVH = ideal/vh

  return (
    <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
    <div style={{ position: 'absolute', width: `${115*nVH}vh`, height: `${100*nVH}vh`, top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}}>
      <img style={{position: 'absolute', height: `${90*nVH}vh`, width: `${135*nVH}vh`, left: '0vh', top: '0vh'}} src={TratEtFlx} alt="" />
    {tratEtanol.flows.map((item, index) => {
        return (
          <Flow flow={item.flow} x={item.x * nVH} y={item.y * nVH} align={item.align} valign={item.valign} cods={item.props} key={index} f={nVH}/>
        )
        })}
    </div>
  </div>
  )
}

export default TratEtanolFlx

const tratEtanol = {
    imagem: TratEtFlx,
    flows: [
      { 
        flow: 'Caldo Etanol',
        props: ['flowCaldoEtanol','brixCaldoEtanol','tempCaldoEtanol','artMassaCaldoEtanol'],
        x: 2.4, y: 13, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowCaldoDosEtanol', 'brixCaldoDosEtanol', 'tempCaldoDosEtanol'],
        x: 85, y: 43, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Caldo Cl. Etanol',
        props: ['flowCaldoClarifEtanol', 'tempCaldoClarifEtanol'],
        x: 74 , y: 3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowCaldoClarifEtanol', 'tempCaldoClarReg'],
        x: 61.4 , y: 23.6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['tempOutCaldoRegCaldoCaldo'],
        x: 80, y: 15.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['tempOutAqVV2Etanol'],
        x: 45, y: 43.4, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['tempOutAqVV1Etanol'],
        x: 10, y: 43.4, align: 'center', valign: 'flex-end'
      },
      { 
        flow: 'VV2',
        props: ['consVaporVV2AqEtanol'],
        x: 71.6, y: 32.2, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Cond. Veg.',
        props: ['gerCondVGAqVV2Etanol'],
        x: 62.2, y: 54.8, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'VV1',
        props: ['consVaporVV1AqEtanol'],
        x: 36.7, y: 32.2, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Cond. Veg.',
        props: ['gerCondVGAqVV1Etanol'],
        x: 27.2, y: 54.8, align: 'left', valign: 'flex-start'
      },
      
      { 
        flow: '',
        props: ['flowCaldoAquecEtanol','brixCaldoAquecEtanol','tempCaldoAquecEtanol'],
        x: 12.5, y: 55, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowCaldoFlashEtanol','brixCaldoFlashEtanol','tempCaldoFlashEtanol'],
        x: 45, y: 70.3, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Caldo Clarificado',
        props: ['flowCaldoClarifEtanol','brixCaldoClarifEtanol', 'polCaldoClarifEtanol', 'artCaldoClarifEtanol', 'tempCaldoClarifEtanol', 'artMassaCaldoClarifEtanol'],
        x: 117, y: 72.8, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Lodo Etanol',
        props: ['flowLodoEtanol','brixLodoEtanol','artMassaLodoEtanol'],
        x: 2.4, y: 84.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Sol. Polimero',
        props: ['flowAguaPolimeroEtanol'],
        x: 2.4, y: 77, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Vapor Flash',
        props: ['flowVapFlashEtanol'],
        x: 21.6, y: 61.6, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Caldo Filtrado',
        props: ['flowCaldoFiltToEtanol', 'brixCaldoFiltrado', 'tempCaldoFiltrado'],
        x: 116.2, y: 42.5, align: 'right', valign: 'flex-end'
      },{ 
        flow: 'Leite de Cal',
        props: ['flowAguaLeiteCalEtanol'],
        x: 116.2, y: 47.5, align: 'right', valign: 'flex-start'
      },
      
    ]
  }

import React, { useState, useContext, useEffect } from 'react';
import GlobalStateContext from '../../components/GlobalStateContext'
import {varsAcucar, varsEtanol, varsMoagem, varsProd, varsTrat, varsRend, varsAll, varsPerdas} from './stdAnalyze'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CandyOff } from 'lucide-react';

const DayAnalyzer = () => {
    const [dataBlt, setDataBlt] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const {data, user} = useContext(GlobalStateContext)

    useEffect(() => {
      console.log('dataBlt change')
      if (dataBlt === null && global.dataBlt !== null){
        setDataBlt(global.dataBlt)
        setSelectedDate(global.selectedDate)
      }else{
        global.dataBlt = dataBlt
        global.selectedDate = selectedDate
      }
    }, [dataBlt]);

    function setDate(){
        let date = selectedDate
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são zero-indexados em JavaScript
        const year = date.getFullYear();

        const formattedDate = `${day}.${month}.${year}`;
        return formattedDate

    }

    async function getData(){
        let date = selectedDate
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são zero-indexados em JavaScript
        const year = date.getFullYear();

        const formattedDate = `${day}.${month}.${year}`;
        
        try {
            const response = await fetch(`${global.endPoint}/getBltDate?data=${formattedDate}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            });
          
            if (!response.ok) {
              throw new Error('Erro de requisição.');
            }
            let impData = await response.json();
            if (Object.keys(impData).length === 0){
                setDataBlt(null)
                global.dataBlt = null
            }else{
            
                setDataBlt(impData)
                global.dataBlt = dataBlt
                global.selectedDate = selectedDate
            }
            
          } catch (error) {
            console.error('Erro na requisição:', error);
          }

    }

    async function exportToBME(){
        for (const v of varsAll){
          if (v.codigo === "ALCTOT"){
            let PENMOL = dataBlt['PENMOL'].valor
            let ACNEUTOT = dataBlt['ACNEUTOT'].valor
            // let ACHIDTOT = dataBlt['ACHIDTOT'].valor
            let ALCTOT = dataBlt['ALCTOT'].valor

            let mixEtanolAnidro = PENMOL * 100 / ALCTOT
            let mixEtanolNeutro = ACNEUTOT * 100 / ALCTOT
            await changeBmeValue('mixEtanolAnidro', mixEtanolAnidro)
            await changeBmeValue('mixEtanolNeutro', mixEtanolNeutro)

          }
          
            if (v.ipt){
                let bltValue = dataBlt[v.codigo]?.valor
                if (v.codigo === "TCHMED"){
                  let moagemDia = dataBlt['CMOIMT'].valor
                  let horasEf = dataBlt['HEFMM'].valor
                  bltValue = moagemDia / horasEf
                }
                if (v.codigo === "FIBBAG"){
                  let bagCana = dataBlt['BACM'].valor
                  let fibCana = dataBlt['FIBPRE'].valor
                  bltValue = fibCana * 100 / bagCana
                }
                
              //   if (v.codigo === "%ARTPD3"){
              //     let perdasFermDest = dataBlt['%ARTPDSP'].valor
              //     let bmePerdasFermDest = data['perdaFermDest']?.valor
              //     let perdasIndet = dataBlt['%ARTPD3'].valor
              //     let dif = bmePerdasFermDest - perdasFermDest
              //     bltValue = perdasIndet - dif
              // }

              if (bltValue){

                await fetch(''+global.endPoint+'/'+user?.uid+'/setValue', {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({codigo: v.bmeCod, newValue: parseFloat(bltValue)})
                })
                .then(response => {
                  if (!response.ok) {
                    throw new Error('Erro de requisição.');
                  }else{
                    console.log('updated: ')
                    data[v.bmeCod].valor = parseFloat(bltValue)
                  }
                })
                .catch(error => {
                  // Lida com o erro da requisição
                })

              }
               
                
            }
        }
    }

    async function changeBmeValue(cod, newValue){
        await fetch(''+global.endPoint+'/'+user?.uid+'/setValue', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({codigo: cod, newValue: parseFloat(newValue)})
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro de requisição.');
          }else{
            console.log('updated value: '+cod + ' '+newValue)
            data[cod].valor = parseFloat(newValue)
          }
        })
        .catch(error => {
          // Lida com o erro da requisição
        })
    }

    return (
        <div style={{ padding: '2vh', textAlign: 'center', overflowY: 'scroll'}}>
          <div style={{marginBottom: '1vh', fontSize: '2vh', fontWeight: 'bold'}}>Analisar Dia</div>
          <div>
      <DatePicker
        selected={selectedDate}
        onChange={date => setSelectedDate(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="Selecione uma data"
      />
      {selectedDate && <button onClick={getData}>OK</button>}
      
    </div>
          {dataBlt && 
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {selectedDate &&
              <div style={{textAlign: 'left', marginLeft: '2.5vh', fontWeight: 'bold', fontSize: '1.6vh'}}>
              Data: {setDate()}
            </div>}
            {selectedDate &&
              <button style={{textAlign: 'left', marginRight: '2.5vh', fontSize: '1.6vh'}} onClick={exportToBME}>
                Exportar Boletim p/ BME</button>}
             
            </div>}
         
            <div style={{marginLeft: '2vh', marginRight: '2vh', marginTop: '2vh', marginBottom: '0.5vh', fontWeight: 'bold'}}>
                <div className='fullLine'>
                    <div className='fullLineRow' style={{width: '15%'}}>Código</div>
                    <div className='fullLineRow' style={{width: '35%'}}>Descrição</div>
                    <div className='fullLineRow' style={{width: '15%'}}>Boletim</div>
                    <div className='fullLineRow' style={{width: '15%'}}>BME</div>
                    {/* <div className='fullLineRow' style={{width: '15%'}}>Desvio</div> */}
                    <div className='fullLineRow' style={{width: '15%', textAlign: 'right'}}>Unidade</div>
                    
                </div>
          </div>
          {dataBlt && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>MOAGEM</div>}
          {dataBlt && varsMoagem.map((item) => {
            let filteredData = dataBlt[item.codigo]
                  return (
                  <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
              <div className='fullLine'>
                  <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                  <div className='fullLineRowBol' style={{width: '35%'}}>{data[item.bmeCod]?.descricao}</div>
                  <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : parseFloat(filteredData?.valor).toLocaleString('pt-BR')}</div>
                  <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{parseFloat(data[item.bmeCod]?.valor).toLocaleString('pt-BR')}</div>
                  <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
              </div>
          </div> 
          )})}

        {dataBlt && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>TRATAMENTO DO CALDO</div>}
        {dataBlt && varsTrat.map((item) => {
            let filteredData = dataBlt[item.codigo]
                return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '35%'}}>{data[item.bmeCod]?.descricao}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : parseFloat(filteredData?.valor).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{parseFloat(data[item.bmeCod]?.valor).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
            </div>
        </div> 
        )})}

        {dataBlt && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>FÁBRICA AÇÚCAR</div>}
        {dataBlt && varsAcucar.map((item) => {
            let filteredData = dataBlt[item.codigo]
                return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '35%'}}>{data[item.bmeCod]?.descricao}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : parseFloat(filteredData?.valor).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{parseFloat(data[item.bmeCod]?.valor).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
            </div>
        </div> 
        )})}

        {dataBlt && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>FERMENTAÇÃO / DESTILARIA</div>}
        {dataBlt && varsEtanol.map((item) => {
            let filteredData = dataBlt[item.codigo]
                return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '35%'}}>{data[item.bmeCod]?.descricao}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : parseFloat(filteredData?.valor).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{parseFloat(data[item.bmeCod]?.valor).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
            </div>
        </div> 
        )})}

      {dataBlt && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>EFICIÊNCIA / PERDAS ART</div>}
      {dataBlt && varsPerdas.map((item) => {
          let filteredData = dataBlt[item.codigo]
              return (
              <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
          <div className='fullLine'>
              <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
              <div className='fullLineRowBol' style={{width: '35%'}}>{data[item.bmeCod]?.descricao}</div>
              <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : parseFloat(filteredData?.valor).toLocaleString('pt-BR')}</div>
              <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{parseFloat(data[item.bmeCod]?.valor).toLocaleString('pt-BR')}</div>
              <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
          </div>
      </div> 
      )})}

        {dataBlt && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>PRODUÇÃO</div>}
        {dataBlt && varsProd.map((item) => {
            let filteredData = dataBlt[item.codigo]
                return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '35%'}}>{data[item.bmeCod]?.descricao}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : parseFloat(filteredData?.valor).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{parseFloat(data[item.bmeCod]?.valor).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
            </div>
        </div> 
        )})}

        {dataBlt && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>RENDIMENTOS</div>}
        {dataBlt && varsRend.map((item) => {
            let filteredData = dataBlt[item.codigo]
                return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '35%'}}>{data[item.bmeCod]?.descricao}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : parseFloat(filteredData?.valor).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{parseFloat(data[item.bmeCod]?.valor).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
            </div>
        </div> 
        )})}
    
          
        </div>
      );
}

export default DayAnalyzer;
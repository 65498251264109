// components


import {useEffect, useContext, useState } from 'react';

import './App.css';
import Main from './components/Main';
import Login from './components/Login'
import { GlobalStateContext }  from './components/GlobalStateContext'
import { getAlertList } from './Funcoes/GetAletrsList';
import { useFetch } from './Hooks/useFetch';

function App() {
  const {setData, data, setlistAlerts, setUser, user} = useContext(GlobalStateContext)
  const [upd, setUpd] = useState(false)

  const userDev = {
    profile: 'developer',
    id: 1,
    email: 'emalmeida@gmail.com',
    firstName: 'Eduardo',
    lastName: 'Almeida',
    units: ['all']
  }

  // global.endPoint = 'https://pms-api-santaelisa.onrender.com'
  // global.endPoint = 'HTTP://localhost:7096'

  // useEffect(() => {
  //   console.log('update')
  //   setUpd(!upd)
  // }, [data])

  // useEffect(() => {
  //   console.log('Initial Execution')
  //   // setUser(null)
  //   setlistAlerts(getAlertList(data))
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(''+global.endPoint+'/'+user?.uid+'/getAll');
  //       if (!response.ok) {
  //         throw new Error('Erro ao buscar dados da API');
  //       }
  //       const jsonData = await response.json();

  //       // console.log(jsonData)
        
  //       setData(jsonData);
  //       setlistAlerts(getAlertList(data))
  //     } catch (error) {
  //       console.error('Erro ao buscar dados da API:', error);
  //     }
  //   }
  //   user && fetchData();
  // }, [setData, setlistAlerts]); // O segundo argumento vazio significa que o useEffect será executado apenas uma vez, após a montagem do componente

  return (
      <div className="App">
        <div className='container'>
          {user ? <Main/> : <Login/>}
          {/* <Main/> */}
        </div>
      </div>
  )
}

export default App;


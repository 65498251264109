import React, { useContext, useEffect } from 'react'
import GlobalStateContext from '../GlobalStateContext'

function Flow({flow, x, y, align, valign, cods, f = 1}) {
    const {area, data} = useContext(GlobalStateContext)

//     const [updated, setUpdate] = useState(false)

  useEffect(() => {
    console.log('Flow Effect')
  }, [data]);

    const style = {
        position: 'absolute',
        height: `${1.5 * f}vh`,
        width: `${15 * f}vh`,
        left: `${x}vh`,
        top: `${y}vh`,
        // border: '1px solid red',
        fontSize:`${1.5 * f}vh`,
      };

      function getAbrev(cod){
        if (area === 'balHidrico'){
            return ''
        }
        let abrev = cod.substring(0,3)
        switch (abrev){
            case 'pol': return 'Pol'
            case 'pur': return 'Pur'
            case 'fib': return 'Fib'
            case 'arC': return 'Ar'
            case 'art': if (cod.substring(0,8) !== 'artMassa'){return 'Art'}else{break}
            case 'bri': return 'Bx'
            default: return ''
        }
    }

  return (
    <div style={style}>
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: `${valign}`, gap: '0'}}>
        {flow !== '' ? 
        <p style={{margin: '0', textAlign: `${align}`, fontWeight: 'bold'}}>{flow}</p> :
        <></>}
        {data && data[cods[0]].valor === 0 ?
                <p style={{margin: '0', textAlign: `${align}`}}>
                    {0} {area !== 'balHidrico' ? data[cods[0]].unidade : ''}{getAbrev(cods[0])}</p> :
                    <></>}

        {data && data[cods[0]].valor !== 0 && cods.map((cod, index) => {
            // console.log('cod: '+cod + '  '+ data[cod].valor)
            return (
                <p key={''+cod+''+index} style={{margin: '0', textAlign: `${align}`, 
                color: `${(((data[cod].valor < data[cod].limiteMin) && (data[cod].limiteMin !== null)) || ((data[cod].valor > data[cod].limiteMax) && (data[cod].limiteMax !== null))) && 'red'}`}}>
                    {/* {data[cod].valor} */}
                    {data[cod].valor.toFixed(data[cod].valor == data[cod].valor.toFixed(0) || data[cod].valor > 1000 ? 0 : data[cod].valor == data[cod].valor.toFixed(1) || data[cod].valor > 100 ? 1 : 2)} {area !== 'balHidrico' ? data[cod].unidade : ''}{getAbrev(cod)}
                    </p>
            )
        })}
        </div>
      </div>
  )
}

export default Flow


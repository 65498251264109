module.exports = {
    calcPS,
    setZeros,
    checkReal,
    abrirPs
}

async function calcPS(psData, psDataReal) {
    let tempData = psData
    let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
    let psReal = await abrirPs(psData[0].safra, '13_Realizado')
    for (const month of months){
        if (psData[0].revisao === '13_Realizado'){
            tempData = await calcReal(month, tempData)
            console.log('Mes Calculado: '+psData[0].safra + ' - '+psData[0].revisao + ' month: '+month)
        }else{
            if (!checkReal(psData, month)){
                tempData = calcPlan(month, tempData, psReal)
            }else{
                for (const item of psReal){
                    tempData.filter(i => i.codigo === item.codigo)[0][month] = item[month]
                }
            }
        }
    }
    tempData = await calcAcum(tempData)
    // console.log('tempData:')
    // console.log(tempData.filter(item => item.codigo === 'psMoagemTotal')[0])
    return tempData

}

function calcPlan(month, data, psReal){
    let tempData = data
    //MATERIA-PRIMA
    let artdigestor = getValue("psAtrDigestor", month, tempData)
    let atrpcts = getValue("psAtrPcts", month, tempData)
    let fibracana = getValue("psFibraCana", month, tempData)
    let fibrapag = getValue("psFibraBagaco", month, tempData)
    let bagacocana = fibrapag == 0.0 ? 0.0 : fibracana*100/fibrapag
    tempData = setValue(tempData, "psBagacoCana", month, bagacocana)

    //UPTIME
    let uptimeGeral = getValue("psUptimeGeral", month, tempData)
    let dias = getValue("psDiasSafra", month, tempData)

    //MOAGEM
    let eficExtracao = 100 - getValue("psPerdaBagaco", month, tempData) 
    let taxamoagem = getValue("psTaxaMoagem", month, tempData)
    let moagemTotal = uptimeGeral/100 * dias * 24.0 * taxamoagem
    let taxaprocart = taxamoagem * artdigestor/100
    let taxaprocatr = taxamoagem * atrpcts/1000
    let taxaprocfib = taxamoagem * fibracana/100
    let moagemMedDia = taxamoagem == 0.0 ? 0.0 : dias == 0.0 ? 0.0 : moagemTotal/dias
    let moagemEfDia = taxamoagem == 0.0 ? 0.0 : dias == 0.0 ? 0.0 : uptimeGeral == 0.0 ? 0.0 : moagemTotal/dias/(uptimeGeral/100)
    tempData = setValue(tempData, "psEficExtracao", month, eficExtracao)
    tempData = setValue(tempData, "psMoagemTotal", month, moagemTotal)
    tempData = setValue(tempData, "psTaxaAtr", month, taxaprocatr)
    tempData = setValue(tempData, "psTaxaArt", month, taxaprocart)
    tempData = setValue(tempData, "psTaxaFibra", month, taxaprocfib)
    tempData = setValue(tempData, "psMoagemDiaria", month, moagemMedDia)
    tempData = setValue(tempData, "psMoagemDiariaEf", month, moagemEfDia)

    //MIX
    let mixAcucar = getValue("psMixAcucar", month, tempData)
    let mixEtanol = 100 - mixAcucar
    tempData = setValue(tempData, "psMixEtanol", month, mixEtanol)

    //EFICIENCIAS
    let efInd =  getValue("psEficienciaIndustrial", month, tempData)
    let artent = moagemTotal * artdigestor/100
    let atrent = moagemTotal * atrpcts
    let artRec = artent * efInd/100
    tempData = setValue(tempData, "psArtEntrado", month, artent)
    tempData = setValue(tempData, "psArtRecuperado", month, artRec)

    //ACUCAR
    let taxaEfProdAcucar =  getValue("psTaxaEfProdAcucar", month, tempData)
    let mixVHP = getValue("psMixProdAcucarVHP", month, tempData)
    let taxaEfProdAcucarScs = taxaEfProdAcucar*20
    let prodTotalAcucar = taxaEfProdAcucar * dias * uptimeGeral/100
    let prodAcucarCristal = prodTotalAcucar * (1 - mixVHP/100)
    tempData = setValue(tempData, "psTaxaEfProdAcucar", month, taxaEfProdAcucar)
    tempData = setValue(tempData, "psTaxaEfProdAcucarScs", month, taxaEfProdAcucarScs)
    tempData = setValue(tempData, "psProdAcucarTotal", month, prodTotalAcucar)
    tempData = setValue(tempData, "psProdAcucarTotalSC", month, prodTotalAcucar*20)
    tempData = setValue(tempData, "psProdAcucarVHP", month, prodTotalAcucar * mixVHP/100)
    tempData = setValue(tempData, "psProdAcucarVHPSC", month, (prodTotalAcucar * mixVHP/100)*20)
    tempData = setValue(tempData, "psProdAcucarCristal", month, prodAcucarCristal)
    tempData = setValue(tempData, "psProdAcucarCristalSC", month, prodAcucarCristal*20)
    tempData = setValue(tempData, "psRelProdAcArtEnt", month, artent == 0 ? 0 :prodTotalAcucar/artent)
    tempData = setValue(tempData, "psRelProdAcAtrEnt", month, atrent == 0 ? 0 :prodTotalAcucar*1000/atrent)

    //UPTIME DNE
    var uptimeAnidro = getValue("psUptimeAnidro", month, tempData) 
    var uptimeVapor = getValue("psUpTimeVapor", month, tempData) 
    if (uptimeGeral > uptimeVapor){
        uptimeVapor = uptimeGeral
        tempData = setValue(tempData, "psUpTimeVapor", month, uptimeVapor)
    }
    if (uptimeAnidro > uptimeVapor){
        uptimeAnidro = uptimeVapor
        tempData = setValue(tempData, "psUptimeAnidro", month, uptimeAnidro)
    } 

    //ETANOL
    let prodEfEtanolAnidro =  getValue("psProdEfEtanolAnidro", month, tempData) 
    let prodEfEtanolNeutro = getValue("psProdEfEtanolNeutro", month, tempData)
    let prodEfEtanol100 = getValue("psProdEfEtanol100", month, tempData)
    let prodEfEtanolHidpTq = getValue("psProdEfEtanolHidpTq", month, tempData)
    let repEfEtanolHid = getValue("psRepEfEtanolHid", month, tempData)
    let prodEtanol100 = prodEfEtanol100 * dias * uptimeGeral/100
    let prodEtanolHidpTq = prodEfEtanolHidpTq * dias * uptimeGeral/100
    let prodEtanolAnidro = prodEfEtanolAnidro * dias * uptimeGeral/100
    let prodEtanolNeutro = prodEfEtanolNeutro * dias * uptimeGeral/100
    let repEtanolHid = repEfEtanolHid * dias * uptimeGeral/100
    let prodTotalEtanol = prodEtanolHidpTq + prodEtanolAnidro + prodEtanolNeutro
    tempData = setValue(tempData, "psProdTotalEtanol100", month, prodEtanol100)
    tempData = setValue(tempData, "psProdEtanolTotalHid", month, prodEtanolHidpTq)
    tempData = setValue(tempData, "psProdEtanolAnidro", month, prodEtanolAnidro)
    tempData = setValue(tempData, "psProdEtanolNeutro", month, prodEtanolNeutro)
    tempData = setValue(tempData, "psRepEtanolHid", month, repEtanolHid)
    tempData = setValue(tempData, "psProdEtanolTotal", month, prodTotalEtanol)

    //unieqs
    let unieqs = prodEtanolHidpTq*32.04 + prodEtanolAnidro*33.44 + prodTotalAcucar*20
    let unieqsTc = moagemTotal == 0 ? 0 : (prodEtanolHidpTq*32.04 + prodEtanolAnidro*33.44 + prodTotalAcucar*20)/moagemTotal
    tempData = setValue(tempData, "psProdUnieqs", month, unieqs)
    tempData = setValue(tempData, "psProdUnieqsTc", month, unieqsTc)
    

    //UNicop
    let unic = moagemTotal == 0 ? 0 : (prodEtanolHidpTq*1000*0.0300 + prodEtanolAnidro*1000*0.0324 + prodTotalAcucar*20*1)/moagemTotal
    tempData = setValue(tempData, "psProdUnicops", month, unic)

    //levedura
    // let prodEfLevedura = getValue("psEfProdLevedura", month, tempData)
    // let prodLevSeca = prodEfLevedura * dias * uptimeGeral/100
    // let levSecaPorLitrosEt = prodEtanol100 == 0 ? 0.0 : prodLevSeca*1000/(prodEtanol100/0.9554)
    // tempData = setValue(tempData, "psProdLevedura", month, prodLevSeca)
    // tempData = setValue(tempData, "pslevSecaPorLitrosEt", month, levSecaPorLitrosEt)
    
    //DNE
    let ptExportdaDNE = getValue("dnePtExportada", month, tempData)
    let prodEfVaporDNE = getValue("dneProdVapor", month, tempData)
    let rendCaldeiraDNE = getValue("dneRendCaldeira", month, tempData)
    if (rendCaldeiraDNE === 0.0){
        tempData = setValue(tempData, "dneRendCaldeira", month, 2.2)
    }
    let energiaExportadaDNE = ptExportdaDNE * dias * 24 * (uptimeVapor - uptimeGeral)/100
    let prodTotalVaporDNE = prodEfVaporDNE * dias * 24 * (uptimeVapor - uptimeGeral)/100
    let consEfBagacoDNE = prodEfVaporDNE / rendCaldeiraDNE
    let consTotalBagacoDNE = prodTotalVaporDNE / rendCaldeiraDNE
    
    tempData = setValue(tempData, "dneEnergiaExportada", month, energiaExportadaDNE)
    tempData = setValue(tempData, "dneProdTotalVapor", month, prodTotalVaporDNE)
    tempData = setValue(tempData, "dneConsBagaco", month, consEfBagacoDNE)
    tempData = setValue(tempData, "dneConsTotalBagaco", month, consTotalBagacoDNE)
    
    //ENERGIA
    let ptExportada = getValue("psPtExportada", month, tempData)
    let ptConsumida = getValue("psPtConsumida", month, tempData)
    let energiaExportada = ptExportada * 24 * dias * uptimeGeral/100 + energiaExportadaDNE
    let energiaConsumida = ptConsumida * 24 * dias * uptimeGeral/100
    let ptExportadaTC = moagemTotal == 0 ? 0 : energiaExportada * 1000 / moagemTotal
    let ptConsumidaTC = moagemTotal == 0 ? 0 : energiaConsumida * 1000 / moagemTotal
    tempData = setValue(tempData, "psEnergiaExportada", month, energiaExportada)
    tempData = setValue(tempData, "psEnergiaCosumida", month, energiaConsumida)
    tempData = setValue(tempData, "psPtExportadaTC", month, ptExportadaTC)
    tempData = setValue(tempData, "psPtConsumidaTC", month, ptConsumidaTC)
    
    //BIOMASSA
    let prodEfBagaco = getValue("psProdEfBagacao", month, tempData)
    let consEfBagaco = getValue("psConsEfBagacao", month, tempData)
    let vendaTotalBagaco = getValue("psVendaBagaco", month, tempData)
    let ajusteEstBag = getValue("psAjusteEstoqueBag", month, tempData)
    let prodTotalBagaco = prodEfBagaco * dias * 24 * uptimeGeral/100
    let consTotalBagaco = consEfBagaco * dias * 24 * uptimeGeral/100 + consTotalBagacoDNE
    let sobraTotalBagaco = prodTotalBagaco - consTotalBagaco
    var estoqueFinalLasMonth = 0.0
    if (month !== 'jan'){
        let lastMonth = getLastMonth(month)
        let isLastMonthReal = checkReal(tempData, lastMonth)
        if (isLastMonthReal && psReal){
            estoqueFinalLasMonth = getRealItem(psReal, 'psEstoqueFinalBag', month)
        }else{
            estoqueFinalLasMonth = getValue("psEstoqueFinalBag", getLastMonth(month), tempData)
        }
        // console.log('isLastMonthReal: '+isLastMonthReal+ ' LastMonth: '+lastMonth+ '  ThisMonth: '+month + '  Estoque Final Last: '+estoqueFinalLasMonth)
    }
    
    let estoqueInicioBagaco = estoqueFinalLasMonth //+ ajusteEstBag
    let estoqueFinalbagaco = estoqueInicioBagaco + sobraTotalBagaco + ajusteEstBag - vendaTotalBagaco

    tempData = setValue(tempData, "psProdBagacao", month, prodTotalBagaco)
    tempData = setValue(tempData, "psConsBagacao", month, consTotalBagaco)
    tempData = setValue(tempData, "psSobraBagaco", month, sobraTotalBagaco)
    tempData = setValue(tempData, "psEstoqueFinalBag", month, estoqueFinalbagaco)
    tempData = setValue(tempData, "psEstoqueInicialBag", month, estoqueInicioBagaco)
    
    //VAPOR
    let prodEfVapor = getValue("psProdEfVapor", month, tempData)
    let prodTotalVapor = prodEfVapor * dias * 24 * uptimeGeral/100 + prodTotalVaporDNE
    tempData = setValue(tempData, "psProdVapor", month, prodTotalVapor)
    
    let consEspVaporTCTotal = moagemTotal == 0.0 ? 0.0 : (prodTotalVaporDNE + prodTotalVapor)*1000/moagemTotal
    tempData = setValue(tempData, "psConsVaporTcTotal", month, consEspVaporTCTotal)
    
    //OUTROS
    let psProdEfVinhaca = getValue("psProdEfVinhaca", month, tempData)
    let psProdEfTorta = getValue("psProdEfTorta", month, tempData)
    var psProdVinhacapEtanol = prodTotalEtanol == 0.0 ? 0.0 : (psProdEfVinhaca * dias * 24 * uptimeGeral/100)/prodTotalEtanol
    var psProdTortaTc = moagemTotal == 0.0 ? 0.0 : (psProdEfTorta * dias * 24 * uptimeGeral/100)*1000/moagemTotal
    if (moagemTotal === 0.0){
        psProdTortaTc = 0.0
        psProdVinhacapEtanol = 0.0
    }
    tempData = setValue(tempData, "psProdVinhacapEtanol", month, psProdVinhacapEtanol)
    tempData = setValue(tempData, "psProdTortaTc", month, psProdTortaTc)
    
    // console.log('psProdEfVinhaca: '+psProdEfVinhaca)
    // console.log('psProdEfTorta: '+psProdEfTorta)

    return tempData

}

function calcReal(month, data){
    let tempData = data
    //MATERIA-PRIMA
    let artdigestor = getValue("psAtrDigestor", month, tempData)
    let atrpcts = getValue("psAtrPcts", month, tempData)
    let fibracana = getValue("psFibraCana", month, tempData)
    let fibrapag = getValue("psFibraBagaco", month, tempData)
    let bagacocana = fibrapag == 0.0 ? 0.0 : fibracana*100/fibrapag
    tempData = setValue(tempData, "psBagacoCana", month, bagacocana)

    //UPTIME
    let uptimeGeral = getValue("psUptimeGeral", month, tempData)
    let dias = getValue("psDiasSafra", month, tempData)

    //MOAGEM
    let moagemTotal = getValue("psMoagemTotal", month, tempData)
    let psTaxaMoagem = dias === 0 || uptimeGeral === 0 ? 0 : moagemTotal * 100 / 24 / dias / uptimeGeral
    let psTaxaArt = psTaxaMoagem * artdigestor / 100
    let psTaxaAtr = psTaxaMoagem * atrpcts / 1000
    let psTaxaFibra = psTaxaMoagem * fibracana / 100
    let psMoagemDiaria = dias === 0 ? 0 : moagemTotal / dias
    let psMoagemDiariaEf = uptimeGeral === 0 ? 0 : psMoagemDiaria/(uptimeGeral/100)
    tempData = setValue(tempData, "psTaxaMoagem", month, psTaxaMoagem)
    tempData = setValue(tempData, "psTaxaArt", month, psTaxaArt)
    tempData = setValue(tempData, "psTaxaAtr", month, psTaxaAtr)
    tempData = setValue(tempData, "psTaxaFibra", month, psTaxaFibra)
    tempData = setValue(tempData, "psMoagemDiaria", month, psMoagemDiaria)
    tempData = setValue(tempData, "psMoagemDiariaEf", month, psMoagemDiariaEf)

    //MIX
    let mixAcucar = getValue("psMixAcucar", month, tempData)
    let mixEtanol = 100 - mixAcucar
    tempData = setValue(tempData, "psMixEtanol", month, mixEtanol)

    //EFICIENCIAS
    let psPerdaLavavem =  getValue("psPerdaLavavem", month, tempData)
    let psPerdaBagaco =  getValue("psPerdaBagaco", month, tempData)
    let psPerdaTorta = getValue("psPerdaTorta", month, tempData)
    let psPerdaMultijato = getValue("psPerdaMultijato", month, tempData)
    let psPerdaFermentacao = getValue("psPerdaFermentacao", month, tempData)
    let psPerdaResiduaria = getValue("psPerdaResiduaria", month, tempData)
    let psPerdaIndeterminada = getValue("psPerdaIndeterminada", month, tempData)
    let efInd =  100 - (psPerdaLavavem + psPerdaBagaco + psPerdaTorta + psPerdaMultijato + psPerdaFermentacao + psPerdaResiduaria + psPerdaIndeterminada)
    let artent = moagemTotal * artdigestor/100
    let atrent = moagemTotal * atrpcts
    let artRec = artent * efInd/100
    tempData = setValue(tempData, "psArtEntrado", month, artent)
    tempData = setValue(tempData, "psArtRecuperado", month, artRec)
    tempData = setValue(tempData, "psEficienciaIndustrial", month, efInd)

    //ACUCAR
    let psProdAcucarTotalSC = getValue("psProdAcucarTotalSC", month, tempData)
    let psMixProdAcucarVHP = getValue("psMixProdAcucarVHP", month, tempData)
    let psProdAcucarTotal = psProdAcucarTotalSC / 20
    let psRelProdAcAtrEnt = moagemTotal === 0 || atrpcts === 0 ? 0 : psProdAcucarTotal*1000/(moagemTotal*atrpcts)
    let psProdAcucarVHP = psProdAcucarTotal * psMixProdAcucarVHP / 100
    let psProdAcucarVHPSC = psProdAcucarVHP * 20
    let psProdAcucarCristal = psProdAcucarTotal * (100-psMixProdAcucarVHP) / 100
    let psProdAcucarCristalSC = psProdAcucarCristal * 20
    let psTaxaEfProdAcucar = dias === 0 || uptimeGeral === 0 ? 0 : psProdAcucarTotal/dias/(uptimeGeral/100)
    let psTaxaEfProdAcucarScs = psTaxaEfProdAcucar * 20

    tempData = setValue(tempData, "psProdAcucarTotal", month, psProdAcucarTotal)
    tempData = setValue(tempData, "psRelProdAcAtrEnt", month, psRelProdAcAtrEnt)
    tempData = setValue(tempData, "psProdAcucarVHP", month, psProdAcucarVHP)
    tempData = setValue(tempData, "psProdAcucarVHPSC", month, psProdAcucarVHPSC)
    tempData = setValue(tempData, "psProdAcucarCristal", month, psProdAcucarCristal)
    tempData = setValue(tempData, "psProdAcucarCristalSC", month, psProdAcucarCristalSC)
    tempData = setValue(tempData, "psTaxaEfProdAcucar", month, psTaxaEfProdAcucar)
    tempData = setValue(tempData, "psTaxaEfProdAcucarScs", month, psTaxaEfProdAcucarScs)

    //ETANOL
    let psProdEtanolTotalHid = getValue("psProdEtanolTotalHid", month, tempData)
    let psProdEtanolNeutro = getValue("psProdEtanolNeutro", month, tempData) 
    let psProdEtanolAnidro = getValue("psProdEtanolAnidro", month, tempData) 

    let psProdEtanolTotal = psProdEtanolTotalHid + psProdEtanolNeutro + psProdEtanolAnidro
    let psProdTotalEtanol100 = (psProdEtanolTotalHid+psProdEtanolNeutro)*0.9554+psProdEtanolAnidro*0.9953
    let psProdEfEtanolHidpTq = dias === 0 || uptimeGeral === 0 ? 0 : psProdEtanolTotalHid/dias/(uptimeGeral/100)
    let psProdEfEtanolAnidro = dias === 0 || uptimeGeral === 0 ? 0 : psProdEtanolAnidro/dias/(uptimeGeral/100)
    let psRepEfEtanolHid = 0
    let psProdEfEtanolHid = psProdEfEtanolAnidro*0.9953/0.9554 + psProdEfEtanolHidpTq - psRepEfEtanolHid
    let psProdEfEtanolNeutro = dias === 0 || uptimeGeral === 0 ? 0 : psProdEtanolNeutro/dias/(uptimeGeral/100)
    let psProdEfEtanol100 = psProdEfEtanolHid*0.9554

    tempData = setValue(tempData, "psProdEtanolTotal", month, psProdEtanolTotal)
    tempData = setValue(tempData, "psProdTotalEtanol100", month, psProdTotalEtanol100)
    tempData = setValue(tempData, "psProdEfEtanolHidpTq", month, psProdEfEtanolHidpTq)
    tempData = setValue(tempData, "psProdEfEtanolAnidro", month, psProdEfEtanolAnidro)
    tempData = setValue(tempData, "psProdEfEtanolHid", month, psProdEfEtanolHid)
    tempData = setValue(tempData, "psProdEfEtanolNeutro", month, psProdEfEtanolNeutro)
    tempData = setValue(tempData, "psRepEfEtanolHid", month, psRepEfEtanolHid)
    tempData = setValue(tempData, "psProdEfEtanol100", month, psProdEfEtanol100)

    //unieqs
    // let unieqs = psProdEtanolTotalHid*32.04 + psProdEtanolAnidro*33.44 + psProdAcucarTotal*20
    // let unieqsTc = moagemTotal == 0 ? 0 : (psProdEtanolTotalHid*32.04 + psProdEtanolAnidro*33.44 + psProdAcucarTotal*20)/moagemTotal
    // tempData = setValue(tempData, "psProdUnieqs", month, unieqs)
    // tempData = setValue(tempData, "psProdUnieqsTc", month, unieqsTc)
    

    //UNicop
    let unic = moagemTotal == 0 ? 0 : (psProdEtanolTotalHid*1000*0.0300 + psProdEtanolAnidro*1000*0.0324 + psProdAcucarTotal*20*1)/moagemTotal
    tempData = setValue(tempData, "psProdUnicops", month, unic)

    //levedura
    // let prodEfLevedura = getValue("psEfProdLevedura", month, tempData)
    // let prodLevSeca = prodEfLevedura * dias * uptimeGeral/100
    // let levSecaPorLitrosEt = prodEtanol100 == 0 ? 0.0 : prodLevSeca*1000/(prodEtanol100/0.9554)
    // tempData = setValue(tempData, "psProdLevedura", month, prodLevSeca)
    // tempData = setValue(tempData, "pslevSecaPorLitrosEt", month, levSecaPorLitrosEt)
    
    //ENERGIA
    let psEnergiaExportada = getValue("psEnergiaExportada", month, tempData)
    let psEnergiaCosumida = getValue("psEnergiaCosumida", month, tempData)
    let psPtExportada = dias === 0 || uptimeGeral === 0 ? 0 : psEnergiaExportada*100/24/dias/uptimeGeral
    let psPtConsumida = dias === 0 || uptimeGeral === 0 ? 0 : psEnergiaCosumida*100/24/dias/uptimeGeral
    let psPtExportadaTC = moagemTotal == 0 ? 0 : psEnergiaExportada * 1000 / moagemTotal
    let psPtConsumidaTC = moagemTotal == 0 ? 0 : psEnergiaCosumida * 1000 / moagemTotal

    tempData = setValue(tempData, "psPtExportada", month, psPtExportada)
    tempData = setValue(tempData, "psPtConsumida", month, psPtConsumida)
    tempData = setValue(tempData, "psPtExportadaTC", month, psPtExportadaTC)
    tempData = setValue(tempData, "psPtConsumidaTC", month, psPtConsumidaTC)

    //VAPOR
    let psUpTimeVapor = getValue("psUpTimeVapor", month, tempData)
    let psProdVapor = getValue("psProdVapor", month, tempData)
    let psProdEfVapor = dias === 0 || psUpTimeVapor === 0 ? 0 : psProdVapor/dias/24/(psUpTimeVapor/100)
    let psConsVaporTc = psTaxaMoagem === 0 ? 0 : psProdEfVapor*1000/psTaxaMoagem
    let psConsVaporTcTotal = moagemTotal === 0 ? 0 : psProdVapor*1000/moagemTotal

    tempData = setValue(tempData, "psProdEfVapor", month, psProdEfVapor)
    tempData = setValue(tempData, "psConsVaporTc", month, psConsVaporTc)
    tempData = setValue(tempData, "psConsVaporTcTotal", month, psConsVaporTcTotal)

    
    //BIOMASSA
    let psConsBagacao = getValue("psConsBagacao", month, tempData)
    let psVendaBagaco = getValue("psVendaBagaco", month, tempData)
    let psAjusteEstoqueBag = getValue("psAjusteEstoqueBag", month, tempData)
    let psProdBagacao = moagemTotal*bagacocana/100
    let psProdEfBagacao = dias === 0 || uptimeGeral === 0 ? 0 : psProdBagacao*100/dias/24/uptimeGeral
    let psConsEfBagacao = dias === 0 || uptimeGeral === 0 ? 0 : psConsBagacao*100/dias/24/uptimeGeral
    let psSobraBagaco = psProdBagacao - psConsBagacao
    var estoqueFinalLasMonth = 0.0
    if (month !== 'jan'){
        estoqueFinalLasMonth = getValue("psEstoqueFinalBag", getLastMonth(month), tempData)
    }else{
        estoqueFinalLasMonth = getValue("psEstoqueInicialBag", month, tempData)
    }
    let psEstoqueInicialBag = estoqueFinalLasMonth 
    let psEstoqueFinalBag = psEstoqueInicialBag + psSobraBagaco + psAjusteEstoqueBag - psVendaBagaco

    tempData = setValue(tempData, "psProdBagacao", month, psProdBagacao)
    tempData = setValue(tempData, "psProdEfBagacao", month, psProdEfBagacao)
    tempData = setValue(tempData, "psConsEfBagacao", month, psConsEfBagacao)
    tempData = setValue(tempData, "psSobraBagaco", month, psSobraBagaco)
    tempData = setValue(tempData, "psEstoqueInicialBag", month, psEstoqueInicialBag)
    tempData = setValue(tempData, "psEstoqueFinalBag", month, psEstoqueFinalBag)

    return tempData

}

function getPSValueSum(data, cod){
    var s = 0.0
    let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
    for (const m in months){
        s += data.filter(i => i.codigo === cod)[0][months[m]]
    }
    return s
}

function getPSValueSumProd(data, cod, cod2){
    let data1 = data.filter(i => i.codigo === cod)[0]
    let data2 = data.filter(i => i.codigo === cod2)[0]
    // console.log(cod)
    // console.log(data1)
    // console.log(cod2)
    // console.log(data2)
    var s = 0.0
    var p = 0.0
    let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
    for (const m in months){
        let v1 = data1[months[m]]
        let v2 = data2[months[m]]
        s += v2
        p += v1 * v2
    }
    if (s == 0){
        return 0
    }else{
        return p/s
    }

}

async function calcAcum(data){
    console.log('Start Calc Acum')
    console.log(data)
    //VALORES ACUMULADOS
    let tempData = data
    let acumMoagemTotal = getPSValueSum(tempData, 'psMoagemTotal')
    let acumAtrPcts = getPSValueSumProd(tempData, 'psAtrPcts', 'psMoagemTotal')
    let acumArtDigestor = getPSValueSumProd(tempData, 'psAtrDigestor', 'psMoagemTotal')
    let acumFibraCana = getPSValueSumProd(tempData, 'psFibraCana', 'psMoagemTotal')
    let acumFibraBag = getPSValueSumProd(tempData, 'psFibraBagaco', 'psMoagemTotal')
    let acumBagCana = getPSValueSumProd(tempData, 'psBagacoCana', 'psMoagemTotal')
    // let acumEficExtracao = getPSValueSumProd(tempData, 'psEficExtracao', 'psAtrDigestor')

    tempData = setValue(tempData, "psMoagemTotal", 'acu', acumMoagemTotal)
    tempData = setValue(tempData, "psAtrPcts", 'acu', acumAtrPcts)
    tempData = setValue(tempData, "psAtrDigestor", 'acu', acumArtDigestor)
    tempData = setValue(tempData, "psFibraCana", 'acu', acumFibraCana)
    tempData = setValue(tempData, "psFibraBagaco", 'acu', acumFibraBag)
    tempData = setValue(tempData, "psBagacoCana", 'acu', acumBagCana)
    // tempData = setValue(tempData, "psEficExtracao", 'acu', acumEficExtracao)

    let acumUptimeGeral = getPSValueSumProd(tempData, 'psUptimeGeral', 'psDiasSafra')
    let acumDiasSafra = getPSValueSum(tempData, 'psDiasSafra')
//     let acumDownClima = getPSValueSumProd(.psDowntimeClima, .psDiasSafra)
//     let acumDownAgr = getPSValueSumProd(.psDowntimeAgr, .psDiasSafra)
//     let acumDownInd = getPSValueSumProd(.psDowntimeInd, .psDiasSafra)
//     let acumDownOutros = getPSValueSumProd(.psDowntimeOutros, .psDiasSafra)

    tempData = setValue(tempData, "psUptimeGeral", 'acu', acumUptimeGeral)
    tempData = setValue(tempData, "psDiasSafra", 'acu', acumDiasSafra)
//     psVar.psDowntimeClima.setValue(.acum, acumDownClima)
//     psVar.psDowntimeAgr.setValue(.acum, acumDownAgr)
//     psVar.psDowntimeInd.setValue(.acum, acumDownInd)
//     psVar.psDowntimeOutros.setValue(.acum, acumDownOutros)

    let acumTaxaMoagem = acumDiasSafra === 0 || acumUptimeGeral === 0 ? 0 : acumMoagemTotal / (acumDiasSafra * acumUptimeGeral/100 * 24)
    tempData = setValue(tempData, "psTaxaMoagem", 'acu', acumTaxaMoagem)
    tempData = setValue(tempData, "psTaxaAtr", 'acu', acumTaxaMoagem * acumAtrPcts/1000)
    tempData = setValue(tempData, "psTaxaArt", 'acu', acumTaxaMoagem * acumArtDigestor/100)
    tempData = setValue(tempData, "psTaxaFibra", 'acu', acumTaxaMoagem * acumFibraCana/100)
    tempData = setValue(tempData, "psMoagemDiaria", 'acu', acumDiasSafra === 0 ? 0 : acumMoagemTotal / acumDiasSafra)
    tempData = setValue(tempData, "psMoagemDiariaEf", 'acu', acumDiasSafra === 0 || acumUptimeGeral === 0 ? 0 : acumMoagemTotal / acumDiasSafra / (acumUptimeGeral/100))

    tempData = setValue(tempData, "psMixAcucar", 'acu', getPSValueSumProd(tempData, 'psMixAcucar', 'psArtEntrado'))
    tempData = setValue(tempData, "psMixEtanol", 'acu', getPSValueSumProd(tempData, 'psMixEtanol', 'psArtEntrado'))

    let acumArtEnt = getPSValueSum(tempData, 'psArtEntrado')
    tempData = setValue(tempData, "psArtEntrado", 'acu', acumArtEnt)
    tempData = setValue(tempData, "psPerdaLavavem", 'acu', getPSValueSumProd(tempData, 'psPerdaLavavem', 'psArtEntrado'))
    tempData = setValue(tempData, "psPerdaBagaco", 'acu', getPSValueSumProd(tempData, 'psPerdaBagaco', 'psArtEntrado'))
    tempData = setValue(tempData, "psPerdaTorta", 'acu', getPSValueSumProd(tempData, 'psPerdaTorta', 'psArtEntrado'))
    
    tempData = setValue(tempData, "psPerdaMultijato", 'acu', getPSValueSumProd(tempData, 'psPerdaMultijato', 'psArtEntrado'))
    tempData = setValue(tempData, "psPerdaFermentacao", 'acu', getPSValueSumProd(tempData, 'psPerdaFermentacao', 'psArtEntrado'))
    tempData = setValue(tempData, "psPerdaResiduaria", 'acu', getPSValueSumProd(tempData, 'psPerdaResiduaria', 'psArtEntrado'))
    tempData = setValue(tempData, "psPerdaIndeterminada", 'acu', getPSValueSumProd(tempData, 'psPerdaIndeterminada', 'psArtEntrado'))
    tempData = setValue(tempData, "psEficienciaIndustrial", 'acu', getPSValueSumProd(tempData, 'psEficienciaIndustrial', 'psArtEntrado'))
    tempData = setValue(tempData, "psArtRecuperado", 'acu', getPSValueSum(tempData, 'psArtRecuperado'))

    // tempData = setValue(tempData, "psRTC", 'acu', getPSValueSumProd(tempData, 'psRTC', 'psArtEntrado'))
    tempData = setValue(tempData, "psRitStab", 'acu', getPSValueSumProd(tempData, 'psRitStab', 'psArtEntrado'))
    tempData = setValue(tempData, "psRitArt", 'acu', getPSValueSumProd(tempData, 'psRitArt', 'psArtEntrado'))
    tempData = setValue(tempData, "psRecSJM", 'acu', getPSValueSumProd(tempData, 'psRecSJM', 'psMoagemTotal'))

    let acumProdAcucarTotal = getPSValueSum(tempData, 'psProdAcucarTotal')
    let acumProdAccucarVHP = getPSValueSum(tempData, 'psProdAcucarVHP')
    let acumProdAcucarCristal = getPSValueSum(tempData, 'psProdAcucarCristal')
    let acumProdAcucarTotalSC = getPSValueSum(tempData, 'psProdAcucarTotalSC')
    let acumProdAccucarVHPSC = getPSValueSum(tempData, 'psProdAcucarVHPSC')
    let acumProdAcucarCristalSC = getPSValueSum(tempData, 'psProdAcucarCristalSC')
    
    tempData = setValue(tempData, "psProdAcucarTotal", 'acu', acumProdAcucarTotal)
    tempData = setValue(tempData, "psProdAcucarVHP", 'acu', acumProdAccucarVHP)
    tempData = setValue(tempData, "psProdAcucarCristal", 'acu', acumProdAcucarCristal)
    tempData = setValue(tempData, "psProdAcucarTotalSC", 'acu', acumProdAcucarTotalSC)
    tempData = setValue(tempData, "psProdAcucarVHPSC", 'acu', acumProdAccucarVHPSC)
    tempData = setValue(tempData, "psProdAcucarCristalSC", 'acu', acumProdAcucarCristalSC)

    let psMixProdAcucarVHP = acumProdAcucarTotal == 0 ? 0 : acumProdAccucarVHP*100/acumProdAcucarTotal
    tempData = setValue(tempData, "psMixProdAcucarVHP", 'acu', psMixProdAcucarVHP)

    let acumTaxaEfProdAcucar = acumProdAcucarTotal / acumDiasSafra / (acumUptimeGeral/100)
    let acumTaxaEfProdAcucarScs = acumProdAcucarTotal*20 / acumDiasSafra / (acumUptimeGeral/100)
    tempData = setValue(tempData, "psTaxaEfProdAcucar", 'acu', acumTaxaEfProdAcucar)
    tempData = setValue(tempData, "psTaxaEfProdAcucarScs", 'acu', acumTaxaEfProdAcucarScs)

    tempData = setValue(tempData, "psRelProdAcArtEnt", 'acu', acumArtEnt == 0 ? 0 : acumProdAcucarTotal/acumArtEnt)
    tempData = setValue(tempData, "psRelProdAcAtrEnt", 'acu', acumAtrPcts == 0 || acumMoagemTotal == 0 ? 0 : acumProdAcucarTotal*1000/(acumMoagemTotal * acumAtrPcts))

    let acumProdTotal100 = getPSValueSum(tempData, 'psProdTotalEtanol100')
    let acumProdETOH = getPSValueSum(tempData, 'psProdEtanolTotalHid')
    let acumProdTotalETOA = getPSValueSum(tempData, 'psProdEtanolAnidro')
    let acumProdTotalETON = getPSValueSum(tempData, 'psProdEtanolNeutro')
    // let acumRepETOH = getPSValueSum(tempData, 'psRepEtanolHid')
    let acumProdEtanolTotal = getPSValueSum(tempData, 'psProdEtanolTotal')

    tempData = setValue(tempData, "psProdTotalEtanol100", 'acu', acumProdTotal100)
    tempData = setValue(tempData, "psProdEtanolTotalHid", 'acu', acumProdETOH)
    tempData = setValue(tempData, "psProdEtanolAnidro", 'acu', acumProdTotalETOA)
    tempData = setValue(tempData, "psProdEtanolNeutro", 'acu', acumProdTotalETON)
    // tempData = setValue(tempData, "psRepEtanolHid", 'acu', acumRepETOH)
    tempData = setValue(tempData, "psProdEtanolTotal", 'acu', acumProdEtanolTotal)

    let acumTaxaEfProdETOH =  getPSValueSumProd(tempData, 'psProdEfEtanolHid', 'psUptimeGeral')
    let acumTaxaEfProdETOA =  acumProdTotalETOA / acumDiasSafra / (acumUptimeGeral/100)
    let acumTaxaEfProdETON =  acumProdTotalETON / acumDiasSafra / (acumUptimeGeral/100)
    let acumTaxaEfEtanol100 =  acumProdTotal100 / acumDiasSafra / (acumUptimeGeral/100)
    let psProdEfEtanolHidpTq =  acumProdETOH / acumDiasSafra / (acumUptimeGeral/100)


    tempData = setValue(tempData, "psProdEfEtanolHid", 'acu', acumTaxaEfProdETOH)
    tempData = setValue(tempData, "psProdEfEtanolAnidro", 'acu', acumTaxaEfProdETOA)
    tempData = setValue(tempData, "psProdEfEtanolNeutro", 'acu', acumTaxaEfProdETON)
    tempData = setValue(tempData, "psProdEfEtanol100", 'acu', acumTaxaEfEtanol100)
    tempData = setValue(tempData, "psProdEfEtanolHidpTq", 'acu', psProdEfEtanolHidpTq)

    tempData = setValue(tempData, "psRGD", 'acu', getPSValueSumProd(tempData, 'psRGD', 'psProdEfEtanol100'))

    
//     let psRepEfEtanolHid =  acumRepETOH / acumDiasSafra / (acumUptimeGeral/100)
//     psVar.psRepEfEtanolHid.setValue(.acum, psRepEfEtanolHid)

//     //ENERGIA
    
    let acumEnergiaExportada = getPSValueSum(tempData, 'psEnergiaExportada')
    let acumEnergiaConsumida = getPSValueSum(tempData, 'psEnergiaCosumida')
    let ptExportadaTC = acumMoagemTotal === 0 ? 0 : acumEnergiaExportada * 1000 / acumMoagemTotal
    let ptConsumidaTC = acumMoagemTotal === 0 ? 0 : acumEnergiaConsumida * 1000 / acumMoagemTotal
    let ptExportada = acumDiasSafra === 0 || acumUptimeGeral === 0 ? 0 : acumEnergiaExportada / acumDiasSafra / 24 / (acumUptimeGeral/100)
    let ptConsumida = acumDiasSafra === 0 || acumUptimeGeral === 0 ? 0 : acumEnergiaConsumida / acumDiasSafra / 24 / (acumUptimeGeral/100)

    tempData = setValue(tempData, "psEnergiaExportada", 'acu', acumEnergiaExportada)
    tempData = setValue(tempData, "psEnergiaCosumida", 'acu', acumEnergiaConsumida)
    tempData = setValue(tempData, "psPtExportadaTC", 'acu', ptExportadaTC)
    tempData = setValue(tempData, "psPtConsumidaTC", 'acu', ptConsumidaTC)
    tempData = setValue(tempData, "psPtExportada", 'acu', ptExportada)
    tempData = setValue(tempData, "psPtConsumida", 'acu', ptConsumida)

        //DNE
    
    let acumUptimeVapor = getPSValueSumProd(tempData, 'psUpTimeVapor', 'psDiasSafra')
    let acumprodTotalVaporDNE = getPSValueSum(tempData, 'dneProdTotalVapor')
    let upTimeDNE = acumUptimeVapor - acumUptimeGeral
    let acumprodEfVaporDNE = getPSValueSumProd(tempData, 'dneProdVapor', 'dneProdTotalVapor')
    let acumRendCaldeiraDNE = getPSValueSumProd(tempData, 'dneRendCaldeira', 'dneProdVapor')
    let acumConsTotalBagacoDNE = acumprodTotalVaporDNE/acumRendCaldeiraDNE
    let acumConsEfBagacoDNE = getPSValueSumProd(tempData, 'dneConsBagaco', 'dneConsTotalBagaco')
    let energiaExportadaDNE = getPSValueSum(tempData, 'dneEnergiaExportada')
    let ptExportdaDNE = energiaExportadaDNE / 24 / ((acumUptimeVapor - acumUptimeGeral)*acumDiasSafra/100)

    tempData = setValue(tempData, "dneEnergiaExportada", 'acu', energiaExportadaDNE)
    tempData = setValue(tempData, "dnePtExportada", 'acu', ptExportdaDNE)
    tempData = setValue(tempData, "dneProdVapor", 'acu', acumprodEfVaporDNE)
    tempData = setValue(tempData, "dneProdTotalVapor", 'acu', acumprodTotalVaporDNE)
    tempData = setValue(tempData, "dneRendCaldeira", 'acu', acumRendCaldeiraDNE)
    tempData = setValue(tempData, "dneConsBagaco", 'acu', acumConsEfBagacoDNE)
    tempData = setValue(tempData, "dneConsTotalBagaco", 'acu', acumConsTotalBagacoDNE)

//VAPOR
    let prodVaporTotal = getPSValueSum(tempData, 'psProdVapor')
    let prodEfVapor = acumDiasSafra === 0 || acumUptimeVapor === 0 ? 0 : prodVaporTotal / acumDiasSafra / (acumUptimeVapor/100) / 24
    let consEspEfTC = acumMoagemTotal === 0 ? 0 : prodVaporTotal*1000/acumMoagemTotal
    let consEspTotalTC =  acumMoagemTotal === 0 ? 0 : (prodVaporTotal + acumprodTotalVaporDNE)*1000/acumMoagemTotal

    tempData = setValue(tempData, "psProdVapor", 'acu', prodVaporTotal)
    tempData = setValue(tempData, "psProdEfVapor", 'acu', prodEfVapor)
    tempData = setValue(tempData, "psConsVaporTc", 'acu', consEspEfTC)
    tempData = setValue(tempData, "psConsVaporTcTotal", 'acu', consEspTotalTC)
    tempData = setValue(tempData, "psUpTimeVapor", 'acu', getPSValueSumProd(tempData, 'psUpTimeVapor', 'psDiasSafra'))
    
    //BIOMASSA
    let psProdBagacao = getPSValueSum(tempData, 'psProdBagacao')
    let psConsBagacao = getPSValueSum(tempData, 'psConsBagacao')
    tempData = setValue(tempData, "psProdBagacao", 'acu', psProdBagacao)
    tempData = setValue(tempData, "psConsBagacao", 'acu', psConsBagacao)

    tempData = setValue(tempData, "psProdEfBagacao", 'acu', acumDiasSafra === 0 || acumUptimeGeral === 0 ? 0 : psProdBagacao / acumDiasSafra / (acumUptimeGeral/100) / 24)
    tempData = setValue(tempData, "psConsEfBagacao", 'acu', acumDiasSafra === 0 || acumUptimeVapor === 0 ? 0 : psConsBagacao / acumDiasSafra / (acumUptimeVapor/100) / 24)

    tempData = setValue(tempData, "psSobraBagaco", 'acu', getPSValueSum(tempData, 'psSobraBagaco'))
    tempData = setValue(tempData, "psVendaBagaco", 'acu', getPSValueSum(tempData, 'psVendaBagaco'))
    tempData = setValue(tempData, "psAjusteEstoqueBag", 'acu', getPSValueSum(tempData, 'psAjusteEstoqueBag'))

    let psEstoqueInicialBag = getValue('psEstoqueInicialBag', 'jan', tempData) + getValue('psAjusteEstoqueBag', 'jan', tempData)
    tempData = setValue(tempData, "psEstoqueInicialBag", 'acu', psEstoqueInicialBag)
    tempData = setValue(tempData, "psEstoqueFinalBag", 'acu', getValue('psEstoqueFinalBag', 'dez', tempData))

        //OUTROS
    // tempData = setValue(tempData, "psProdVinhacapEtanol", 'acu', getPSValueSumProd(tempData, 'psProdVinhacapEtanol', 'psProdEtanolTotal'))
    // tempData = setValue(tempData, "psProdTortaTc", 'acu', getPSValueSumProd(tempData, 'psProdTortaTc', 'psMoagemTotal'))
    
    return tempData
    
    

    
//     let acumUnieqs = getPSValueSum(.psProdUnieqs)
//     psVar.psProdUnieqs.setValue(.acum, acumUnieqs)
    
//     psVar.psProdUnieqsTc.setValue(.acum, acumMoagemTotal == 0 ? 0 : acumUnieqs/acumMoagemTotal)
    
//     let acumUnicops = getPSValueSum(.psProdUnicops)
//     psVar.psProdUnicops.setValue(.acum, acumUnicops)
    
//     //LEVEDURA
//     let acumProdLev = getPSValueSum(.psProdLevedura)
//     psVar.psProdLevedura.setValue(.acum, acumProdLev)
//     let psProdEfLev =  acumProdLev / acumDiasSafra / (acumUptimeGeral/100)
//     psVar.psEfProdLevedura.setValue(.acum, psProdEfLev)
//     psVar.pslevSecaPorLitrosEt.setValue(.acum, acumProdLev*1000/acumProdETOH)
    
    

    

    

    

    
//     print("Acumulado Safra: \(acumprodTotalVaporDNE), \(acumDiasSafra), \(upTimeDNE/100)")
}

function getValue(codigo, month, data){
    let value = 0 
    let index = data.findIndex(obj => obj.codigo === codigo);
    if (index !== -1) {
        let item = data[index];
        if (month in item) {
            value = item[month]
        }
    }
    return value
}

function setValue(data, codigo, campo, novoValor) {
    let index = data.findIndex(obj => obj.codigo === codigo);
    if (index !== -1) {
        let item = data[index];
        if (campo in item) {
            item[campo] = novoValor;
            data[index] = item;  
        } 
    }
    return data;
}

function getLastMonth(month){
    let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
    let index = months.indexOf(month);
    return months[index-1]
}

function getRealItem(psReal, cod, mes){
    let item = psReal.filter(item => item.codigo === cod)[0][mes]
    // let item = psDataReal[psData[0].safra]['13_Realizado'][cod][0][mes]
    return item
  }

//   function getRealItem(psDataReal, psData, cod, mes){
//     psReal.filter(item => item.codigo === cod)[0][mes]
//     let item = psDataReal[psData[0].safra]['13_Realizado'][cod][0][mes]
//     return item
//   }


function checkReal(psData, mes){
    let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
    let revisao = psData[0].revisao
    if (revisao === '13_Realizado'){
      return true
    }
    let month = revisao.slice(-3).toLowerCase();
    let index = months.indexOf(month);
    let index1 = months.indexOf(mes);
    if (index1 < index){
      return true
    }else{
      return false
    }
  }

function setZeros(psData, month){
    let tempData = psData
    tempData = setValue(tempData, "psAtrPcts", month, 0.0)

    tempData = setValue(tempData, "psAtrDigestor", month, 0.0)
    tempData = setValue(tempData, "psFibraCana", month, 0.0)
    tempData = setValue(tempData, "psFibraBagaco", month, 0.0)
    tempData = setValue(tempData, "psBagacoCana", month, 0.0)
    tempData = setValue(tempData, "psTaxaMoagem", month, 0.0)
    tempData = setValue(tempData, "psTaxaArt", month, 0.0)
    tempData = setValue(tempData, "psTaxaAtr", month, 0.0)
    tempData = setValue(tempData, "psTaxaFibra", month, 0.0)

    tempData = setValue(tempData, "psUptimeGeral", month, 0.0)
    tempData = setValue(tempData, "psDiasSafra", month, 0.0)

    tempData = setValue(tempData, "psMoagemTotal", month, 0.0)
    tempData = setValue(tempData, "psMoagemDiaria", month, 0.0)
    tempData = setValue(tempData, "psMoagemDiariaEf", month, 0.0)
    tempData = setValue(tempData, "psMixAcucar", month, 0.0)
    tempData = setValue(tempData, "psMixEtanol", month, 0.0)
    tempData = setValue(tempData, "psArtEntrado", month, 0.0)
    tempData = setValue(tempData, "psArtRecuperado", month, 0.0)
    tempData = setValue(tempData, "psPerdaLavavem", month, 0.0)
   
    tempData = setValue(tempData, "psPerdaBagaco", month, 0.0)
    tempData = setValue(tempData, "psPerdaTorta", month, 0.0)
    tempData = setValue(tempData, "psPerdaMultijato", month, 0.0)
    tempData = setValue(tempData, "psPerdaFermentacao", month, 0.0)
    tempData = setValue(tempData, "psPerdaResiduaria", month, 0.0)
    tempData = setValue(tempData, "psPerdaIndeterminada", month, 0.0)

    tempData = setValue(tempData, "psEficienciaIndustrial", month, 0.0)
    tempData = setValue(tempData, "psRTC", month, 0.0)
    tempData = setValue(tempData, "psRitStab", month, 0.0)
    tempData = setValue(tempData, "psRitArt", month, 0.0)
    tempData = setValue(tempData, "psRelProdAcArtEnt", month, 0.0)
    tempData = setValue(tempData, "psRelProdAcAtrEnt", month, 0.0)
    tempData = setValue(tempData, "psRecSJM", month, 0.0)

    tempData = setValue(tempData, "psProdUnieqs", month, 0.0)
    tempData = setValue(tempData, "psProdUnicops", month, 0.0)
    tempData = setValue(tempData, "psProdAcucarTotal", month, 0.0)
    tempData = setValue(tempData, "psProdAcucarTotalSC", month, 0.0)
    tempData = setValue(tempData, "psProdAcucarVHP", month, 0.0)
    tempData = setValue(tempData, "psProdAcucarVHPSC", month, 0.0)
    tempData = setValue(tempData, "psProdAcucarCristal", month, 0.0)

    tempData = setValue(tempData, "psProdAcucarCristalSC", month, 0.0)
    tempData = setValue(tempData, "psTaxaEfProdAcucar", month, 0.0)
    tempData = setValue(tempData, "psTaxaEfProdAcucarScs", month, 0.0)
    tempData = setValue(tempData, "psProdEfEtanolHid", month, 0.0)
    tempData = setValue(tempData, "psProdEfEtanolAnidro", month, 0.0)
    tempData = setValue(tempData, "psProdEfEtanolNeutro", month, 0.0)
    tempData = setValue(tempData, "psProdEfEtanol100", month, 0.0)
    tempData = setValue(tempData, "psProdEfEtanolHidpTq", month, 0.0)
    tempData = setValue(tempData, "psRepEfEtanolHid", month, 0.0)
    tempData = setValue(tempData, "psProdTotalEtanol100", month, 0.0)
    tempData = setValue(tempData, "psProdEtanolTotalHid", month, 0.0)
   
    tempData = setValue(tempData, "psProdEtanolAnidro", month, 0.0)
    tempData = setValue(tempData, "psProdEtanolNeutro", month, 0.0)
    tempData = setValue(tempData, "psRepEtanolHid", month, 0.0)
    tempData = setValue(tempData, "psProdEtanolTotal", month, 0.0)
    tempData = setValue(tempData, "psRGD", month, 0.0)
    tempData = setValue(tempData, "psPtExportada", month, 0.0)
    tempData = setValue(tempData, "psPtConsumida", month, 0.0)
    tempData = setValue(tempData, "psEnergiaCosumida", month, 0.0)
    tempData = setValue(tempData, "psPtExportadaTC", month, 0.0)

    tempData = setValue(tempData, "psPtConsumidaTC", month, 0.0)
    tempData = setValue(tempData, "psProdLevedura", month, 0.0)
    tempData = setValue(tempData, "psEfProdLevedura", month, 0.0)
    tempData = setValue(tempData, "psProdEfVapor", month, 0.0)
    tempData = setValue(tempData, "psConsVaporTc", month, 0.0)
    tempData = setValue(tempData, "psConsVaporTcTotal", month, 0.0)
    tempData = setValue(tempData, "psProdBagacao", month, 0.0)
    tempData = setValue(tempData, "psProdEfBagacao", month, 0.0)
    tempData = setValue(tempData, "psConsEfBagacao", month, 0.0)
    tempData = setValue(tempData, "psProdVinhacapEtanol", month, 0.0)
    tempData = setValue(tempData, "psProdTortaTc", month, 0.0)
   

    //DNE
    let upTimeVapor = getValue("psUpTimeVapor", month, tempData)
    let diasSafra = getValue("psDiasSafra", month, tempData)
    let rendCaldeira = getValue("dneRendCaldeira", month, tempData)
    let prodEfVapor = getValue("dneProdVapor", month, tempData)
    let potExportada = getValue("dnePtExportada", month, tempData)
    let prodVapor = diasSafra * upTimeVapor/100 * 24 * prodEfVapor
    var consBag = prodVapor/rendCaldeira
    var consEfBag = prodEfVapor/rendCaldeira
    let energiaExp = diasSafra * upTimeVapor/100 * 24 * potExportada

    tempData = setValue(tempData, "dneProdTotalVapor", month, prodVapor)
    if (rendCaldeira === 0){
        consBag = 0.0
        consEfBag = 0.0
    }
    tempData = setValue(tempData, "dneConsTotalBagaco", month, consBag)
    tempData = setValue(tempData, "dneConsBagaco", month, consEfBag)
    tempData = setValue(tempData, "dneEnergiaExportada", month, energiaExp)

    tempData = setValue(tempData, "psEnergiaExportada", month, energiaExp)
    tempData = setValue(tempData, "psProdVapor", month, prodVapor)
    tempData = setValue(tempData, "psConsBagacao", month, consBag)
    tempData = setValue(tempData, "psSobraBagaco", month, -consBag)

    var estoqueFinalLasMonth = 0.0
    if (month !== 'jan'){
        estoqueFinalLasMonth = getValue("psEstoqueFinalBag", getLastMonth(month), tempData)
    }
    let vendaTotalBagaco = getValue("psVendaBagaco", month, tempData) 
    let ajusteEstBag = getValue("psAjusteEstoqueBag", month, tempData)
    let estoqueInicioBagaco = estoqueFinalLasMonth //+ ajusteEstBag
    let estoqueFinalbagaco = estoqueInicioBagaco + (-consBag) + ajusteEstBag - vendaTotalBagaco

    tempData = setValue(tempData, "psEstoqueFinalBag", month, estoqueFinalbagaco)
    tempData = setValue(tempData, "psEstoqueInicialBag", month, estoqueInicioBagaco)

    tempData = setValue(tempData, "psAlerts", month, 1.0)
    global.psSaved = false

    return tempData
}

// async function abrirPs(safra, revisao){
//     console.log('abrirPs realziado'+ safra + '  '+revisao)
//     try {
//         const response = await fetch(`${global.endPoint}/getps?safra=${safra}&revisao=${revisao}`, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });
      
//         if (!response.ok) {
            
//           throw new Error('Erro de requisição.');
          
//         }
//         console.log('not ok')
        
//         let impData = await response.json();

//         // let stdPS = stdPlanoVars
//         // let tempData = []

//         // for (const item of stdPS){
//         //     let newItem = impData.filter(row => row.codigo === item.cod)
//         //     if (newItem.length > 0){
//         //         tempData.push(newItem[0])
//         //     }
//         // }
//         return impData
        
//       } catch (error) {
//         console.error('Erro na requisição:', error);
//       }

//   }

async function abrirPs(safra, revisao) {
    // console.log('abrirPs chamado com safra:', safra, 'e revisao:', revisao);
    
    const url = `${global.endPoint}/getps?safra=${safra}&revisao=${revisao}`;
    // console.log('URL da requisição:', url);
    
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        console.log('Resposta não OK, status:', response.status);
        throw new Error('Erro de requisição.');
      }
  
      let impData = await response.json();
    //   console.log('Dados recebidos:', impData);
  
      // Descomente e ajuste conforme necessário:
      // let stdPS = stdPlanoVars;
      // let tempData = [];
  
      // for (const item of stdPS) {
      //   let newItem = impData.filter(row => row.codigo === item.cod);
      //   if (newItem.length > 0) {
      //     tempData.push(newItem[0]);
      //   }
      // }
      
      return impData;
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  }
  
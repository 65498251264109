import React, { useContext, useState, useRef, useEffect} from 'react'

import '../css/planoSafra.css';
import GlobalStateContext from '../components/GlobalStateContext'
import PSContext from './opoio/PSContext';
import MenuPlanoSafra from './menuPlanoSafra';
import { COLORS } from '../components/ui/colors';
import { stdPlanoVars } from './stdPlanoSafra'
import SubMenuNew from './subMenus/subMenuNew';
import SubMenuEdit from './subMenus/subMenuEdit';
import SubMenuCopy from './subMenus/subMenuCopy';
import SubMenuFiles from './subMenus/subMenuFiles';
import PsInput from './opoio/psInput';
import { stdValue } from '../Resources/setNumber';
import { checkReal } from './opoio/calcPS'
import { listaDNECods, inputCods} from '../Resources/Constantes'
import { calcPS } from './opoio/calcPS'

function PlanoSafra() {
  const {isNew, isEdit, isSaveAs, isOpen, psData, setPsData, psDataReal, editingMonth} = useContext(PSContext)
  const {data} = useContext(GlobalStateContext)
  const newItemRef = useRef(null);
  const [groupData, setGroupData] = useState(null)
  const [headData, setHeadData] = useState(getHeadData())

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', COLORS.primaryColor);
  }, []);

  // useEffect(async() => {
  //   if (!isOpen){
  //     console.log('IsOPen')
  //     let temp = await calcPS(psData, psDataReal)
  //     setPsData(temp)
  //   }
    
  // }, [isOpen]);

  useEffect(() => {
    console.log('Update Plano Safra Screen')
    if (psData){
      const groupedItems = psData.reduce((acc, item) => {
        if (stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.show !== false){
          
          // if (sections.includes('all') || sections.includes(item.secao)){
            if (!acc[item.secao]) {
              acc[item.secao] = [];
          }
            acc[item.secao].push(item);
          // }
          
        }
        return acc;
    }, {});
    console.log('Grouped')
    console.log(groupedItems)
    setGroupData(groupedItems)
    setHeadData(getHeadData())
    console.log(groupedItems)
    // console.log(psData)
    } 
    // global.psSaved = false
    
  }, [psData, editingMonth]);

  let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
  


  function getHeadData(){
    let psAlerts = psData?.filter(item => item.codigo === 'psAlerts')

    if (psAlerts && psData){
      
      let alerts = [
        {status: checkReal(psData, 'jan') ? 'Real' : 'Plano', mes: 'Jan', alert: psAlerts[0]?.jan === 1 ? false : true},
        {status: checkReal(psData, 'fev') ? 'Real' : 'Plano', mes: 'Fev', alert: psAlerts[0]?.fev === 1 ? false : true},
        {status: checkReal(psData, 'mar') ? 'Real' : 'Plano', mes: 'Mar', alert: psAlerts[0]?.mar === 1 ? false : true},
        {status: checkReal(psData, 'abr') ? 'Real' : 'Plano', mes: 'Abr', alert: psAlerts[0]?.abr === 1 ? false : true},
        {status: checkReal(psData, 'mai') ? 'Real' : 'Plano', mes: 'Mai', alert: psAlerts[0]?.mai === 1 ? false : true},
        {status: checkReal(psData, 'jun') ? 'Real' : 'Plano', mes: 'Jun', alert: psAlerts[0]?.jun === 1 ? false : true},
        {status: checkReal(psData, 'jul') ? 'Real' : 'Plano', mes: 'Jul', alert: psAlerts[0]?.jul === 1 ? false : true},
        {status: checkReal(psData, 'ago') ? 'Real' : 'Plano', mes: 'Ago', alert: psAlerts[0]?.ago === 1 ? false : true},
        {status: checkReal(psData, 'set') ? 'Real' : 'Plano', mes: 'Set', alert: psAlerts[0]?.set === 1 ? false : true},
        {status: checkReal(psData, 'out') ? 'Real' : 'Plano', mes: 'Out', alert: psAlerts[0]?.out === 1 ? false : true},
        {status: checkReal(psData, 'nov') ? 'Real' : 'Plano', mes: 'Nov', alert: psAlerts[0]?.nov === 1 ? false : true},
        {status: checkReal(psData, 'dez') ? 'Real' : 'Plano', mes: 'Dez', alert: psAlerts[0]?.dez === 1 ? false : true}
      ]
      return alerts
    }else{
      let blkAlerts = [
        {status: 'Plano', mes: 'Jan', alert: true},
        {status: 'Plano', mes: 'Fev', alert: true},
        {status: 'Plano', mes: 'Mar', alert: true},
        {status: 'Plano', mes: 'Abr', alert: true},
        {status: 'Plano', mes: 'Mai', alert: true},
        {status: 'Plano', mes: 'Jun', alert: true},
        {status: 'Plano', mes: 'Jul', alert: true},
        {status: 'Plano', mes: 'Ago', alert: true},
        {status: 'Plano', mes: 'Set', alert: true},
        {status: 'Plano', mes: 'Out', alert: true},
        {status: 'Plano', mes: 'Nov', alert: true},
        {status: 'Plano', mes: 'Dez', alert: true}
      ]
      return blkAlerts
    } 
  } 

  function getRealItem(cod){
    let item = psDataReal[psData[0].safra]['13_Realizado'][cod][0]
    return item
  }

  return (
    <>
    <div>
        <MenuPlanoSafra/>
        {isNew && <SubMenuNew/>}
        {isEdit && <SubMenuEdit/>}
        {isSaveAs && <SubMenuCopy/>}
        {isOpen && <SubMenuFiles/>}
        {groupData && !isOpen && <div className='psTableHead'>
            <div style={{width: '15%', textAlign: 'left'}}>Descrição</div>
            <div style={{width: '5%', textAlign: 'center'}}>Un.</div>
            {headData.map((item, index) => {
                return(
                    <div className='psHeadElement' style={{width: '5%'}} key={item.mes}>
                        <div>{item.status}</div>
                        <div>{item.mes}</div>
                        {item.alert === true && 
                        <div className='psAlert'>
                            !
                            </div>}
                    </div>
                )
            })}
            <div style={{width: '10%', textAlign: 'center'}}>Acumulado</div>
        </div>}
        </div>
        <div className='psBody'>
        {groupData && !isOpen && Object.keys(groupData).map(group => {
          return(
            <div key={group}>
              {group !== 'settings' && <div className='psSectionHead'>{group}</div>}
              {groupData[group].map((item, index) => {
                if (item.codigo === 'psAlerts' || stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.show === false ){
                 
                  return(<></>)
                }

                if (isEdit && psData[0].revisao === '13_Realizado'){
                  return(
                    <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
                    <div style={{width: '15%', textAlign: 'left'}}>{item.descricao}</div>
                    <div style={{width: '5%', textAlign: 'left'}}>{item.unidade}</div>

                    {months.map(mes => {
                        return (
                          <div style={{width: '5%', textAlign: 'left'}}>{
                            setMonth(editingMonth) === mes && 
                            inputCods.includes(item.codigo) ?
                            <PsInput cod={item.codigo} mes={mes} valor={stdValue(item[mes])}/> :
                            listaDNECods.includes(item.codigo) ?
                            null :
                            stdValue(item[mes])
                          }</div>
                        )
                    })}
                  
                    <div style={{width: '10%'}}>{stdValue(item.acu)}</div>
                  </div>
                  )
                }

                if (!isEdit && psData[0].revisao === '13_Realizado'){
                  return(
                    <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
                    <div style={{width: '15%', textAlign: 'left'}}>{item.descricao}</div>
                    <div style={{width: '5%', textAlign: 'left'}}>{item.unidade}</div>
                    {months.map(mes => {
                       return(<div style={{width: '5%', textAlign: 'center'}}>{listaDNECods.includes(item.codigo) ? null : stdValue(item[mes])}</div>)
                    })}
                    <div style={{width: '10%'}}>{stdValue(item.acu)}</div>
                  </div>
                  )
                }

                if (isEdit){
                  return(
                    <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
                    <div style={{width: '15%', textAlign: 'left'}}>{item.descricao}</div>
                    <div style={{width: '5%', textAlign: 'left'}}>{item.unidade}</div>

                    {months.map(mes => {
                        return <div style={{width: '5%', textAlign: 'left'}}>{isIpt(psData, mes, item.input)  ?
                          <PsInput cod={item.codigo} mes={mes} valor={stdValue(item[mes])}/> : 
                          stdValue(item[mes])
                          }</div>
                    })}
                  
                    <div style={{width: '10%'}}>{stdValue(item.acu)}</div>
                  </div>
                  )
                }
                
                  return(
                    <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
                    <div style={{width: '15%', textAlign: 'left'}}>{item.descricao}</div>
                    <div style={{width: '5%', textAlign: 'left'}}>{item.unidade}</div>

                    {months.map(mes => {
                        return  <div style={{width: '5%'}} key={mes}>{stdValue(item[mes])}</div>
                    })}
                    <div style={{width: '10%'}}>{stdValue(item.acu)}</div>
                  </div>
                  )
              })}
            
            </div>
          )
        })}
        </div>
    
    </>
  )
}

export default PlanoSafra

function isIpt(psData, mes, ipt){
  let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
  let revisao = psData[0].revisao
  if (revisao === '13_Realizado'){
    return true
  }
  let month = revisao.slice(-3).toLowerCase();
  let index = months.indexOf(month);
  let index1 = months.indexOf(mes);
  if (index1 < index){
    return false
  }

  if (ipt){
    return true
  }
  
}

function setMonth(fullMonth){
  let month = fullMonth.substring(0, 3).toLowerCase()
  return month
}


import React, { useState, useContext, useEffect, useRef } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import SwitchControl from "../../components/ui/Switch";


function SwitchLine({cod, handleCountChange}) {
  const { setAlert, data, user } = useContext(GlobalStateContext);
 
  const [selectedValue1, setSelectedValue1] = useState(parseInt(data[cod].valor));

  

  useEffect(() => {
    setSelectedValue1(`${parseInt(data[cod]?.valor)}`) 
  }, [ cod, data]);


  const updateValue = async (val) => {
      setSelectedValue1(val)
      console.log(''+val + ': '+cod)
      await fetch(''+global.endPoint+'/'+user?.uid+'/setValue', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({codigo: cod, newValue: val})
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro de requisição.');
      }else{
        console.log('updated: '+val)
        data[cod].valor = val
        setAlert(true)
        handleCountChange(cod)
      }
    })
    .catch(error => {
      // Lida com o erro da requisição
    })
  }

  return (
    <SwitchControl
      descricao={data[cod].descricao}
        name="group-1"
        callback={(val) => updateValue(val)}
        controlRef={useRef()}
        defaultIndex={selectedValue1}
        segments={[
            {
              label: "0",
              value: 0,
              ref: useRef()
            },
            {
              label: "1",
              value: 1,
              ref: useRef()
            }
          ]}
      />
  )
}

export default SwitchLine


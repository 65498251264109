import Flow from '../Flow'
import EvapFlx from '../../../assets/fluxogramas/evaporacaoWeb.png'

function EvaporacaoFlx() {
  const vh = window.innerHeight
const ideal = 727
let nVH = ideal/vh

  return (
    <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
    <div style={{ position: 'absolute', width: `${115*nVH}vh`, height: `${100*nVH}vh`, top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}}>
      <img style={{position: 'absolute', height: `${90*nVH}vh`, width: `${135*nVH}vh`, left: '0vh', top: '0vh'}} src={EvapFlx} alt="" />
    {evaporacao.flows.map((item, index) => {
        return (
          <Flow flow={item.flow} x={item.x * nVH} y={item.y * nVH} align={item.align} valign={item.valign} cods={item.props} key={index} f={nVH}/>
        )
        })}
    </div>
  </div>
  )
}

export default EvaporacaoFlx

const evaporacao = {
    imagem: EvapFlx,
    flows: [
      { 
        flow: 'Sangria VV1',
        props: ['sangriaVV1'],
        x: 40.5 , y: 6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Sangria VV2',
        props: ['sangriaVV2'],
        x: 60.8 , y: 6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Sangria VV3',
        props: ['sangriaVV3'],
        x: 80 , y: 6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowVV1'],
        x: 31.5 , y: 23, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowVV2'],
        x: 51.5 , y: 21, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowVV3'],
        x: 71.5 , y: 21, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowVV4'],
        x: 90.5 , y: 21, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowVV5'],
        x: 109.5 , y: 21, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowCaldo1EfAc', 'brixCaldo1EfAc'],
        x: 30 , y: 47, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowCaldo2Ef', 'brixCaldo2EfAc'],
        x: 49 , y: 47, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowCaldo3Ef', 'brixCaldo3EfAc'],
        x: 69 , y: 47, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowCaldo4Ef', 'brixCaldo4EfAc'],
        x: 88 , y: 47, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowXarope', 'brixXarope'],
        x: 107 , y: 47, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Xarope p/ Fáb.',
        props: ['flowXaropeToFab','brixXarope'],
        x: 116.5, y: 47, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Xarope p/ Ferm.',
        props: ['flowXaropeToFerm','brixXarope'],
        x: 116.5, y: 57, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Vapor Escape',
        props: ['consVaporVEEvapAc'],
        x: 2.3, y: 35, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Cond. Escape',
        props: ['gerCondVEEvapAc'],
        x: 116.5, y: 75.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'CDVG',
        props: ['gerCondVGEvapAc'],
        x: 116.5, y: 38.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'CDV1',
        props: ['gerCondVG1EvapAc'],
        x: 116.5, y: 66.3, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['taxaEvapPreEvapAcucar', 'areaOpPreEvapAcucar'],
        x: 16.5, y: 19.5, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['taxaEvap2EfEvapAcucar', 'areaOp2EfEvapAcucar'],
        x: 40, y: 19.5, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['taxaEvap3EfEvapAcucar', 'areaOp3EfEvapAcucar'],
        x: 59, y: 19.5, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['taxaEvap4EfEvapAcucar', 'areaOp4EfEvapAcucar'],
        x: 78.5, y: 19.5, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['taxaEvap5EfEvapAcucar', 'areaOp5EfEvapAcucar'],
        x: 98, y: 19.5, align: 'center', valign: 'flex-end'
      },
      { 
        flow: 'Caldo Cl. Açúcar',
        props: ['flowCaldoClarifAcucar','brixCaldoClarifAcucar','polCaldoClarifAcucar','artCaldoClarifAcucar','tempCaldoClarifAqAcucar','artMassaCaldoClarifAcucar'],
        x: 2.3, y: 49.3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowAguaEvapTorreFab'],
        x: 116.5, y: 15.8, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowAguaQuenteEvapTorreFab'],
        x: 116.5, y: 27.4, align: 'right', valign: 'flex-start'
      },
    ]
  }

import React, { useState, useContext } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import { getAlertList } from '../../Funcoes/GetAletrsList'

function InputLine({cod}) {
  
  const [prevV, setPrevV] = useState('')
  const { setAlert, data, setlistAlerts, user } = useContext(GlobalStateContext);
  const [v, setV] = useState(data[cod]?.valor.toFixed(getN()))

  function getN(){
    let valor = data[cod]?.valor
    if (valor == valor.toFixed(0)){// eslint-disable-line eqeqeq
      return 0
    }else if (valor == valor.toFixed(1)){// eslint-disable-line eqeqeq
      return 1
    }else if (valor == valor.toFixed(2)){// eslint-disable-line eqeqeq
      return 2
    }else if (valor < 1){
    return 3
    }else if (valor < 100){
      return 2
    }else{
      return 1
    }
  }

  const handleAlert = (v) =>{
      setAlert(true)
  }
  const handleIpt = (e) => {
    setV(e.target.value)
  }
  const updateValue = async (e) => {
    if (v === '' || !contemNumero(v)){
      setV(prevV)
      
    }else{
      if (v !== prevV){
        setV(e.target.value)
        handleAlert(true)
        console.log(''+e.target.value + ': '+e.target.id)
        await fetch(''+global.endPoint+'/'+user?.uid+'/setValue', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codigo: cod, newValue: parseFloat(v)})
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro de requisição.');
        }else{
          console.log('updated: ')
          data[cod].valor = parseFloat(v)
          
          setlistAlerts(getAlertList(data))
        }
      })
      .catch(error => {
        // Lida com o erro da requisição
      })
      }
    }
    
  }
  const handlePrevV = (e) => {
    setPrevV(e.target.value)
  }

  return (
    <div className={`inputLineCont ${data[cod]?.limiteMax !== null && data[cod]?.valor > data[cod]?.limiteMax ? 'outOfLimit' : ''}`}>
            <div className='line'>
                <div className='desc'>{data[cod]?.descricao}</div>
                {/* <div className='iptCont'> */}
                    <input className='ipt' value={v} id={cod} onChange={handleIpt} onBlur={updateValue} onFocus={handlePrevV} type="text" />
                {/* </div> */}
                <div className='unit'>{data[cod]?.unidade}</div>
            </div>
    </div>
  )
}

export default InputLine

function contemNumero(string) {
  return !isNaN(parseFloat(string)) && isFinite(string);
}
import Flow from './Flow'
import TratAcFlx from '../../assets/fluxogramas/tratFabrica3Web.png'
import TratEtFlx from '../../assets/fluxogramas/tratEtanolWeb.png'
import FiltLodoFlx from '../../assets/fluxogramas/filtFlx.png'
import EvapFlx from '../../assets/fluxogramas/evaporacaoWeb.png'
import FermFlx from '../../assets/fluxogramas/fermentacao4Web.png'
import DestFlx from '../../assets/fluxogramas/destilaria4Web.png'
import FabFlx from '../../assets/fluxogramas/fabrica2M_UseWeb.png'
import bhFlx from '../../assets/fluxogramas/BH7.svg'
import vapFlx from '../../assets/fluxogramas/vapor6Web.png'
import extracaoFlx from '../../assets/fluxogramas/extracaoWeb.png'
import extAcFlx from '../../assets/fluxogramas/acionamentosWeb.png'
import blank from '../../assets/fluxogramas/blank.png'



import GlobalStateContext from '../../components/GlobalStateContext'
import { useContext, useEffect, useState } from 'react';

function Fluxograma() {
  const {area, data, screen} = useContext(GlobalStateContext)
  // const [areaData, setAreaData] = useState(getAreaData())

//  useEffect(() => {
//   setAreaData(getAreaData())
//   console.log('data flx: '+data)
// }, [data, areaData]);

  const getAreaData = () => {
    switch (area){
      case 'tratAcucar': return tratAcucar
      case 'tratEtanol': return tratEtanol
      case 'filtLodo': return filtLodo
      case 'evaporacao': return evaporacao
      case 'fermentacao': return fermentacao
      case 'destilaria': return destilaria(data['opVaporEtNeutro'].valor)
      case 'fabrica': return fabrica
      case 'vapor': return vapor
      case 'balHidrico': return bh
      case 'blank': return Blank
      default: 
        if (screen === 'flxAcionamentos'){
          return extAcion
        }
        return extracao
    }
  }
 
  return (
    <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
      <div style={{ position: 'absolute', width: '115vh', height: '100vh', top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}}>
        <img style={{position: 'absolute', height: '90vh', width: `${area === 'balHidrico' ? '121.8vh' : '135vh'}`, left: '0vh', top: '0vh'}} src={getAreaData().imagem} alt="" />
      {getAreaData().flows.map((item, index) => {
          return (
            <Flow flow={item.flow} x={item.x} y={item.y} align={item.align} valign={item.valign} cods={item.props} key={index}/>
          )
          })}
        
      </div>
    </div>
  )
}

export default Fluxograma

const Blank = {
  imagem: blank,
  flows: [
    { 
      flow: '',
      props: ['flowVinhaca'],
      x: 29 , y: 3, align: 'left', valign: 'flex-start'
    }
  ]
}
 
const extracao = {
  imagem: extracaoFlx,
  flows: [
    { 
      flow: 'Cana',
      props: ['flowCana','polCana', 'purezaCana', 'fibraCana', 'arCana', 'artCana', 'brixCana', 'artMassaCana', 'tempCana'],
      x: 1.5, y: 2.5, align: 'left', valign: 'flex-start'
    },
     { 
      flow: 'Embebição',
      props: ['flowEmbeb','fibraEmbeb', 'tempCondpEmbeb'],
      x: 117.5, y: 2.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Bagacilho',
      props: ['flowBagacilho'],
      x: 117.5, y: 33.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Bagaço',
      props: ['flowBagacoToBoilers'],
      x: 117.5, y: 25.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Limp. Peneira',
      props: ['consCondVGLimpPeneira'],
      x: 86.5, y: 39, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowBagaco', 'fibraBagaco', 'polBagaco', 'umidBagaco', 'brixBagaco', 'artBagaco', 'artMassaBagaco'],
      x: 106, y: 21, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo 1ºT',
      props: ['flowCaldo1T', 'brixCaldo1T', 'artMassaCaldo1T', 'tempCaldo1T'],
      x: 18, y: 38, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Caldo 2ºT',
      props: ['flowCaldo2T', 'brixCaldo2T', 'artMassaCaldo2T', 'tempCaldo2T'],
      x: 49, y: 38, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Açúcar',
      props: ['flowCaldoAcucar', 'brixCaldoAcucar', 'artMassaCaldoAcucar', 'tempCaldoAcucar'],
      x: 1.5, y: 69, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Etanol',
      props: ['flowCaldoEtanol', 'brixCaldoEtanol', 'artMassaCaldoEtanol', 'tempCaldoEtanol'],
      x: 1.5, y: 84.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'P.Lav. Cana',
      props: ['perdaArtLavagemCana', 'perdaMassaArtLavagemCana'],
      x: 16.5, y: 7.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'P. Água Res.',
      props: ['perdaArtAguaResiduaria', 'perdaMassaArtAguaResiduaria'],
      x: 30, y: 64.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'P.Inteterm.',
      props: ['perdaArtIndeterminda', 'perdaMassaArtIndeterminda'],
      x: 30, y: 57, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldoSecToAcucar'],
      x: 72.5, y: 69.5, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldoPrimToEtanol'],
      x: 72.5, y: 75.5, align: 'center', valign: 'flex-end'
    }
  ]
}

const extAcion = {
  imagem: extAcFlx,
  flows: [
    { 
      flow: 'V22',
      props: ['flowVaporV22M1'],
      x: 3, y: 11.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VE',
      props: ['gerVaporVEM1', 'tempVaporVEM1'],
      x: 116, y: 27.6, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22Pic1M1'],
      x: 30, y: 14, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22Pic1M1', 'tempVaporVEPic1M1'],
      x: 32.5, y: 26, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22Pic2M1'],
      x: 57, y: 14, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22Pic2M1', 'tempVaporVEPic2M1'],
      x: 59.5, y: 26, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22DesfM1'],
      x: 82.5, y: 14, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22DesfM1', 'tempVaporVEDesfM1'],
      x: 85, y: 26, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporV22TerM1'],
      x: 106.5, y: 14, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporV22TerM1', 'tempVaporVETerM1'],
      x: 109, y: 26, align: 'left', valign: 'flex-end'
    },


    { 
      flow: 'V22',
      props: ['flowVaporV22M2'],
      x: 3, y: 42.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VE',
      props: ['gerVaporVEM2', 'tempVaporVEM2'],
      x: 116, y: 58.6, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22NivM2'],
      x: 30.5, y: 45, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22NivM2', 'tempVaporVENivM2'],
      x: 33, y: 57, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22Pic1M1'],
      x: 50.5, y: 45, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22Pic1M1', 'tempVaporVEPic1M1'],
      x: 53, y: 57, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22Pic2M1'],
      x: 69.5, y: 45, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22Pic2M1', 'tempVaporVEPic2M1'],
      x: 72, y: 57, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22DesfM1'],
      x: 88.5, y: 45, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22DesfM1', 'tempVaporVEDesfM1'],
      x: 91, y: 57, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporV22TerM1'],
      x: 107, y: 45, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporV22TerM1', 'tempVaporVETerM1'],
      x: 109.5, y: 57, align: 'left', valign: 'flex-end'
    },
  ]
}

const tratAcucar = {
  imagem: TratAcFlx,
  flows: [
    { 
      flow: 'Vinhaça',
      props: ['flowVinhaca', 'tempVinhaca'],
      x: 29 , y: 3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Vinhaça Reg.',
      props: ['flowVinhaca', 'tempVinhacaReg'],
      x: 19, y: 23.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutCaldoRegCaldoVinhaca'],
      x: 30, y: 15.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG Reg.',
      props: ['flowCondVegetal', 'tempCondReg'],
      x: 41, y: 23.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG',
      props: ['flowCondVegetal', 'tempCondVG'],
      x: 51, y: 3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDV1 Reg.',
      props: ['gerCondVG1EvapAc', 'tempCondVG1Reg'],
      x: 66.5, y: 23.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDV1',
      props: ['gerCondVG1EvapAc', 'tempCondVG1EvapAc'],
      x: 76, y: 3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Veg.',
      props: ['gerCondVGAqVV1Acucar2'],
      x: 89, y: 25.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'VV1',
      props: ['consVaporVV1AqAcucar2'],
      x: 98.5, y: 3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutAqVV1Acucar2'],
      x: 100, y: 13.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutCaldoRegCaldoCond'],
      x: 53, y: 15.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Leite de Cal',
      props: ['flowLeiteCalAcucar'],
      x: 117.5 , y: 47.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Caldo Filtrado',
      props: ['flowCaldoFiltToAcucar', 'brixCaldoFiltrado', 'tempCaldoFiltrado'],
      x: 117.5 , y: 42, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'VV2',
      props: ['consVaporVV2AqAcucar'],
      x: 85, y: 32.3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Veg.',
      props: ['gerCondVGAqVV2Acucar'],
      x: 75.7, y: 53.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutAqVV2Acucar'],
      x: 55, y: 42.5, align: 'center', valign: 'flex-start'
    },
    { 
      flow: 'VV1',
      props: ['consVaporVV1AqAcucar'],
      x: 49.2, y: 32.3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Veg.',
      props: ['gerCondVGAqVV1Acucar'],
      x: 39.7, y: 53.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutAqVV1Acucar'],
      x: 15, y: 42.5, align: 'center', valign: 'flex-start'
    },
    { 
      flow: 'Sol. Polimero',
      props: ['flowAguaPolimeroAcucar'],
      x: 1.8, y: 77, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Vapor Flash',
      props: ['flowVapFlashAcucar'],
      x: 12, y: 61.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldoClarifAcucar', 'brixCaldoClarifAcucar', 'tempCaldoClarifAcucar'],
      x: 60, y: 70, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VV1',
      props: ['consVaporVV1AqCCAcucar'],
      x: 116.5, y: 60, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG',
      props: ['gerCondVGAqCCAcucar'],
      x: 107, y: 83, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Açúcar',
      props: ['flowCaldoAcucar', 'brixCaldoAcucar', 'tempCaldoAcucar', 'artMassaCaldoAcucar'],
      x: 1.5, y: 13, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoSulfAcucar', 'brixCaldoSulfAcucar', 'tempCaldoSulfAcucar'],
      x:115, y: 25, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoAquecAcucar', 'brixCaldoAquecAcucar', 'tempCaldoAquecAcucar'],
      x: 6, y: 52, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoDosAcucar', 'brixCaldoDosAcucar', 'tempCaldoDosAcucar'],
      x: 92, y: 42.6, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldoFlashAcucar', 'brixCaldoFlashAcucar', 'tempCaldoFlashAcucar'],
      x: 26, y: 70, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Clarificado',
      props: ['flowCaldoClarifAcucar', 'brixCaldoClarifAcucar', 'polCaldoClarifAcucar', 'artCaldoClarifAcucar', 'tempCaldoClarifAqAcucar', 'artMassaCaldoClarifAcucar',],
      x: 117.5, y: 73, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Lodo Açúcar',
      props: ['flowLodoAcucar', 'brixLodoAcucar', 'artMassaLodoAcucar'],
      x: 1.7, y: 85, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['tempCaldoRegAcucar'],
      x: 80, y: 13.2, align: 'left', valign: 'flex-end'
    }
  ]
}

const tratEtanol = {
  imagem: TratEtFlx,
  flows: [
    { 
      flow: 'Caldo Etanol',
      props: ['flowCaldoEtanol','brixCaldoEtanol','tempCaldoEtanol','artMassaCaldoEtanol'],
      x: 2.4, y: 13, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoDosEtanol', 'brixCaldoDosEtanol', 'tempCaldoDosEtanol'],
      x: 85, y: 43, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Cl. Etanol',
      props: ['flowCaldoClarifEtanol', 'tempCaldoClarifEtanol'],
      x: 74 , y: 3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoClarifEtanol', 'tempCaldoClarReg'],
      x: 61.4 , y: 23.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutCaldoRegCaldoCaldo'],
      x: 80, y: 15.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutAqVV2Etanol'],
      x: 45, y: 43.4, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['tempOutAqVV1Etanol'],
      x: 10, y: 43.4, align: 'center', valign: 'flex-end'
    },
    { 
      flow: 'VV2',
      props: ['consVaporVV2AqEtanol'],
      x: 71.6, y: 32.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Veg.',
      props: ['gerCondVGAqVV2Etanol'],
      x: 62.2, y: 54.8, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'VV1',
      props: ['consVaporVV1AqEtanol'],
      x: 36.7, y: 32.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Veg.',
      props: ['gerCondVGAqVV1Etanol'],
      x: 27.2, y: 54.8, align: 'left', valign: 'flex-start'
    },
    
    { 
      flow: '',
      props: ['flowCaldoAquecEtanol','brixCaldoAquecEtanol','tempCaldoAquecEtanol'],
      x: 12.5, y: 55, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoFlashEtanol','brixCaldoFlashEtanol','tempCaldoFlashEtanol'],
      x: 45, y: 70.3, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Clarificado',
      props: ['flowCaldoClarifEtanol','brixCaldoClarifEtanol', 'polCaldoClarifEtanol', 'artCaldoClarifEtanol', 'tempCaldoClarifEtanol', 'artMassaCaldoClarifEtanol'],
      x: 117, y: 72.8, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Lodo Etanol',
      props: ['flowLodoEtanol','brixLodoEtanol','artMassaLodoEtanol'],
      x: 2.4, y: 84.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Sol. Polimero',
      props: ['flowAguaPolimeroEtanol'],
      x: 2.4, y: 77, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Vapor Flash',
      props: ['flowVapFlashEtanol'],
      x: 21.6, y: 61.6, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Filtrado',
      props: ['flowCaldoFiltToEtanol', 'brixCaldoFiltrado', 'tempCaldoFiltrado'],
      x: 116.2, y: 42.5, align: 'right', valign: 'flex-end'
    },{ 
      flow: 'Leite de Cal',
      props: ['flowAguaLeiteCalEtanol'],
      x: 116.2, y: 47.5, align: 'right', valign: 'flex-start'
    },
    
  ]
}

const filtLodo = {
  imagem: FiltLodoFlx,
  flows: [
    { 
      flow: 'Bagacilho',
      props: ['flowBagacilho'],
      x: 115.5 , y: 37.8, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Água Emb.',
      props: ['flowAguaEmbebicaoFiltroRot'],
      x: 115.5, y: 52.4, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Água Emb.',
      props: ['flowAguaEmbebicaoFiltroPre'],
      x: 3, y: 42.8, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Água Lav.',
      props: ['flowAguaLavTelasFiltroPre'],
      x: 3, y: 51, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Águas Resid.',
      props: ['flowAguaLavTelasFiltroPre'],
      x: 3, y: 58.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Lodo Etanol',
      props: ['flowLodoEtanol', 'brixLodoEtanol', 'artMassaLodoEtanol'],
      x: 115.5, y: 6.6, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Lodo Açúcar',
      props: ['flowLodoAcucar', 'brixLodoAcucar', 'artMassaLodoAcucar'],
      x: 3, y: 6.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Caldo Filtrado',
      props: ['flowCaldoFiltrado', 'brixCaldoFiltrado', 'tempCaldoFiltrado', 'artMassaCaldoFiltrado'],
      x: 3, y: 72.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Torta',
      props: ['flowTorta','artTorta', 'artMassaTorta'],
      x: 3, y: 81.6, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Lodo',
      props: ['flowLodo','brixLodo', 'artMassaLodo'],
      x: 68, y: 6.6, align: 'left', valign: 'flex-start'
    }
  ]
}

const evaporacao = {
  imagem: EvapFlx,
  flows: [
    { 
      flow: 'Sangria VV1',
      props: ['sangriaVV1'],
      x: 40.5 , y: 6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Sangria VV2',
      props: ['sangriaVV2'],
      x: 60.8 , y: 6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Sangria VV3',
      props: ['sangriaVV3'],
      x: 80 , y: 6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV1'],
      x: 31.5 , y: 23, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV2'],
      x: 51.5 , y: 21, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV3'],
      x: 71.5 , y: 21, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV4'],
      x: 90.5 , y: 21, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV5'],
      x: 109.5 , y: 21, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldo1EfAc', 'brixCaldo1EfAc'],
      x: 30 , y: 47, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldo2Ef', 'brixCaldo2EfAc'],
      x: 49 , y: 47, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldo3Ef', 'brixCaldo3EfAc'],
      x: 69 , y: 47, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldo4Ef', 'brixCaldo4EfAc'],
      x: 88 , y: 47, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowXarope', 'brixXarope'],
      x: 107 , y: 47, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Xarope p/ Fáb.',
      props: ['flowXaropeToFab','brixXarope'],
      x: 116.5, y: 47, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Xarope p/ Ferm.',
      props: ['flowXaropeToFerm','brixXarope'],
      x: 116.5, y: 57, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Vapor Escape',
      props: ['consVaporVEEvapAc'],
      x: 2.3, y: 35, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Escape',
      props: ['gerCondVEEvapAc'],
      x: 116.5, y: 75.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'CDVG',
      props: ['gerCondVGEvapAc'],
      x: 116.5, y: 38.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'CDV1',
      props: ['gerCondVG1EvapAc'],
      x: 116.5, y: 66.3, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['taxaEvapPreEvapAcucar', 'areaOpPreEvapAcucar'],
      x: 16.5, y: 19.5, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['taxaEvap2EfEvapAcucar', 'areaOp2EfEvapAcucar'],
      x: 40, y: 19.5, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['taxaEvap3EfEvapAcucar', 'areaOp3EfEvapAcucar'],
      x: 59, y: 19.5, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['taxaEvap4EfEvapAcucar', 'areaOp4EfEvapAcucar'],
      x: 78.5, y: 19.5, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['taxaEvap5EfEvapAcucar', 'areaOp5EfEvapAcucar'],
      x: 98, y: 19.5, align: 'center', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Cl. Açúcar',
      props: ['flowCaldoClarifAcucar','brixCaldoClarifAcucar','polCaldoClarifAcucar','artCaldoClarifAcucar','tempCaldoClarifAqAcucar','artMassaCaldoClarifAcucar'],
      x: 2.3, y: 49.3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaEvapTorreFab'],
      x: 116.5, y: 15.8, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaQuenteEvapTorreFab'],
      x: 116.5, y: 27.4, align: 'right', valign: 'flex-start'
    },
  ]
}

const fermentacao = {
  imagem: FermFlx,
  flows: [
    { 
      flow: 'Xarope',
      props: ['flowXaropeToFerm', 'brixXarope', 'tempXarope'],
      x: 116 , y: 3.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Caldo',
      props: ['flowCaldoToFerm', 'brixCaldoToFerm', 'tempCaldoToFerm'],
      x: 3, y: 3.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Mel Final',
      props: ['flowMelFinal2', 'brixMelFinal2', 'tempMelFinal'],
      x: 3, y: 13.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Água Diluição',
      props: ['flowAguaDilMosto', 'tempAguaDilMosto'],
      x: 116, y: 13.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Mosto',
      props: ['flowMosto', 'brixMosto', 'artMassaMosto', 'tempMosto'],
      x: 68, y: 25, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Mosto Resf.',
      props: ['flowMosto', 'tempMostoResf'],
      x: 68, y: 42, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Vinho Bruto',
      props: ['flowVinhoBruto', 'concEtanolVinhoBruto', 'concCelVinhoBruto'],
      x: 68, y: 62, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Vinho',
      props: ['flowVinhoCent','concEtanolVinhoBruto','concCelVinhoCent'],
      x: 116, y: 75, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Creme',
      props: ['flowCreme','concCelCreme'],
      x: 52.5, y: 69.1, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Ferm. Trat.',
      props: ['flowfermTratado','concEtanolFermTratado','concCelFermTratato'],
      x: 46.5, y: 60, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CO2',
      props: ['flowCO2'],
      x: 40, y: 36, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Água',
      props: ['flowAguaLavCO2'],
      x: 3, y: 40, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaLavCO2'],
      x: 14, y: 52, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Água',
      props: ['flowAguaComplemDilFerm'],
      x: 3, y: 70.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consAguaTratDiluicaoFerm'],
      x:25.3, y: 72.6, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Sangria',
      props: ['flowVolSangriaLev'],
      x: 3, y: 81, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowAguaResfMosto', 'tempAguaEntResfMosto'],
      x: 116, y: 31, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaResfMosto', 'tempAguaSaiResfMosto'],
      x: 116, y: 40, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaTorreFerm', 'tempAguaFriaTorreFerm'],
      x: 116, y: 47.8, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaTorreFerm', 'tempAguaQuenteTorreFerm'],
      x: 116, y: 57, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowMelpEstoque', 'artMassaMelpEstoque'],
      x: 30.4, y: 14, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowMelpFerm', 'artMassaMelpFerm'],
      x: 48.5, y: 9.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowMelEstoque', 'brixMelEstoque', 'artMassaMelEstoque'],
      x: 40, y: 15.8, align: 'center', valign: 'flex-start'
    },
  ]
}

const destilaria = (opVaporA2) => ({
  imagem: DestFlx,
  flows: [
    { 
      flow: 'Água',
      props: ['flowAguaTorreDestilatia', 'tempAguaQuenteTorreFerm'],
      x: 1.5 , y: 3.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Vinho',
      props: ['flowVolVinhoCent', 'concEtanolVinhoBruto'],
      x: 1.5, y: 74.3, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Vinhaça',
      props: ['flowVinhaca', 'concEtanolVinhaca', 'tempVinhaca'],
      x: 1.5, y: 84, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowEtanol'],
      x: 55, y: 11.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'VV1',
      props: ['flowVaporVV1Destilaria'],
      x: 70, y: 57.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'VE',
      props: ['flowVaporVEDestilaria'],
      x: 70, y: 68.7, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporTotalColA'],
      x: 29, y: 42.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporTotalColB'],
      x: 54, y: 36.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVV1IndColA'],
      x: 76.5, y: 65.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporVV1DirColA'],
      x: 63, y: 65.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Flegmaça',
      props: ['flowFlegmaca', 'concEtanolFlegmaca'],
      x: 117.5, y: 84, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Flegma',
      props: ['flowFlegma', 'concEtanolFlegma'],
      x: 24.6, y: 34.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'CDVG',
      props: ['flowRetCondColA'],
      x: 70, y: 74.2, align: 'right', valign: 'flex-start'
    },

     //2VIEW
    { 
      flow: '',
      props: ['flowAguaTorreColA2'],
      x: 69, y: 5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowAguaTorrePeneriraMol'],
      x: 106, y: 5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowEtanolProdToHid'],
      x: 83, y: 15, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowEtanolHidToPenMol'],
      x: 101, y: 15, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowEtanolProdToNeutro'],
      x: 66.5, y: 15, align: 'right', valign: 'flex-end'
    },
//TANQUES
{ 
  flow: '',
  props: ['flowEtanolNeutro', 'flowEtanolNeutroDia'],
  x: 119.5, y: 51.2, align: 'center', valign: 'flex-start'
},
{ 
  flow: '',
  props: ['flowEtanolAnidro', 'flowEtanolAnidroDia'],
  x: 119.5, y: 61.3, align: 'center', valign: 'flex-start'
},
{ 
  flow: '',
  props: ['flowEtanolProdToHid', 'flowEtanolHidDia'],
  x: 119.5, y: 71, align: 'center', valign: 'flex-start'
},

    
    { 
      flow: 'V10',
      props: ['consVaporV10Desidratacao'],
      x: 117.5, y: 26.7, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'CDV10',
      props: ['consVaporV10Desidratacao'],
      x: 117.5, y: 33.7, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'VE',
      props: ['consVaporVEPenMol'],
      x: 117.5, y: 13.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'CDVE',
      props: ['consVaporVEPenMol'],
      x: 117.5, y: 20.5, align: 'right', valign: 'flex-start'
    },
    
    { 
      flow: opVaporA2 === 0 ? 'VV1' : 'VE',
      props: [opVaporA2 === 0 ? 'consVaporVV1ColA2' : 'consVaporVEColA2'],
      x: 80.3, y: 26.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'CDVG',
      props: ['gerCondVGEtNeutro'],
      x: 80.3, y: 33.6, align: 'right', valign: 'flex-end'
    }
  ]
})

const fabrica = {
  imagem: FabFlx,
  flows: [
    { 
      flow: 'VV1',
      props: ['consVaporVV1CozedorMassaA'],
      x: 14.3 , y: 26.3, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'VV1',
      props: ['consVaporVV1CozedorMassaB'],
      x: 74 , y: 26.3, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'VV2',
      props: ['consVaporVV2CozedorMassaB'],
      x: 93, y: 26.3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG',
      props: ['gerCondVGCozedorMassaA'],
      x: 51.4, y: 26.3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG',
      props: ['gerCondVGCozedorMassaB'],
      x: 114, y: 26.3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG',
      props: ['consCondVGMelAPobre'],
      x: 40.2, y: 41.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'CDVG',
      props: ['consCondVGMelARico'],
      x: 25, y: 41.7, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'CDVG',
      props: ['consCondVGCintrifMassaA'],
      x: 29.7, y: 56, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG',
      props: ['consCondVGMagma'],
      x: 82.8, y: 68.3, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'CDVG',
      props: ['consCondVGCintrifMassaB'],
      x: 92.5, y: 56, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG',
      props: ['consCondVGMelBRico'],
      x: 87.8, y: 41.7, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VE Limp.',
      props: ['consVaporVELimpCozMA'],
      x: 44.8, y: 20.7, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'VE Limp.',
      props: ['consVaporVELimpCozMB'],
      x: 107.5, y: 20.7, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Xarope',
      props: ['flowXaropeToFab', 'brixXarope', 'purezaXarope', 'artMassaXaropeToFab'],
      x: 3, y: 3.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowXaropeToMassaA'],
      x: 20, y: 13.4, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowXaropeToMassaB'],
      x: 83, y: 13.4, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowMARDiluido', 'brixMARDiluido', 'purezaMelARico'],
      x: -1, y: 42, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Mel A Rico',
      props: ['flowMelARico', 'brixMelARico', 'purezaMelARico'],
      x: 22, y: 47, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowMAPDiluido', 'brixMAPDiluido', 'purezaMAPDiluido'],
      x: 50, y: 42, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Mel B Rico',
      props: ['flowMelBRico', 'brixMelBRico', 'purezaMelBRico'],
      x: 85, y: 47, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowMelBRicoDil', 'brixMelBRicoDil', 'purezaMelBRico'],
      x: 78, y: 38.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Açúcar',
      props: ['flowAcucar', 'flowAcucarScsdia', 'brixAcucar', 'purezaAcucar', 'artMassaAcucar'],
      x: 3, y: 83.3, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Mel Final',
      props: ['flowMelFinal2', 'brixMelFinal2', 'purezaMelFinal2', 'artMassaMelFinal2'],
      x: 116, y: 47, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowMAPDiluidoToMB'],
      x: 50, y: 33, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowMAPDiluidoToMF'],
      x: 118.3, y: 38.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Açúcar B',
      props: ['flowAcucarB',  'brixAcucarB', 'purezaAcucarB'],
      x: 103.7, y: 60, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Mel A Pobre',
      props: ['flowMelAPobre', 'brixMelAPobre', 'purezaMelAPobre'],
      x: 43, y: 47, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Massa A',
      props: ['flowMassaA', 'brixMassaA', 'purezaMassaA'],
      x: 40.7, y: 30, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Massa B',
      props: ['flowMassaB', 'brixMassaB', 'purezaMassaB'],
      x: 103.7, y: 30, align: 'left', valign: 'flex-start'
    },
    // { 
    //   flow: '',
    //   props: ['flowAlimMassaA', 'brixAlimMassaA', 'purezaAlimMassaA'],
    //   x: 8.4, y: 20, align: 'right', valign: 'flex-start'
    // },
    // { 
    //   flow: '',
    //   props: ['flowAlimMassaB', 'brixAlimMassaB', 'purezaAlimMassaB'],
    //   x: 42.3, y: 20, align: 'right', valign: 'flex-start'
    // },
    { 
      flow: 'Magma',
      props: ['flowMagma', 'brixMagma', 'purezaMagma'],
      x: 103.7, y: 76, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VE',
      props: ['consVaporVESecadorAcucar'],
      x: 3, y: 69, align: 'left', valign: 'flex-end'
    }
  ]
}

const vapor = {
  imagem: vapFlx,
  flows: [
    { 
      flow: 'Bagaço',
      props: ['flowBagacoToBoilers'],
      x: 3 , y: 11.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Sobra',
      props: ['sobraBagaco'],
      x: 3, y: 46.4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'V67',
      props: ['pressureVA', 'tempVaporVA'],
      x: 3, y: 6.4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'V42',
      props: ['pressureVM', 'tempVaporVM'],
      x: 3, y: 23.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'V22',
      props: ['pressureV22', 'tempVaporV22'],
      x: 3, y: 40.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VE',
      props: ['pressureVE', 'tempVaporVE'],
      x: 3, y: 57.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VV1',
      props: ['pressureVV1'],
      x: 3, y: 65.3, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VV2',
      props: ['pressureVV2'],
      x: 3, y: 73.7, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VV3',
      props: ['pressureVV3'],
      x: 3, y: 83, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['gerVaporVACaldeiras'],
      x: 26.5, y: 4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consCondDesCaldeirasVA', 'tempAguaAlimCaldeirasVA'],
      x: 26.5, y: 9.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consBagCaldeirasVA'],
      x: 5.5, y: 13.7, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowBlowDownCaldVA'],
      x: 26.5, y: 15, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['gerVaporVMCaldeiras'],
      x: 26.5, y: 21, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consCondDesCaldeirasVM', 'tempAguaAlimCaldeirasVM'],
      x: 26.5, y: 26.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consBagCaldeirasVM'],
      x: 5.5, y: 31.3, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowBlowDownCaldVM'],
      x: 26.5, y: 32.4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['gerVaporV22Caldeiras'],
      x: 26.5, y: 38, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consCondDesCaldeirasV22', 'tempAguaAlimCaldeirasV22'],
      x: 26.5, y: 43.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consBagCaldeirasV22'],
      x: 5.5, y: 47.8, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowBlowDownCaldV22'],
      x: 26.5, y: 49, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVATgCpVA', 'pressInTgCpVA', 'tempInTgCpVA'],
      x: 66, y: 3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['gerVaporVETgCpVA', 'tempEscTgCpVA'],
      x: 62.5, y: 13, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['ptTgCpVA'],
      x: 72.5, y: 9.3, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['eficIsoTgCpVA'],
      x: 56.3, y: 10, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVMTgCpVM', 'pressInTgCpVM', 'tempInTgCpVM'],
      x: 83.1, y: 20, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['gerVaporVETgCpVM', 'tempEscTgCpVM'],
      x: 79.4, y: 30.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['ptTgCpVM'],
      x: 88.5, y: 26.8, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['eficIsoTgCpVM'],
      x: 72.8, y: 27.5, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVMTgCdVM', 'pressInTgCpVM', 'tempInTgCpVM'],
      x: 49.5, y: 20, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['gerCondVETgCdVM', 'pressEscTgCdVM', 'tempEscTgCdVM'],
      x: 46, y: 30.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['ptTgCdVM'],
      x: 54.7, y: 26.8, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['eficIsoTgCdVM'],
      x: 40, y: 27.5, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporV22M1'],
      x: 69, y: 38, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['gerVaporVEM1', 'tempVaporVEM1'],
      x: 66, y: 48, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporV22M2'],
      x: 86, y: 38, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['gerVaporVEM2', 'tempVaporVEM2'],
      x: 83, y: 48, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporV22TBCaldeiras'],
      x: 103, y: 38, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVETBCaldeiras', 'tempVaporVETBCaldeiras'],
      x: 100, y: 48, align: 'left', valign: 'flex-start'
    },
    //REB
    { 
      flow: '',
      props: ['consVaporVARebVAVE'],
      x: 96.5, y: 4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowAguaDesRebVAVE'],
      x: 89, y: 12.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['gerVaporVERebVaVe'],
      x: 96.5, y: 16, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVARebVAVM'],
      x: 110.7, y: 4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowAguaDesRebVAVM'],
      x: 103, y: 12.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['gerVaporVMRebVAVM'],
      x: 110.7, y: 16, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVMRebVMV22'],
      x: 104, y: 21, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowAguaDesRebVMV22'],
      x: 96, y: 30.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['gerVaporV22RebVMV22'],
      x: 104, y: 33.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22RebV22VE'],
      x: 114.5, y: 38, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowAguaDesRebV22VE'],
      x: 106.5, y: 48, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['gerVaporVERebV22VE'],
      x: 114.5, y: 50.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVEEvapAc'],
      x: 73.5, y: 54.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVEDesaerador2'],
      x: 47.7, y: 51, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVEDesaerador'],
      x: 47.7, y: 54.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVEDestilaria'],
      x: 84, y: 54.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVEPenMol'],
      x: 94.3, y: 54.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVESecadorAcucar'],
      x: 104.6, y: 54.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVELimpCoz'],
      x: 115.2, y: 54.5, align: 'left', valign: 'flex-end'
    },

    { 
      flow: '',
      props: ['flowVaporVV1Aquecimento'],
      x: 26, y: 63.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVV1TotalDest'],
      x: 46.5, y: 63.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVV1'],
      x: 73.5, y: 60, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVV12Efeito'],
      x: 67.5, y: 63.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVV1CozedorMassaA'],
      x: 84, y: 63.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVV1CozedorMassaB'],
      x: 105, y: 63.5, align: 'left', valign: 'flex-end'
    },

    { 
      flow: '',
      props: ['flowVaporVV2Aquecimento'],
      x: 35.3, y: 72.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVV23Efeito'],
      x: 56.6, y: 72.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVV2CozedorMassaA'],
      x: 76, y: 72.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVV2CozedorMassaB'],
      x: 94.7, y: 72.5, align: 'left', valign: 'flex-end'
    },

    { 
      flow: '',
      props: ['flowVaporVV3Aquecimento'],
      x: 46.5, y: 81.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVV34Efeito'],
      x: 67.5, y: 81.5, align: 'left', valign: 'flex-end'
    },

    { 
      flow: '',
      props: ['consVaporVEAlivio'],
      x: 124.4, y: 49.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVV1Alivio'],
      x: 124.4, y: 59, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVV2Alivio'],
      x: 124.4, y: 68.5, align: 'left', valign: 'flex-end'
    },
    //PERDAS
    { 
      flow: 'Perdas',
      props: ['consVaporVAPerdas'],
      x: 116, y: 4, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporVMPerdas'],
      x: 116, y: 21, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporV22Perdas'],
      x: 116, y: 38, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporVEPerdas'],
      x: 116, y: 55, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporVV1Perdas'],
      x: 116, y: 64.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporVV2Perdas'],
      x: 116, y: 73, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporVV3Perdas'],
      x: 116, y: 82.2, align: 'right', valign: 'flex-start'
    },

    { 
      flow: '',
      props: ['flowCondDessupFromDes'],
      x: 30, y: 12.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['gerVaporVEDessupVE'],
      x: 15, y: 56.3, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV22Desidratacao'],
      x: 116.7, y: 33.4, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporV10Desidratacao'],
      x: 116.7, y: 29, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consCondVEDessupDesid'],
      x: 108.8, y: 30.7, align: 'right', valign: 'flex-start'
    },

    { 
      flow: '',
      props: ['gerCondVEProcesso', 'tempRetCondVEDesaerador'],
      x: 53, y: 54.2, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCondVEDest'],
      x: 51.5, y: 59.6, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCondProDesVAVM'],
      x: 52, y: 46.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCondProDesV22'],
      x: 52, y: 56.2, align: 'left', valign: 'flex-end'
    },

    { 
      flow: '',
      props: ['flowAguaDesaerador2', 'tempDesaerador2'],
      x: 37.3, y: 46.8, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Desmi',
      props: ['consAguaDesmiDesaerador', 'tempsAguaDesmiDesaerador'],
      x: 49.5, y: 43.5, align: 'left', valign: 'flex-end'
    },
    
    { 
      flow: 'CDVG1',
      props: ['consCondVGDesaerador', 'tempCondVG1Reg'],
      x: 23.5, y: 59.5, align: 'left', valign: 'flex-end'
    },
  ]
}

const bh = {
  imagem: bhFlx,
  flows: [
    {flow: '', props: ['gerCondVGAqVV3Acucar'], x: 95.6, y: 9.6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVFLAcucar'], x: 95.6, y: 5.6, align: 'right', valign: 'flex-end'},
    // {flow: '', props: ['gerCondVGAqVV3Acucar2'], x: 79.8, y: 19.6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV2Acucar'], x: 95.6, y: 13.6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV1Acucar'], x: 95.6, y: 17.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqCCAcucar'], x: 95.6, y: 21.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV2Etanol'], x: 95.6, y: 25.4, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV1Etanol'], x: 95.6, y: 29.4, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGEvapAc'], x: 95.6, y: 33.4, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCDVGFabrica'], x: 95.6, y: 37.3, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowCondVegetal'], x: 81.5, y: 23.5, align: 'left', valign: 'flex-end'},
    //// {flow: '', props: ['flowCDVGtoTqCaldeira'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    //// {flow: '', props: ['flowSobraCDVGTqCaldeira'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    //// {flow: '', props: ['flowCondContaminado'], x: 70, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVEProcesso'], x: 81.3, y: 8, align: 'left', valign: 'flex-end'},
    //// {flow: '', props: ['consCondVGDesaerador'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: 'VE', props: ['consVaporVEDesaerador'], x: 70, y: 8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaDesmiDesaerador'], x: 63.5, y: 16, align: 'right', valign: 'flex-start'},
    {flow: 'Vapor', props: ['gerVaporVMCaldeiras'], x: 73.2, y: 28.9, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowAguaDesaerador'], x: 81.3, y: 16, align: 'left', valign: 'flex-end'},
    {flow: 'Dessup.', props: ['flowCondDessupFromDes'], x: 73.2, y: 20.9, align: 'right', valign: 'flex-end'},
    //// {flow: '', props: ['consCondVECaldeira1'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaResfMosto'], x: 22.5, y: 39.4, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTorreFerm'], x: 22.5, y: 52, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTotalTorreDest','tempAguaQuenteTotalTorreDest'], x: -5.8, y: 40, align: 'right', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTotalTorreDest', 'tempAguaFriaTorreFerm'], x: 27.2, y: 40, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaRepTorreDest'], x: 22.4, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaArrasteTorreDest'], x: 22.4, y: 58.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowPerdaEvapTorreDest'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaEvapTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaMassaATorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaMassaBTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaQuenteEvapTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaQuenteMassaATorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaQuenteMassaBTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaQuenteTorreFab','tempAguaQuenteTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTorreFab', 'tempAguaFriaTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['consAguaBrutaRepTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowPerdaArrasteTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowPerdaEvapTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTorreMancais','tempAguaQuenteTorreMancais'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTorreMancais', 'tempAguaFriaTorreMancais'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['consAguaBrutaRepTorreMancais'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowPerdaArrasteTorreMancais'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowPerdaEvapTorreMancais'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaComplemDilFerm'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaLavCO2'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratPrepPolimeto'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratLevedura'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratAbrandador'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratLimpEvap'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratEvap'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratGeral'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratPrepMosto'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},

    {flow: '', props: ['flowAguaBrutaTotal'], x: -8.5, y: 2.8, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaETA'], x: -8.5, y: 9.2, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaGeral'], x: -8.5, y: 71.8, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaEmb'], x: -8.5, y: 63.6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowFlegmaca'], x: 43.3, y: 52, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowVinhaca', 'tempVinhacaReg'], x: 84.8, y: 70.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaArrasteTorreVinhaca'], x: 78.6, y: 69, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaEvapTorreVinhaca'], x: 100.8, y: 69, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowVinhacaFria', 'tempVinhacaFriaTorreVinhaca'], x: 106, y: 70.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResPurgasTorreDest'], x: 34, y: 24.2, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResPurgasTorreFab'], x: 34, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResPurgasTorreMancais'], x: 34, y: 13.9, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResAbrandador'], x: 64.6, y: 20.9, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['gerAguaResLimpEvap'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResUsoGeral'], x: 43.2, y: 26.8, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResUsoGeralAguaBruta'], x: 16, y: 71.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['gerAguaResUsoGeralCDCont'], x: 81.3, y: 40, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResSobraCondCont'], x: 66.9, y: 58.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResDescCaldeira'], x: 81.3, y: 38, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResFiltroLodo'], x: 85.5, y: 58.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResETA'], x: 16, y: 9.1, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaResParaLavoura'], x: 115.5, y: 66, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContFiltroLodo'], x: 76, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContUsoGeral'], x: 85.5, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCDVGFabrica'], x: 95, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContPrepLeiteCal'], x: 104.6, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContEmbebicao'], x: 114.2, y: 48.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['consCondContDilMosto'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['consAguaResRepSistFuligem'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaLavadorGases'], x: 63.2, y: 75, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaEvapLavadorGases'], x: 52, y: 67.8, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowAguaFuligem'], x: 50.4, y: 82.6, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowTotalToLavoura'], x: 115.5, y: 77, align: 'left', valign: 'flex-end'},
  ]
}
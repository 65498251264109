import React, {useState} from 'react'
import {stdPlanoVars} from '../stdPlanoSafra'

const PsHooks = () => {

  const getPsReal = async (safra) => {
    try {
      const response = await fetch(`${global.endPoint}/getps?safra=${safra}&revisao=13_Realizado`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Erro de requisição.');
      }
      let impData = await response.json();

      if (!impData){
        return null
      }

      const groupedData = impData.reduce((acc, item) => {
        const { safra, revisao, codigo } = item;
        if (!acc[safra]) {
          acc[safra] = {};
        }
        if (!acc[safra][revisao]) {
          acc[safra][revisao] = [];
        }
        if (!acc[safra][revisao][codigo]) {
          acc[safra][revisao][codigo] = [];
        }
        acc[safra][revisao][codigo].push(item);
      
        return acc;
      }, {});
      

      // let stdPS = stdPlanoVars;
      // let tempData = [];

      // for (const item of stdPS){
      //   let newItem = impData.filter(row => row.codigo === item.cod);
      //   if (newItem.length > 0){
      //     tempData.push(newItem[0]);
      //   }
      // }
      return groupedData
      
    } catch (error) {
      console.error('Erro na requisição:', error);
      
    }
  };

  return {getPsReal};
};

export default PsHooks;

import Flow from '../Flow'
import extracaoFlx from '../../../assets/fluxogramas/extracaoWeb.png'

function ExtracaoFlx() {
const vh = window.innerHeight
const ideal = 727
let nVH = ideal/vh

return (
  <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
    <div style={{ position: 'absolute', width: `${115*nVH}vh`, height: `${100*nVH}vh`, top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}}>
      <img style={{position: 'absolute', height: `${90*nVH}vh`, width: `${135*nVH}vh`, left: '0vh', top: '0vh'}} src={extracao.imagem} alt="" />
    {extracao.flows.map((item, index) => {
        return (
          <Flow flow={item.flow} x={item.x * nVH} y={item.y * nVH} align={item.align} valign={item.valign} cods={item.props} key={index} f={nVH}/>
        )
        })}
    </div>
  </div>
  )
}

export default ExtracaoFlx

const extracao = {
    imagem: extracaoFlx,
    flows: [
      { 
        flow: 'Cana',
        props: ['flowCana','polCana', 'purezaCana', 'fibraCana', 'arCana', 'artCana', 'brixCana', 'artMassaCana', 'tempCana'],
        x: 1.5, y: 2.5, align: 'left', valign: 'flex-start'
      },
       { 
        flow: 'Embebição',
        props: ['flowEmbeb','fibraEmbeb', 'tempCondpEmbeb'],
        x: 117.5, y: 2.5, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Bagacilho',
        props: ['flowBagacilho'],
        x: 117.5, y: 33.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Bagaço',
        props: ['flowBagacoToBoilers'],
        x: 117.5, y: 25.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Limp. Peneira',
        props: ['consCondVGLimpPeneira'],
        x: 86.5, y: 39, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowBagaco', 'fibraBagaco', 'polBagaco', 'umidBagaco', 'brixBagaco', 'artBagaco', 'artMassaBagaco'],
        x: 106, y: 21, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Caldo 1ºT',
        props: ['flowCaldo1T', 'brixCaldo1T', 'artMassaCaldo1T', 'tempCaldo1T'],
        x: 18, y: 38, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Caldo 2ºT',
        props: ['flowCaldo2T', 'brixCaldo2T', 'artMassaCaldo2T', 'tempCaldo2T'],
        x: 49, y: 38, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Caldo Açúcar',
        props: ['flowCaldoAcucar', 'brixCaldoAcucar', 'artMassaCaldoAcucar', 'tempCaldoAcucar'],
        x: 1.5, y: 69, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Caldo Etanol',
        props: ['flowCaldoEtanol', 'brixCaldoEtanol', 'artMassaCaldoEtanol', 'tempCaldoEtanol'],
        x: 1.5, y: 84.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'P.Lav. Cana',
        props: ['perdaArtLavagemCana', 'perdaMassaArtLavagemCana'],
        x: 16.5, y: 7.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'P. Água Res.',
        props: ['perdaArtAguaResiduaria', 'perdaMassaArtAguaResiduaria'],
        x: 30, y: 64.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'P.Inteterm.',
        props: ['perdaArtIndeterminda', 'perdaMassaArtIndeterminda'],
        x: 30, y: 57, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowCaldoSecToAcucar'],
        x: 72.5, y: 69.5, align: 'center', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowCaldoPrimToEtanol'],
        x: 72.5, y: 75.5, align: 'center', valign: 'flex-end'
      }
    ]
  }

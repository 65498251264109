import Flow from '../Flow'
import TratAcFlx from '../../../assets/fluxogramas/tratFabrica3Web.png'

function TratFabricaFlx() {
  const vh = window.innerHeight
const ideal = 727
let nVH = ideal/vh

  return (
    <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
    <div style={{ position: 'absolute', width: `${115*nVH}vh`, height: `${100*nVH}vh`, top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}}>
      <img style={{position: 'absolute', height: `${90*nVH}vh`, width: `${135*nVH}vh`, left: '0vh', top: '0vh'}} src={TratAcFlx} alt="" />
    {tratAcucar.flows.map((item, index) => {
        return (
          <Flow flow={item.flow} x={item.x * nVH} y={item.y * nVH} align={item.align} valign={item.valign} cods={item.props} key={index} f={nVH}/>
        )
        })}
    </div>
  </div>
  )
}

export default TratFabricaFlx

const tratAcucar = {
    imagem: TratAcFlx,
    flows: [
      { 
        flow: 'Vinhaça',
        props: ['flowVinhaca', 'tempVinhaca'],
        x: 29 , y: 3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Vinhaça Reg.',
        props: ['flowVinhaca', 'tempVinhacaReg'],
        x: 19, y: 23.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['tempOutCaldoRegCaldoVinhaca'],
        x: 30, y: 15.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CDVG Reg.',
        props: ['flowCondVegetal', 'tempCondReg'],
        x: 41, y: 23.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CDVG',
        props: ['flowCondVegetal', 'tempCondVG'],
        x: 51, y: 3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CDV1 Reg.',
        props: ['gerCondVG1EvapAc', 'tempCondVG1Reg'],
        x: 66.5, y: 23.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CDV1',
        props: ['gerCondVG1EvapAc', 'tempCondVG1EvapAc'],
        x: 76, y: 3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Cond. Veg.',
        props: ['gerCondVGAqVV1Acucar2'],
        x: 89, y: 25.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'VV1',
        props: ['consVaporVV1AqAcucar2'],
        x: 98.5, y: 3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['tempOutAqVV1Acucar2'],
        x: 100, y: 13.2, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['tempOutCaldoRegCaldoCond'],
        x: 53, y: 15.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Leite de Cal',
        props: ['flowLeiteCalAcucar'],
        x: 117.5 , y: 47.5, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Caldo Filtrado',
        props: ['flowCaldoFiltToAcucar', 'brixCaldoFiltrado', 'tempCaldoFiltrado'],
        x: 117.5 , y: 42, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'VV2',
        props: ['consVaporVV2AqAcucar'],
        x: 85, y: 32.3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Cond. Veg.',
        props: ['gerCondVGAqVV2Acucar'],
        x: 75.7, y: 53.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['tempOutAqVV2Acucar'],
        x: 55, y: 42.5, align: 'center', valign: 'flex-start'
      },
      { 
        flow: 'VV1',
        props: ['consVaporVV1AqAcucar'],
        x: 49.2, y: 32.3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Cond. Veg.',
        props: ['gerCondVGAqVV1Acucar'],
        x: 39.7, y: 53.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['tempOutAqVV1Acucar'],
        x: 15, y: 42.5, align: 'center', valign: 'flex-start'
      },
      { 
        flow: 'Sol. Polimero',
        props: ['flowAguaPolimeroAcucar'],
        x: 1.8, y: 77, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Vapor Flash',
        props: ['flowVapFlashAcucar'],
        x: 12, y: 61.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowCaldoClarifAcucar', 'brixCaldoClarifAcucar', 'tempCaldoClarifAcucar'],
        x: 60, y: 70, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'VV1',
        props: ['consVaporVV1AqCCAcucar'],
        x: 116.5, y: 60, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CDVG',
        props: ['gerCondVGAqCCAcucar'],
        x: 107, y: 83, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Caldo Açúcar',
        props: ['flowCaldoAcucar', 'brixCaldoAcucar', 'tempCaldoAcucar', 'artMassaCaldoAcucar'],
        x: 1.5, y: 13, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowCaldoSulfAcucar', 'brixCaldoSulfAcucar', 'tempCaldoSulfAcucar'],
        x:115, y: 25, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowCaldoAquecAcucar', 'brixCaldoAquecAcucar', 'tempCaldoAquecAcucar'],
        x: 6, y: 52, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowCaldoDosAcucar', 'brixCaldoDosAcucar', 'tempCaldoDosAcucar'],
        x: 92, y: 42.6, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowCaldoFlashAcucar', 'brixCaldoFlashAcucar', 'tempCaldoFlashAcucar'],
        x: 26, y: 70, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Caldo Clarificado',
        props: ['flowCaldoClarifAcucar', 'brixCaldoClarifAcucar', 'polCaldoClarifAcucar', 'artCaldoClarifAcucar', 'tempCaldoClarifAqAcucar', 'artMassaCaldoClarifAcucar',],
        x: 117.5, y: 73, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'Lodo Açúcar',
        props: ['flowLodoAcucar', 'brixLodoAcucar', 'artMassaLodoAcucar'],
        x: 1.7, y: 85, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['tempCaldoRegAcucar'],
        x: 80, y: 13.2, align: 'left', valign: 'flex-end'
      }
    ]
  }

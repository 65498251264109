import React, { useRef, useState, useContext } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FaFilePdf } from "react-icons/fa6";
import buttomStyle from '../../../css/headButton.css'
import Modal from '../../BarraSuperior/Modal';
import Listas from '../../Conteudo/Listas'
import GlobalStateContext from '../../GlobalStateContext'

import ExtracaoFlx from '../../Conteudo/Fluxs/extracaoFlx';
import Headlandscape from '../HeadLandscape';
import TratFabricaFlx from '../../Conteudo/Fluxs/tratFabrica';
import TratEtanolFlx from '../../Conteudo/Fluxs/tratEtanol';
import FiltracaoLodoFlx from '../../Conteudo/Fluxs/filtracaoLofo';
import EvaporacaoFlx from '../../Conteudo/Fluxs/evaporacao';
import FermentacaoFlx from '../../Conteudo/Fluxs/fermentacao';
import DestilariaFlx from '../../Conteudo/Fluxs/destilaria';
import FabricaFlx from '../../Conteudo/Fluxs/fabrica';
import VaporFlx from '../../Conteudo/Fluxs/vapor';

const ExportPDF = ({fileName = 'fileName'}) => {
  const divRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalText, setModalText] = useState('...');
  const { setPrinting } = useContext(GlobalStateContext)

  const addToRefs = (el) => {

    if (el && !divRefs.current.includes(el)) {
      divRefs.current.push(el);
    }
  };

  async function exportPDF(){
    setPrinting(true)
    setIsLoading(true)
    setProgress(0)
    setModalText('Gerando PDF...')
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 1;
      if (progressValue <= 100) {
        setProgress(progressValue)
      } else {
        clearInterval(interval)
        setIsLoading(false)
        setPrinting(false)
      }
    }, 20);
   
    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    let promiseChain = Promise.resolve();

    divRefs.current.forEach((div, index) => {
      setProgress(divRefs.current.length/index)
      promiseChain = promiseChain.then(async () => {
        const canvas = await html2canvas(div);
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 277; // largura em mm para A4
        const imgHeight = 190; //(canvas.height * imgWidth) / canvas.width;
        if (index > 0) {
          pdf.addPage("a4", "l");
          // imgWidth = 190;
          // imgHeight = 277;
          pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
        }else{
          pdf.addImage(imgData, 'PNG', 10, 10, 190, 277);
        }
        
      });
    });



    promiseChain.then(() => {
        // pdf.setFont('courier');
        //     pdf.setFontSize(10);
        //     pdf.setCharSpace(6);
      pdf.save('fluxagramas.pdf');
    }).catch(err => {
      console.error('Erro ao gerar PDF:', err);
    });
  
  };

  return (
    <div>

<div style={{ position: 'absolute', left: '-9999px' }}>
        <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='RESUMO PRODUÇÃO'  fileName={fileName} page='1' pages='10'/>
            <div style={{ width: '100%',  marginTop: '5px', marginBottom: '300px', position: 'relative', lineHeight: '1.0'}}>
                <Listas/>
             </div>
             {/* <div style={{height: '300px', border: '1px solid blue'}}></div> */}
      </div>
    
      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='EXTRAÇÃO' subtitle='Fluxograma' fileName={fileName} page='2' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <ExtracaoFlx/>
             </div>
      </div>

     

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='TRATAMENTO FÁBRICA' subtitle='Fluxograma' fileName={fileName} page='3' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <TratFabricaFlx/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='TRATAMENTO DESTILARIA' subtitle='Fluxograma' fileName={fileName} page='4' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <TratEtanolFlx/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='FILTRAÇÃO LODO' subtitle='Fluxograma' fileName={fileName} page='5' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <FiltracaoLodoFlx/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='EVAPORAÇÃO' subtitle='Fluxograma' fileName={fileName} page='6' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <EvaporacaoFlx/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='FERMENTAÇÃO' subtitle='Fluxograma' fileName={fileName} page='7' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <FermentacaoFlx/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='DESTILARIA' subtitle='Fluxograma' fileName={fileName} page='8' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <DestilariaFlx/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='FÁBRICA DE AÇÚCAR' subtitle='Fluxograma' fileName={fileName} page='9' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <FabricaFlx/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='VAPOR E ENERGIA' subtitle='Fluxograma' fileName={fileName} page='10' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: '1.05',  lineHeight: '1' }}>
                <VaporFlx/>
             </div>
      </div>
       </div>
       <div className='menuButtom' onClick={exportPDF}>
            <FaFilePdf  className='menuIcon' />
            <div className='menuText'>Exportar</div>
        </div>
      {/* <button style={{position: 'relative'}} onClick={exportPDF}>PDF</button> */}
      <Modal show={isLoading} progress={progress} texto={modalText}/>
    </div>
  );
};

export default ExportPDF;



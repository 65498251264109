

import React, { useState, useContext, useEffect } from 'react'
import ImportBulletin from '../ANALISES/Botoes/ImportBulletin';
import BtDayAnalyzer from '../ANALISES/Botoes/btDayAnalyzer';
import BtNewPS from "./botoes/btNewPS";
import BtSavePS from './botoes/btSavePS';
import BtSaveAsPS from './botoes/btSaveAsPS';
import MenuBtDivider from '../components/ui/menuBtDivider';
import BtEditPS from './botoes/btEditPS';
import BtOpenPS from './botoes/btOpenPS';
import BtExportPdfPS from './botoes/btExportPdfPS';
import BtChartsPS from './botoes/btChartsPS';
import PSContext from './opoio/PSContext';
import GlobalStateContext from '../components/GlobalStateContext';
import PrintPlanoSafra from '../components/Pages/Print/PrintPlanoSafra';


function MenuPlanoSafra() {
  const { psData } = useContext(PSContext)
  const {user} = useContext(GlobalStateContext)

    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div className="psMainMenu">
                <BtOpenPS/>
                <MenuBtDivider/>
                <BtNewPS/>
                <MenuBtDivider/>
                <BtSavePS/>
                <MenuBtDivider/>
                <BtSaveAsPS/>
                <MenuBtDivider/>
                
                <BtEditPS/>
                <MenuBtDivider/>
                <BtExportPdfPS/>
                {/* <PrintPlanoSafra/> */}
                {/* <MenuBtDivider/>
                <BtChartsPS/> */}
            </div>
            {psData && <div style={{marginRight: '3vh', alignContent: 'center', fontSize: '2vh', fontWeight: 'bold'}}>{psData[0].safra} - {psData[0].revisao}</div>}
         </div> 
    )
}

export default MenuPlanoSafra
import React, { useState, useContext } from 'react';
import PmsLogo from '../assets/imgs/pmsLogo.png'
import { auth } from '../firebase/config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { GlobalStateContext } from '../components/GlobalStateContext'
function Login() {
    const [isHovered, setIsHovered] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { setUser, user} = useContext(GlobalStateContext)
  
    const handleLogin = async (event) => {
      event.preventDefault();
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        setUser(userCredential.user);
        // Sucesso! Redirecionar ou atualizar o estado conforme necessário
        console.log('Login bem-sucedido! ');
      } catch (error) {
        setError(error.message);
      }
    };
  
    return (
      <div style={styles.container}>
        <div style={styles.box}>
          <img src={PmsLogo} alt="Logo" style={styles.logo} />
          <h2>Login</h2>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <form onSubmit={handleLogin}>
            <div style={styles.inputGroup}>
              <label htmlFor="email" style={styles.label}>Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                style={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div style={styles.inputGroup}>
              <label htmlFor="password" style={styles.label}>Senha:</label>
              <input
                type="password"
                id="password"
                name="password"
                required
                style={styles.input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              style={isHovered ? { ...styles.button, ...styles.buttonHover } : styles.button}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Logar
            </button>
          </form>
        </div>
      </div>
    );
  }
  
  export default Login;

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      backgroundColor: '#f0f0f0',
    },
    box: {
      padding: '20px',
      background: '#fff',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      textAlign: 'center',
      width: '300px',
    },
    logo: {
      width: '100px',
      height: '100px',
      marginBottom: '20px',
    },
    inputGroup: {
      marginBottom: '15px',
      textAlign: 'left',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
    },
    input: {
      width: '100%',
      padding: '8px',
      boxSizing: 'border-box',
      border: '1px solid #ccc',
      borderRadius: '5px',
    },
    button: {
      width: '100%',
      padding: '10px',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '5px',
      color: 'white',
      fontSize: '16px',
      cursor: 'pointer',
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    }
  };
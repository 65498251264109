import { AlignCenter } from 'lucide-react'
import React from 'react'
import LogoCustomer from '../../assets/fluxogramas/logoCostumer.png'
import LogoPMS from '../../assets/imgs/pmsLogo.png'
import LogoMasm from '../../assets/imgs/masmLogo.png'

  const Headlandscape = ({title, subtitle, fileName, page, pages, nomeField = 'Nome'}) => {
    const vh = window.innerHeight
const ideal = 727
let nVH = ideal/vh
    return (
        <div style={{height: '100px', width: '100%', borderBottom: '1px solid black', position: 'relative'}}>
            <div style={{display: 'flex', direction: 'row', justifyContent: 'center', alignItems: 'center', height: '65px', borderBottom: '1px solid black'}}>
                <div style={{width: '25%', height: '100%', borderRight: '1px solid black', alignContent: 'center'}}>
                     {/* <img style={{height: '80%'}} src={LogoCustomer} alt="" /> */}
                </div>
                <div style={{width: '50%'}}>
                <div style={{fontWeight: 'bold', fontSize: `${2.5*nVH}vh`}}>{title}</div>
                {subtitle && 
                <div style={{fontSize: `${2*nVH}vh`}}>{subtitle}</div>}
                </div>
                
                <div style={{width: '25%', height: '100%', borderLeft: '1px solid black', alignContent: 'center'}}>
                    <img style={{height: '70%'}} src={LogoMasm} alt="" />
                    <img style={{height: '80%'}} src={LogoPMS} alt="" />
                </div>
                
                </div>
            <div style={{display: 'flex', direction: 'row',  height: '35px'}}>
                <div style={{height: '100%', width: '50%', alignContent: 'center', textAlign: 'left', paddingLeft: `${2*nVH}vh`, borderRight: '1px solid black'}}>
                    {nomeField}: {fileName}
                </div>
                <div style={{height: '100%', width: '26%', alignContent: 'center', textAlign: 'left', paddingLeft: `${2*nVH}vh`}}>
                    Data: {new Date().toLocaleString()}
                </div>
                <div style={{height: '100%', width: '25%', alignContent: 'center', textAlign: 'left', paddingLeft: `${2*nVH}vh`, borderLeft: '1px solid black'}}>
                    Página: {page}/{pages}
                </div>
            </div>
         </div>
    )
  }

export default Headlandscape
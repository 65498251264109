import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import { MdFileCopy } from "react-icons/md";


export default function BtSaveAsPS() {
  const {isSave, setIsSaveAs, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart, psData} = useContext(PSContext)
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if(isNew || isSave || isOpen || isExpPdf || isEdit || isChart || !psData){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
  }, [isSave, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart])

    const btClicked = () => {
      setIsSaveAs(!isSaveAs)
    }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
        <button className='psButtom' onClick={btClicked}
        disabled={isDisable}>
        <div className='psMenuButtom' >
    <MdFileCopy  className='psMenuIcon'/>
    <div className='psMenuText'>COPIAR</div></div>
        </button>
</div>
  )
}
import React, {useContext, useEffect, useState } from 'react';
import { FaFile } from "react-icons/fa6";
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import '../../css/planoSafra.css';
import { calcPS } from '../opoio/calcPS';

export default function BtSavePS() {
  const {isSave, setIsSave, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart, psData, setPsData} = useContext(PSContext)
  const {user} = useContext(GlobalStateContext)
 
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if(isNew || isSaveAs || isOpen || isExpPdf || isEdit || isChart || !psData){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
  }, [isSave, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart])

    const btClicked = async () => {
      setIsSave(true)
      let temp = psData
      temp.filter(item => item.codigo === 'psAlerts')[0].descricao = user?.email
      console.log(temp)
      setPsData(temp)
      await atualizarPS()
      global.psSaved = true
      setIsSave(false)
    }

    async function atualizarPS(){
      // let tempData = await calcPS(psData)
      // setPsData(tempData);

      await fetch(''+global.endPoint+'/atualizarps', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(psData)
      })
      .then(async response => {
        if (!response.ok) {
          throw new Error('Erro de requisição.');
        }else{
          console.log('updated')
        }
      })
      .catch(error => {
        // Lida com o erro da requisição
      })
    }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
        <button className='psButtom' onClick={btClicked}
        disabled={isDisable}>
        <div className='psMenuButtom' >
    <FaFile  className='psMenuIcon' style={{scale: '0.7'}}/>
    <div className='psMenuText'>SALVAR</div>
    {global.psSaved !== true && <div className='psAlert'>!</div>}
    </div>
        </button>
</div>
  )
}
import React, { useContext, useEffect, useState } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'

function OutputLine({cod}) {
  const { data, isPrinting} = useContext(GlobalStateContext);
  const [updated, setUpdate] = useState(false)

  useEffect(() => {
    setUpdate(!updated)
  }, [data]);

  const vh = window.innerHeight
  const ideal = 727
  let nVH = isPrinting? ideal/vh : 1

  const styles = {
    inputLineCont: {
      marginLeft: `${2*nVH}vh`},
    desc: {
        fontSize: `${1.7*nVH}vh`,
    },
    optCont: {
      padding: `${0.2*nVH}vh`,
      fontSize: `${1.6*nVH}vh`,
      padding: `${0.6*nVH}vh`,
      margin: `${0.4*nVH}vh`},
      unit:{
        fontSize: `${1.6*nVH}vh`,
        paddingRight: `${1*nVH}vh`,
        marginRight: `${1*nVH}vh`,
    }
  }

  return (
    <div className={`inputLineCont ${data[cod]?.limiteMax !== null && data[cod]?.valor > data[cod]?.limiteMax ? 'outOfLimit' : ''}`} style={styles.inputLineCont}>
            <div className='line' >
                <div className='desc' style={styles.desc}>{data[cod]?.descricao}</div>
                <div className='optCont' style={styles.optCont}>{data[cod]?.valor !== null ? data[cod]?.valor.toFixed(2) : data[cod]?.valor}</div>
                <div className='unit' style={styles.unit}>{data[cod]?.unidade}</div>
            </div>
    </div>
  )
}

export default OutputLine


import { useContext } from 'react'
import Flow from '../Flow'
import FabFlx from '../../../assets/fluxogramas/fabrica2M_UseWeb.png'
import GlobalStateContext from '../../GlobalStateContext'

function FabricaFlx() {
  const vh = window.innerHeight
const ideal = 727
let nVH = ideal/vh

  return (
    <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
    <div style={{ position: 'absolute', width: `${115*nVH}vh`, height: `${100*nVH}vh`, top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}}>
      <img style={{position: 'absolute', height: `${90*nVH}vh`, width: `${135*nVH}vh`, left: '0vh', top: '0vh'}} src={FabFlx} alt="" />
    {fabrica.flows.map((item, index) => {
        return (
          <Flow flow={item.flow} x={item.x * nVH} y={item.y * nVH} align={item.align} valign={item.valign} cods={item.props} key={index} f={nVH}/>
        )
        })}
    </div>
  </div>
  )
}

export default FabricaFlx

const fabrica = {
    imagem: FabFlx,
    flows: [
      { 
        flow: 'VV1',
        props: ['consVaporVV1CozedorMassaA'],
        x: 14.3 , y: 26.3, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'VV1',
        props: ['consVaporVV1CozedorMassaB'],
        x: 74 , y: 26.3, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'VV2',
        props: ['consVaporVV2CozedorMassaB'],
        x: 93, y: 26.3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CDVG',
        props: ['gerCondVGCozedorMassaA'],
        x: 51.4, y: 26.3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CDVG',
        props: ['gerCondVGCozedorMassaB'],
        x: 114, y: 26.3, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CDVG',
        props: ['consCondVGMelAPobre'],
        x: 40.2, y: 41.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'CDVG',
        props: ['consCondVGMelARico'],
        x: 25, y: 41.7, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'CDVG',
        props: ['consCondVGCintrifMassaA'],
        x: 29.7, y: 56, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CDVG',
        props: ['consCondVGMagma'],
        x: 82.8, y: 68.3, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'CDVG',
        props: ['consCondVGCintrifMassaB'],
        x: 92.5, y: 56, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'CDVG',
        props: ['consCondVGMelBRico'],
        x: 87.8, y: 41.7, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'VE Limp.',
        props: ['consVaporVELimpCozMA'],
        x: 44.8, y: 20.7, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'VE Limp.',
        props: ['consVaporVELimpCozMB'],
        x: 107.5, y: 20.7, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Xarope',
        props: ['flowXaropeToFab', 'brixXarope', 'purezaXarope', 'artMassaXaropeToFab'],
        x: 3, y: 3.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowXaropeToMassaA'],
        x: 20, y: 13.4, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowXaropeToMassaB'],
        x: 83, y: 13.4, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowMARDiluido', 'brixMARDiluido', 'purezaMelARico'],
        x: -1, y: 42, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Mel A Rico',
        props: ['flowMelARico', 'brixMelARico', 'purezaMelARico'],
        x: 22, y: 47, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowMAPDiluido', 'brixMAPDiluido', 'purezaMAPDiluido'],
        x: 50, y: 42, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Mel B Rico',
        props: ['flowMelBRico', 'brixMelBRico', 'purezaMelBRico'],
        x: 85, y: 47, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowMelBRicoDil', 'brixMelBRicoDil', 'purezaMelBRico'],
        x: 78, y: 38.6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Açúcar',
        props: ['flowAcucar', 'flowAcucarScsdia', 'brixAcucar', 'purezaAcucar', 'artMassaAcucar'],
        x: 3, y: 83.3, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Mel Final',
        props: ['flowMelFinal2', 'brixMelFinal2', 'purezaMelFinal2', 'artMassaMelFinal2'],
        x: 116, y: 47, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowMAPDiluidoToMB'],
        x: 50, y: 33, align: 'right', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowMAPDiluidoToMF'],
        x: 118.3, y: 38.6, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Açúcar B',
        props: ['flowAcucarB',  'brixAcucarB', 'purezaAcucarB'],
        x: 103.7, y: 60, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Mel A Pobre',
        props: ['flowMelAPobre', 'brixMelAPobre', 'purezaMelAPobre'],
        x: 43, y: 47, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Massa A',
        props: ['flowMassaA', 'brixMassaA', 'purezaMassaA'],
        x: 40.7, y: 30, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Massa B',
        props: ['flowMassaB', 'brixMassaB', 'purezaMassaB'],
        x: 103.7, y: 30, align: 'left', valign: 'flex-start'
      },
      // { 
      //   flow: '',
      //   props: ['flowAlimMassaA', 'brixAlimMassaA', 'purezaAlimMassaA'],
      //   x: 8.4, y: 20, align: 'right', valign: 'flex-start'
      // },
      // { 
      //   flow: '',
      //   props: ['flowAlimMassaB', 'brixAlimMassaB', 'purezaAlimMassaB'],
      //   x: 42.3, y: 20, align: 'right', valign: 'flex-start'
      // },
      { 
        flow: 'Magma',
        props: ['flowMagma', 'brixMagma', 'purezaMagma'],
        x: 103.7, y: 76, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'VE',
        props: ['consVaporVESecadorAcucar'],
        x: 3, y: 69, align: 'left', valign: 'flex-end'
      }
    ]
  }

import React, { useState } from 'react'
import './../../css/Listas.css'
import ListLine from './ListLine'
import { getScreenData } from '../../Funcoes/AreaFuncs'
import GlobalStateContext from '../GlobalStateContext'
import { useContext } from 'react'

function Listas() {
  const {data, area, screen, isPrinting } = useContext(GlobalStateContext)
  const [cod, setChangedCod] = useState('');

  const handleCountChange = (c) => {
    console.log('Count mudou:', c);
    if (c === cod){
      setChangedCod(''+c+'1')
    }else{
      setChangedCod(c)
    }
  };

  const vh = window.innerHeight
  const ideal = 727
  let nVH = isPrinting? ideal/vh : 1

  const styles = {
    lista: {
      marginTop: `${1*nVH}vh`,
    },
    coluna: {
      minWidth: `${45*nVH}vh`,
      maxWidth: `${70*nVH}vh`,
    },
    sectionBox: {
      marginBottom: `${3*nVH}vh`},
    subSection: {
        fontSize: `${1.6*nVH}vh`,
        marginLeft: `${1.5*nVH}vh`,
        marginBottom: `${0.3*nVH}vh`,
        marginTop: `${-2*nVH}vh`,
    },
    section: {
      fontSize: `${1.6*nVH}vh`,
      marginBottom: `${1*nVH}vh`,
      marginTop: `${2*nVH}vh`,
  }
  }

  return (
    <div className='lista' style={styles.lista}>
        <div className='colunas' >
          <div className='coluna' style={styles.coluna}>
            {data && getScreenData(area, screen, data).map((item, idx) => {
              if (item.col === 1){
                return(
                  <div >
                   {item.subSection ? 
                  <section style={{marginBottom: `${3*nVH}vh`}}>{item.sectionName}</section> :
                  <section style={styles.section}>{item.sectionName}</section>
                   }
                   {item.subSection && 
                   <div  className='subSection' style={styles.subSection}>
                    {item.subSection}
                    </div>
                   }
                   <div  className='sectionBox' style={styles.sectionBox}>
                      {item.props.map((cod, index) => {
                        if (cod !== ''){
                          return (
                            <ListLine ipt={item.ipt[index]} cod={cod} handleCountChange={handleCountChange} qty={!item.qty ? 0 : item.qty[index]} key={''+screen+''+cod+''+idx} item={item}/>
                          )
                        }else{return(<></>)}
                      })}
                   </div>
                  </div>
                )
              }else{return(<></>)}
            })}
            <div style={{height: '300px'}}></div>
          </div>
          <div className='coluna' style={styles.coluna}>
          {data && getScreenData(area, screen, data).map((item, idx) => {
              if (item.col === 2){
                return(
                  <div >
                   {item.subSection ? 
                  <section style={{marginBottom: `${3*nVH}vh`}}>{item.sectionName}</section> :
                  <section style={styles.section}>{item.sectionName}</section>
                   }
                   {item.subSection && 
                   <div  className='subSection' style={styles.subSection}>
                    {item.subSection}
                    </div>
                   }
                   <div className='sectionBox' style={styles.sectionBox}>
                      {item.props.map((cod, index) => {
                        if (cod !== ''){
                          return (
                            <ListLine ipt={item.ipt[index]} cod={cod}  handleCountChange={handleCountChange} qty={!item.qty ? 0 : item.qty[index]} key={''+screen+''+cod+''+idx}/>
                          )
                        }else{return(<></>)}
                      })}
                   </div>
                  </div>
                )
              }else{return(<></>)}
            })}
          </div>
        </div>
    </div>
  )
}

export default Listas
  
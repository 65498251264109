import { useContext } from 'react'
import Flow from '../Flow'
import DestFlx from '../../../assets/fluxogramas/destilaria4Web.png'
import GlobalStateContext from '../../GlobalStateContext'

function DestilariaFlx() {
    const {data} = useContext(GlobalStateContext)
    const vh = window.innerHeight
    const ideal = 727
    let nVH = ideal/vh
    
    return (
      <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
        <div style={{ position: 'absolute', width: `${115*nVH}vh`, height: `${100*nVH}vh`, top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}}>
          <img style={{position: 'absolute', height: `${90*nVH}vh`, width: `${135*nVH}vh`, left: '0vh', top: '0vh'}}  src={DestFlx} alt="" />
      {data &&  destilaria(data['opVaporEtNeutro'].valor).flows.map((item, index) => {
          return (
            <Flow flow={item.flow} x={item.x * nVH} y={item.y * nVH} align={item.align} valign={item.valign} cods={item.props} key={index} f={nVH}/>
          )
          })}
      </div>
    </div>
  )
}

export default DestilariaFlx

const destilaria = (opVaporA2) => ({
    imagem: DestFlx,
    flows: [
      { 
        flow: 'Água',
        props: ['flowAguaTorreDestilatia', 'tempAguaQuenteTorreFerm'],
        x: 1.5 , y: 3.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'Vinho',
        props: ['flowVolVinhoCent', 'concEtanolVinhoBruto'],
        x: 1.5, y: 74.3, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Vinhaça',
        props: ['flowVinhaca', 'concEtanolVinhaca', 'tempVinhaca'],
        x: 1.5, y: 84, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowEtanol'],
        x: 55, y: 11.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: 'VV1',
        props: ['flowVaporVV1Destilaria'],
        x: 70, y: 57.5, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'VE',
        props: ['flowVaporVEDestilaria'],
        x: 70, y: 68.7, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVaporTotalColA'],
        x: 29, y: 42.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowVaporTotalColB'],
        x: 54, y: 36.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowVaporVV1IndColA'],
        x: 76.5, y: 65.5, align: 'left', valign: 'flex-start'
      },
      { 
        flow: '',
        props: ['flowVaporVV1DirColA'],
        x: 63, y: 65.5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: 'Flegmaça',
        props: ['flowFlegmaca', 'concEtanolFlegmaca'],
        x: 117.5, y: 84, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'Flegma',
        props: ['flowFlegma', 'concEtanolFlegma'],
        x: 24.6, y: 34.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'CDVG',
        props: ['flowRetCondColA'],
        x: 70, y: 74.2, align: 'right', valign: 'flex-start'
      },
  
       //2VIEW
      { 
        flow: '',
        props: ['flowAguaTorreColA2'],
        x: 69, y: 5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowAguaTorrePeneriraMol'],
        x: 106, y: 5, align: 'left', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowEtanolProdToHid'],
        x: 83, y: 15, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowEtanolHidToPenMol'],
        x: 101, y: 15, align: 'right', valign: 'flex-end'
      },
      { 
        flow: '',
        props: ['flowEtanolProdToNeutro'],
        x: 66.5, y: 15, align: 'right', valign: 'flex-end'
      },
  //TANQUES
  { 
    flow: '',
    props: ['flowEtanolNeutro', 'flowEtanolNeutroDia'],
    x: 119.5, y: 51.2, align: 'center', valign: 'flex-start'
  },
  { 
    flow: '',
    props: ['flowEtanolAnidro', 'flowEtanolAnidroDia'],
    x: 119.5, y: 61.3, align: 'center', valign: 'flex-start'
  },
  { 
    flow: '',
    props: ['flowEtanolProdToHid', 'flowEtanolHidDia'],
    x: 119.5, y: 71, align: 'center', valign: 'flex-start'
  },
  
      
      { 
        flow: 'V10',
        props: ['consVaporV10Desidratacao'],
        x: 117.5, y: 26.7, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'CDV10',
        props: ['consVaporV10Desidratacao'],
        x: 117.5, y: 33.7, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'VE',
        props: ['consVaporVEPenMol'],
        x: 117.5, y: 13.5, align: 'right', valign: 'flex-start'
      },
      { 
        flow: 'CDVE',
        props: ['consVaporVEPenMol'],
        x: 117.5, y: 20.5, align: 'right', valign: 'flex-start'
      },
      
      { 
        flow: opVaporA2 === 0 ? 'VV1' : 'VE',
        props: [opVaporA2 === 0 ? 'consVaporVV1ColA2' : 'consVaporVEColA2'],
        x: 80.3, y: 26.5, align: 'right', valign: 'flex-end'
      },
      { 
        flow: 'CDVG',
        props: ['gerCondVGEtNeutro'],
        x: 80.3, y: 33.6, align: 'right', valign: 'flex-end'
      }
    ]
  })

import { useRef, useState, useEffect } from "react";
import "../../css/Segment.css";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
const ScreenSegs = ({
  descricao,
  name,
  segments,
  callback,
  defaultIndex,
  controlRef,
  screen,
  area
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const componentReady = useRef();

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true;
  }, []);

  // Determine when the component is "ready"
  useEffect(() => {
    setActiveIndex(0)
  }, [area]);

  useEffect(() => {
    if (!segments[activeIndex]){
        const activeSegmentRef = segments[0]?.ref;
        const { offsetWidth, offsetLeft } = activeSegmentRef?.current;
        const { style } = controlRef?.current;
        style.setProperty("--highlight-width", `${offsetWidth}px`);
        style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }else{
        const activeSegmentRef = segments[activeIndex]?.ref;
        const { offsetWidth, offsetLeft } = activeSegmentRef?.current;
        const { style } = controlRef?.current;
        style.setProperty("--highlight-width", `${offsetWidth}px`);
        style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }
    
  }, [activeIndex, callback, controlRef, segments]);

  const onInputChange = (value, index) => {
    setActiveIndex(index);
    callback(value, index);
  };

  return (
    <div className="screenControls-container" ref={controlRef} style={{background: `${screen === 'fluxograma' ? 'white' : '#F2F2F7'}`}}>
        {/* <div style={{width: '55%', textAlign: 'left'}}>{descricao}</div> */}
      <div className={`controlsScreen ${componentReady.current ? "ready" : "idle"}`}>
        {segments?.map((item, i) => (
          <div
            key={item?.value}
            className={`segmentScreen ${i === activeIndex ? "active" : "inactive"}`}
            ref={item?.ref}
          >
            <input
              type="radio"
              value={item?.value}
              id={item?.label}
              name={name}
              onChange={() => onInputChange(item?.value, i)}
              checked={i === activeIndex}
            />
            <label htmlFor={item?.label}>{item?.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScreenSegs;